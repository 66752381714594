.manage-passenger-layout {
	display: flex;
	position: relative;
}

.manage-passenger-layout__sub-menu {
	flex: 1;

	@include breakpoint(notebook) {
		max-width: 400px;
	}
}

.manage-passenger-layout__sub-menu--hide-on-mobile {
	display: none;

	@include breakpoint(notebook) {
		display: block;
	}
}

.manage-passenger-layout__body {
	display: none;

	@include breakpoint(notebook) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 2.4;
	}
}

.manage-passenger-layout__body--keep-on-mobile {
	display: block;
	width: 100%;
}

.manage-passenger-layout {
	&__none-found{
		margin: auto;
		text-align: center;
		padding-top: 20px;

		@include breakpoint(notebook) {
			padding-top: 40px;
		}

		&__title {
			@extend %boldWeight;
			margin-bottom: 0.5em;
			@include font(18px);

			@include breakpoint(tablet) {
				@include font(22px)
			}

			@include breakpoint(notebook) {
				font-weight: 500;
				@include font(24px)
			}
		}

		&__sub-title {
			@extend %lightWeight;
			@include font(16px, 20px);

			@include breakpoint(phablet) {
				max-width: 70vw;
			}
		}
	}
}