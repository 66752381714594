.environmental-project {
	width: 100%;
	padding-bottom: 67%;
	background-color: $c-environmental-green;
	color: $c-grey-04;
	position: relative;
	background-size: cover;
	background-position: center;

	&__tint {
		@include inset(0, 0, 0, 0);
		position: absolute;
		background-color: rgba($c-black, 0.4);
	}

	&__inner {
		@include inset(0, 0, 0, 0);
		position: absolute;
		padding: 24px 20px;
	}

	&__counter {
		@extend %lightWeight;
	}

	&__title {
		@include font(26px, 32px);
		@extend %mediumWeight;
		color: $c-gold;
		margin-top: 10px;
	}

	&__line {
		height: 0.5px;
		background-color: $c-gold;
		width: 50%;
		margin-top: 5px;
		margin-bottom: 20px;
	}

	&__writeup {
		@include font(16px, 22px)
	}

	&__select-checkbox {
		position: absolute;
		top: 19px;
		right: 19px;
		height: 22px;
		width: 22px;
		border: solid $c-grey-01 0.5px;
		border-radius: 2px;

		img {
			height: 60%;
			width: 60%;
		}
	}

	&__select-button {
		position: absolute;
		@include inset(0, 0, 0, 0);
	}
}

// Selectable
.environmental-project--selectable {
	margin-bottom: 8px;
}

// Selected
.environmental-project--selected {
	.environmental-project__select-button {
		display: none;
	}
}