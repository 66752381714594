.main {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - $header-height);
    padding-top: $header-height;
    transition: all .3s ease;

    @include breakpoint(phablet) {
        min-height: calc(100vh - $header-height--phablet);
        padding-top: $header-height--phablet;
    }

    .header--scrolled ~ & {

        @include breakpoint(phablet) {
            min-height: calc(100vh - $header-height);
            padding-top: $header-height;
        }
    }
}