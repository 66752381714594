.crypto-payment {

	&__response-wrapper {
		position: relative;
		min-height: 200px;

		&__error {
			@include font(16px, 20px);
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;

			img {
				height: 22px;
				margin-right: 14px;
			}
		}
	}

}