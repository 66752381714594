.blog-content-block {
	@include margin(bottom, 56px);
}

.blog-content-block--has-background {
	position: relative;
	padding: 32px 0 72px;
	color: $c-grey-01;

	@include breakpoint(tablet) {
		padding: 72px 0;
	}
}

	.blog-content-block__background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.blog-content-block__container {
		@extend .wrap;

		position: relative;
		max-width: 920px + ($site-gutter--tablet * 2);

		@include breakpoint(tablet) {
			display: flex;
			align-items: center;
		}

		.blog-content-block--align-Right & {

			@include breakpoint(tablet) {
				flex-direction: row-reverse;
			}
		}
	}

		.blog-content-block__image {
			@include margin(bottom, 32px);

			@include breakpoint(tablet) {
				flex: 0 0 52%;
				margin: 0;
				padding-right: 50px;
			}

			.blog-content-block--align-Right & {

				@include breakpoint(tablet) {
					padding-right: 0;
					padding-left: 50px;
				}
			}

			img {
				width: 100%;
				display: block;
			}
		}

		.blog-content-block__content {

			@include breakpoint(tablet) {
				flex: 1 1 auto;
			}

			h2 {
				@include font(20px, 24px);
				@include margin(bottom, 16px);
				@extend %lightWeight;

				@include breakpoint(tablet) {
					@include font(22px, 26px);
				}
			}

			p + h2 {
				margin-top: 56px;
			}

			p {
				@include font(14px, 20px);
				@include margin(bottom, 20px);
				@extend %lightWeight;

				@include breakpoint(tablet) {
					@include font(16px, 22px);
				}
			}

			a {
				text-decoration: underline;
			}
		}