.spinner {
	position: relative;
	height: 45px;
	width: 45px;
	animation: spin infinite linear 1500ms;

	img {
		@include inset(0, 0, 0, 0);
		position: absolute;
	}
}