// Home side panel
.home-side-panel {

	&__backdrop {
		position: fixed;
		@include inset(0, 0, 0, 0);
		background-color: $c-black;
		opacity: 0;
		transition: opacity $home-side-panel-speed ease;
		z-index: 9999;
	}

	&__panel {
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		width: 100vw;
		background: $c-grey-01;
		z-index: 9999;
		transform: translate(100%);
		transition: transform $home-side-panel-speed ease;

		@include breakpoint(phablet) {
			width: 375px;
		}
	}

	&__scroll {
		@extend %scrollVertical;
		position: absolute;
		@include inset(0, 0, 0, 0)
	}

	&__header {
		@include font(18px);
		text-transform: uppercase;
		white-space: nowrap;
		overflow: hidden;
		height: 50px;
		position: sticky;
		top: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		color: $c-grey-dark;
		background-color: $c-white;
		z-index: 1;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 2;
	}

	&__image {
		position: relative;
		height: 0;
		background-color: $c-black;
		padding-bottom: 70%;
		width: 100%;
	}

	&__body {
		@include font(16px, 24px);
		color: $c-grey-dark;
		padding: 20px 40px 70px;

		p {
			margin-bottom: 1.4em;
		}
	}

	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 60px;
		width: 100%;

		button {
			@extend %boldWeight;
			@include font(18px);
			height: 100%;
			width: 100%;
			background: $c-grey-05;
			border-top: 1px $c-blue-04 solid;
			color: $c-primary-blue;
			display: flex;
			flex-direction: row;
			padding: 0 25px;
			align-items: center;
			justify-content: flex-start;

			img {
				margin-right: 16px;
			}
		}
	}
}

.home-side-panel--open {
	.home-side-panel__backdrop {
		opacity: 0.7;
	}

	.home-side-panel__panel {
		transform: translate(0%);
	}
}