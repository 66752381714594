.secure-reassure {
	@extend %lightWeight;
	color: $c-grey-dark;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 28px;

	img {
		margin-right: 2px;
	}
}