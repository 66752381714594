.compare-buttons-bar {
	background-color: $c-grey-01;
	position: fixed;
	top: 100vh;
	left: 0;
	right: 0;
	z-index: 1;
	box-shadow: 0px -6px 4px rgba(27, 38, 70, 0.1);
	transition: transform 300ms, opacity 250ms;

	@include breakpoint(notebook) {
		background-color: transparent;
		position: absolute;
		box-shadow: none;
		top: 0;
		opacity: 0;
		min-width: 50vw;
		height: 32px;
		margin: 4px auto 0;
	}

	&--active,
	&--transition-in {
		transform: translate(0, -100%);

		@include breakpoint(notebook) {
			transform: none;
			opacity: 1;
		}
	}

	&__inner {
		padding: 18px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		@include breakpoint(notebook) {
			background-color: $c-blue-04;
			border-radius: 4px;
			width: fit-content;
			min-width: 50%;
			flex-direction: row;
			height: 100%;
			align-items: center;
			padding: 0;
		}

		&__title {
			@include font(16px);
			margin-bottom: 18px;

			@include breakpoint(notebook) {
				margin: 0 20px;
			}
		}

		&__buttons-wrapper {
			display: flex;
			flex-direction: row;

			button {
				width: 140px;
			}

			@include breakpoint(notebook) {
				button {
					height: 30px;
					margin: 0;
				}
			}
		}

		&__close-cross {
			position: absolute;
			top: 10px;
			right: 10px;

			@include breakpoint(notebook) {
				display: none;
			}
		}
	}
}