.text-input-alt {

	img {
		position: absolute;
		left: 10px;
		top: 50%;
		max-width: 26px;
		transform: translate(0, -50%);
	}

	&__input {
		width: 100%;
		padding: 0 15px 0 42px;
		box-sizing: border-box;
		color: $c-primary-blue;
		border: none;
		height: 42px;
		background-color: $c-input-grey;
		background-size: 24px;
		background-repeat: no-repeat;
		background-position: 10px center;
		border-radius: 4px;

		&:focus {
			outline: none;
			border: none;
		}

		@include breakpoint(tablet) {
			font-weight: 700;
		}
	}

}

.text-input-alt__input--error {
	outline: solid 2px $c-error-red;
	outline-offset: -2px;
	color: $c-error-red;;
}