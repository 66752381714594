.wrap {
	max-width: $site-max-width;
	padding-right: $site-gutter;
	padding-left: $site-gutter;
	margin: auto;
	width: 100%;

	@include breakpoint(tablet) {
		padding-left: $site-gutter--tablet;
		padding-right: $site-gutter--tablet;
	}
}

.wrap--sm-no-padding{
	padding-left: 0;
	padding-right: 0;

	@include breakpoint(phablet) {
		padding-right: $site-gutter;
		padding-left: $site-gutter;
	}

	@include breakpoint(tablet) {
		padding-left: $site-gutter--tablet;
		padding-right: $site-gutter--tablet;
	}
}

.wrap--1040 {
	max-width: calc(1040px + #{$site-gutter * 2});

	@include breakpoint(tablet) {
		max-width: calc(1040px + #{$site-gutter--tablet * 2});
	}
}