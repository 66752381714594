.block-header {

	// H1 section title
	.title {
	// h1 {
		@include font(24px, 28px);
		@extend %lightWeight;

		text-transform: uppercase;
		letter-spacing: 0.05em;
		text-align: center;
		max-width: 800px;
		margin: 0 auto;

		// theme
		color: var(--block-header-h1-color);

		@include breakpoint(phablet) {
			@include font(26px, 32px);
		}

		@include breakpoint(tablet) {
			@include font(28px, 34px);
		}

		@include breakpoint(laptop) {
			@include font(32px, 36px);
		}
	}

	// H2 section title
	.subtitle {
	// h2 {
		@include font(16px, 20px);

		margin-bottom: 0.7em;
		text-align: center;
		text-transform: uppercase;

		// theme
		color: var(--block-header-h2-color);

		@include breakpoint(tablet) {
			@include font(18px, 22px);
		}
	}
}

.block-header--light {
	.title {
	// h1 {

		// theme
		color: var(--block-header-h1-color-light);

		.block-aircraft & {

			// theme
			color: var(--block-header-h1-color-light-alt);
		}
	}
}

.block-header--skeleton {

	.title {
		@extend %skeleton;
	}

	.subtitle {
		@extend %skeleton;
	}
}