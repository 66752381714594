.share-payment-success {
	@include font(16px, 19px);

	width: 100%;
	max-width: 380px;
	margin: 0 auto;
	text-align: center;
	color: $c-grey-dark;

	@include breakpoint(tablet) {
		max-width: 630px + 50px;
	}

	.button {
		display: inline-flex;
	}
}

	.share-payment-success__container {
		@include margin(bottom, 42px);

		@include breakpoint(tablet) {
			@include margin(bottom, 16px);

			display: flex;
			justify-content: center;
			padding: 10px 25px 25px;
			border: 0.5px solid rgba($c-grey-06, 0.2);
			box-shadow: -2px 2px 4px rgba(212, 188, 135, 0.04), 2px -2px 4px rgba(212, 188, 135, 0.04), -2px -2px 4px rgba(212, 188, 135, 0.04), 2px 2px 4px rgba(212, 188, 135, 0.04), inset 2px 1px 2px rgba(212, 188, 135, 0.02), inset -1px -1px 2px rgba(212, 188, 135, 0.02);
		}
	}

		.share-payment-success__inner {

			@include breakpoint(tablet) {
				max-width: 380px;
			}

			.clipboard-input {

				@include breakpoint(tablet) {
					width: 180px;
					margin: 0 auto;
				}
			}
		}

			.share-payment-success__image {

				@include breakpoint(tablet) {
					@include margin(bottom, 18px);
				}

				img {
					width: 46px;
					height: 46px;

					@include breakpoint(tablet) {
						width: auto;
						height: auto;
					}
				}
			}

			.share-payment-success__title {
				@include font(18px, 22px);
				@include margin(bottom, 7px);

				letter-spacing: 0.02em;
				color: $c-primary-blue;

				@include breakpoint(tablet) {
					@include font(22px, 26px);
					@include margin(bottom, 20px);
				}
			}

			.share-payment-success__confirmation {
				@include margin(bottom, 32px);
				@extend %boldWeight;

				padding-top: 16px;
				color: $c-grey-dark;
				border-top: 1px solid $c-gold;

				@include breakpoint(tablet) {
					padding-top: 24px;
				}

				span {
					@include font(14px, 20px);
					@extend %lightWeight;

					display: block;

					@include breakpoint(tablet) {
						@include font(16px, 22px);
						@include margin(bottom, 4px);
					}
				}
			}

			.share-payment-success__note {
				@include margin(bottom, 40px);
				@include font(14px, 20px);
				@extend %lightWeight;

				text-align: left;
				color: $c-red;

				@include breakpoint(tablet) {
					@include font(16px, 22px);

					text-align: center;
				}
			}

	.share-payment-success__footer {
		color: $c-gold;

		p {
			@include margin(bottom, 12px);
		}
	}