.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__is-act-as-border {
        position: fixed;
        @include inset(0, 0, 0, 0);
        z-index: 99999;
        pointer-events: none;
        border: 2px $c-link-blue solid;
    }
}

    .layout__loading {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $c-white;
        z-index: 4;
    }