.aircraft-browser-filters {
	@include margin(bottom, 24px);

	width: 100%;
	max-width: 1032px + (32px * 2);
	padding: 0 32px;

	@include breakpoint(notebook) {
		margin: 0 auto 30px;
	}
}

	.aircraft-browser-filters__slider-container {
		display: flex;
		align-items: center;
	}

		.aircraft-browser-filters__slider-offset {
			width: 55px;
			height: 4px;

			// theme
			background: var(--rc-slider-rail-bg);

			&:first-child {
				border-radius: 4px 0 0 4px;
			}

			&:last-child {
				border-radius: 0 4px 4px 0;
			}
		}

		.aircraft-browser-filters__slider {
			flex: 1 1 auto;
		}

	.aircraft-browser-filters__swiper {
		margin: 0 -32px;
		padding: 0 32px;

		.swiper-wrapper {

			@include breakpoint(notebook) {
				justify-content: space-between;
			}
		}

			.swiper-slide {
				width: auto;

				@include breakpoint(notebook) {
					flex: 1 1 auto;
				}
			}

		.swiper-pagination {
			display: none;
		}
	}

		.aircraft-browser-filters__button {
			@include font(16px, 19px);
			@extend %mediumWeight;

			margin-bottom: 2px;
			padding-right: 50px;
			letter-spacing: 0.02em;

			// theme
			border-bottom: 2px solid var(--rc-slider-rail-bg);
			color: var(--aircraft-browser-filters-color);

			@include breakpoint(notebook) {
				margin-bottom: 0;
				border-bottom: none;
			}

			&:last-child {
				padding-right: 0;

				@include breakpoint(notebook) {
					text-align: right;
				}
			}

			button {
				margin-bottom: -4px;
				padding-bottom: 11px;
				line-height: inherit;
				letter-spacing: inherit;
				text-transform: inherit;

				@include breakpoint(notebook) {
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
		}

		.aircraft-browser-filters__button--active {

			// theme
			color: var(--aircraft-browser-filters-active-color);

			button {
				padding-bottom: 5px;
				
				// theme
				border-bottom: 6px solid currentColor;

				@include breakpoint(notebook) {
					padding-bottom: 0;
					border-bottom: none;
				}
			}
		}