.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: $header-height;
    transition: all .3s ease;
    z-index: 3;

    // theme
    background: var(--header-bg);

    @include breakpoint(phablet) {
        height: $header-height--phablet;
    }
}

.header--scrolled {
    background-blend-mode: multiply;
    box-shadow: 0px 1px 4px 0px rgba($c-grey-dark, 0.4);

    // theme
    background: var(--header-bg-scrolled);

    @include breakpoint(phablet) {
        height: $header-height;
    }
}

.header--quote-query-scrolled {
    box-shadow: none;

    // theme
    background: var(--quote-query-scrolled-bg);
}

    .header__logo {
        position: absolute;
        top: 10px;
        left: 50%;
        height: 40px;
        transform: translate(-50%);
        transition: all .3s ease;

        @include breakpoint(phablet) {
            top: 24px;
            height: 100px;
        }

        .header--scrolled & {

            @include breakpoint(phablet) {
                top: 10px;
                height: 40px;
            }
        }
    }

    .header__logo--on-top {
        z-index: 9999
    }

    .header__wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

        .header__toggle {
            position: absolute;
            top: $header-height / 2;
            left: $site-gutter;
            transform: translateY(-50%);
            transition: all .3s ease;

            @include breakpoint(phablet) {
                top: $header-height--phablet / 2;
            }

            @include breakpoint(tablet) {
                left: $site-gutter--tablet;
            }

            @include breakpoint(desktop) {
                left: 50%;
                transform: translate(calc(-700px + #{$site-gutter--tablet}), -50%);
            }

            .header--scrolled & {

                @include breakpoint(phablet) {
                    top: 30px;
                }
            }

            img {
                display: block;
            }
        }