.text-input {
	position: relative;

	&--disabled {
		opacity: 0.7;
	}
}

.text-input--margin-bottom--margin-bottom {
	margin-bottom: 40px;
}

	.text-input__input,
	.PhoneInputInput {
		@extend %lightWeight;
		@include font(16px);
		padding-left: 8px;
		padding-right: 8px;
		padding-top: 14px;
		color: $c-primary-blue;
		height: 45px;
		width: 100%;
		border: solid 1px $c-grey-03;
	}

	.PhoneInput {
		padding-left: 8px;
		border: solid 1px $c-grey-03;

		~ .label {
			left: 48px;
		}
	}

	.PhoneInputInput {
		border: none;

		&:focus {
			outline: none;
		}
	}

	.text-input__input--error {
		border: solid 1px $c-red;
	}

	.text-input__input:disabled {
		color: $c-grey-02;
		background-color: $c-grey-05;
	}

	.text-input__input:-webkit-autofill:disabled {
		-webkit-box-shadow: 0 0 0 1000px $c-grey-05 inset !important;
		color: $c-grey-02;
	}

	.text-input__input--success{
		border: solid 1px $c-success-green;
	}

	.text-input__input:focus,
	.text-input__input--error:focus,
	.PhoneInput:focus-within {
		border: solid 1px $c-grey-03;
		outline-offset: 0;
		outline: 0.75px solid $c-link-blue;
	}

	.text-input__error-cross {
		position: absolute;
		top: 10.5px;
		right: 10px;
	}

	.text-input__tick {
		position: absolute;
		top: 10.5px;
		right: 10px;
	}