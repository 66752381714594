/// Define all of the font files here
///
/// Make sure to include links to woff2 and woff files only

$fonts: ((
        font-family: "Lato",
        src: (
                url("#{$fonts-directory}lato-v23-latin-100.woff2") format("woff2"),
                url("#{$fonts-directory}lato-v23-latin-100.woff") format("woff")
        ),
        font-display: swap,
        font-weight: 275
),(
    font-family: "Lato",
    src: (
        url("#{$fonts-directory}lato-v23-latin-300.woff2") format("woff2"),
        url("#{$fonts-directory}lato-v23-latin-300.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 300
),(
    font-family: "Lato",
    src: (
        url("#{$fonts-directory}lato-v23-latin-400.woff2") format("woff2"),
        url("#{$fonts-directory}lato-v23-latin-400.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400
),(
    font-family: "Lato",
    src: (
        url("#{$fonts-directory}lato-v23-latin-400-italic.woff2") format("woff2"),
        url("#{$fonts-directory}lato-v23-latin-400-italic.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400,
    font-style: italic
),(
        font-family: "Lato",
        src: (
                url("#{$fonts-directory}lato-v23-latin-500.woff2") format("woff2"),
                url("#{$fonts-directory}lato-v23-latin-500.woff") format("woff")
        ),
        font-display: swap,
        font-weight: 500,
),(
    font-family: "Lato",
    src: (
        url("#{$fonts-directory}lato-v23-latin-700.woff2") format("woff2"),
        url("#{$fonts-directory}lato-v23-latin-700.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 700
),(
        font-family: "Calligraffitti",
        src: (
                url("#{$fonts-directory}calligraffitti-v19-latin-regular.woff2") format("woff2"),
                url("#{$fonts-directory}calligraffitti-v19-latin-regular.woff") format("woff")
        ),
        font-display: swap
),(
    font-family: "LyonDisplay",
    src: (
        url("#{$fonts-directory}LyonDisplay-Light.woff2") format("woff2"),
        url("#{$fonts-directory}LyonDisplay-Light.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 300,
    font-style: normal
),(
    font-family: "LyonDisplay",
    src: (
        url("#{$fonts-directory}LyonDisplay-LightItalic.woff2") format("woff2"),
        url("#{$fonts-directory}LyonDisplay-LightItalic.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 300,
    font-style: italic
),(
    font-family: "LyonDisplay",
    src: (
        url("#{$fonts-directory}LyonDisplay-Medium.woff2") format("woff2"),
        url("#{$fonts-directory}LyonDisplay-Medium.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 500,
    font-style: normal
),(
    font-family: "LyonDisplay",
    src: (
        url("#{$fonts-directory}LyonDisplay-MediumItalic.woff2") format("woff2"),
        url("#{$fonts-directory}LyonDisplay-MediumItalic.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 500,
    font-style: italic
),(
    font-family: "LyonDisplay",
    src: (
        url("#{$fonts-directory}LyonDisplay-Regular.woff2") format("woff2"),
        url("#{$fonts-directory}LyonDisplay-Regular.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400,
    font-style: normal
),(
    font-family: "LyonDisplay",
    src: (
        url("#{$fonts-directory}LyonDisplay-RegularItalic.woff2") format("woff2"),
        url("#{$fonts-directory}LyonDisplay-RegularItalic.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400,
    font-style: italic
),(
    font-family: "LyonText",
    src: (
        url("#{$fonts-directory}LyonText-Regular.woff2") format("woff2"),
        url("#{$fonts-directory}LyonText-Regular.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400,
    font-style: normal
),(
    font-family: "LyonText",
    src: (
        url("#{$fonts-directory}LyonText-RegularItalic.woff2") format("woff2"),
        url("#{$fonts-directory}LyonText-RegularItalic.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400,
    font-style: italic
),(
    font-family: "LyonText",
    src: (
        url("#{$fonts-directory}LyonText-RegularNo2.woff2") format("woff2"),
        url("#{$fonts-directory}LyonText-RegularNo2.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400,
    font-style: normal
),(
    font-family: "LyonText",
    src: (
        url("#{$fonts-directory}LyonText-RegularNo2Italic.woff2") format("woff2"),
        url("#{$fonts-directory}LyonText-RegularNo2Italic.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400,
    font-style: italic
),(
    font-family: "SchnyderCond",
    src: (
        url("#{$fonts-directory}SchnyderCondS-Demi.woff2") format("woff2"),
        url("#{$fonts-directory}SchnyderCondS-Demi.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 600,
    font-style: normal
),(
    font-family: "StyreneA",
    src: (
        url("#{$fonts-directory}StyreneA-Medium.woff2") format("woff2"),
        url("#{$fonts-directory}StyreneA-Medium.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 500,
    font-style: normal
),(
    font-family: "StyreneA",
    src: (
        url("#{$fonts-directory}StyreneA-Regular.woff2") format("woff2"),
        url("#{$fonts-directory}StyreneA-Regular.woff") format("woff")
    ),
    font-display: swap,
    font-weight: 400,
    font-style: normal
));

@each $font in $fonts {
    @font-face {
        @each $name in map-keys($font) {
            #{$name}: map-get($font, $name);
        }
    }
}
