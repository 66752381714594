.add-passenger-details {
	display: flex;
	flex-direction: row;
}

	.add-passenger-details__sub-menu {
		flex: 1;
		width: 100%;

		@include breakpoint(notebook) {
			max-width: 400px;
		}
	}

	.add-passenger-details__sub-menu--hide-on-mobile {
		display: none;

		@include breakpoint(notebook) {
			display: block;
		}
	}

	.add-passenger-details__body {
		display: none;

		@include breakpoint(notebook) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex: 2.4;
			padding-bottom: 100px;
		}
	}

	.add-passenger-details__body--keep-on-mobile {
		display: block;
		padding-bottom: 100px;
		width: 100%;
	}

		.add-passenger-details__body__title {
			@include font(22px, 28px);
			max-width: 330px;
			text-align: center;
		}

.add-passenger-details__body__sub-title {
	margin-top: 20px;
}