.text-area-input {
	position: relative;
}

.text-area-input__text-area {
	@extend %lightWeight;
	@include font(16px);
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 22px;
	color: $c-primary-blue;
	background-color: $c-grey-01;
	height: 130px;
	width: 100%;
	border: solid 1px $c-grey-03;
	display: block;
	resize: none;
}

.text-area-input__text-area--error {
	border: solid 1px $c-red;
}

.text-area-input__text-area--error:focus,
.text-area-input__text-area:focus {
	border: 1px solid $c-primary-blue;
	outline: 0.75px solid $c-link-blue;
}

.text-area-input__text-area--success,
.text-area-input__text-area--success:focus{
	border: solid 1px $c-success-green;
}

.text-area-input__error-cross {
	position: absolute;
	top: 10.5px;
	right: 10px;
}

.text-area-input__tick {
	position: absolute;
	top: 10.5px;
	right: 10px;
}