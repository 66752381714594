.overlay-fade {
	@include inset(0, 0, 0, 0);
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include breakpoint(phablet) {
		border-radius: 10px;
	}

	@include breakpoint(tablet) {
		right: 7.5px;
		left: 7.5px;
	}
}

.overlay-fade--exit {
	animation: fadeOut $aircraft-filter-speed;
	visibility: visible;
}

.overlay-fade--enter {
	animation: fadeIn $aircraft-filter-speed;
	visibility: visible;
}