.listing {
	border: 1px solid rgba($c-grey-03, 0.3);
	margin-top: 20px;
	border-radius: 4px;

	&--expired {
		padding-right: 10px;
	}

	&--expiring {
		border: 1px solid $c-red;
		padding-right: 10px;
	}

	@include breakpoint(notebook) {
		display: grid;
		grid-template-columns: 2fr 1fr 35px;
		column-gap: 10px;
	}

	@include breakpoint(laptop) {
		grid-template-columns: 1.8fr 1fr 50px;
	}

	&__flight-details {
		@include font(16px, 24px);
		padding: 15px;

		@include breakpoint(phablet) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		> * {

			@include breakpoint(phablet) {
				@include margin(right, 10px);
			}

			&:only-child {

				@include breakpoint(phablet) {
					margin: auto;
				}
			}
		}

		&__date-info {
			@extend %mediumWeight;

			color: $c-grey-02;

			&--expiring {
				color: $c-red;

				b {
					@extend %boldWeight;
				}
			}
		}

		&__shy-ref {
			@extend %boldWeight;
			color: $c-grey-dark;
			text-transform: uppercase;
		}

		&__trip-type {
			@extend %lightWeight;
		}
	}

	&__journey-leg {
		margin: 0 15px 25px;
		grid-column: 1/2;
	}

	&__ctas {
		@include breakpoint(notebook) {
			grid-column: 2/2;
			grid-row: 1/3;
			align-self: center;
		}

		@include breakpoint(laptop) {
			width: 90%;
			justify-self: flex-end;
		}

		&__in-preparation {
			@include font(16px, 18px);
			background-color: $c-pending;
			padding: 20px 8px;
			color: #595959;
			text-align: center;
		}

		&__pending-selection {
			background-color: $c-pending;
			padding: 12px 8px;
			color: #595959;

			&__text {
				display: flex;
				flex-direction: row;
				margin-bottom: 0.5em;
				align-items: center;
				justify-content: center;

				&:last-child {
					margin-bottom: 0;
				}

				img {
					margin-right: 10px;
				}
			}
		}

		&__pending-payment {
			background-color: rgba($c-red, 0.2);
			padding: 12px 8px;
		}

		&__pending-payment-confirmation {
			background-color: rgba($c-red, 0.2);
			padding: 20px 8px;
		}

		&__pending-document {
			background-color: $c-pending;
			padding: 30px 15px;
			text-align: center;

			@include breakpoint(notebook) {
				padding: 24px 15px;
				margin: 0 8px;
			}

			a {
				color: $c-grey-02;
				&:hover {
					border-bottom: 1px $c-grey-02 solid;
				}
			}
		}

		&__copy-quote {
			margin-top: 8px;

			button {
				margin: auto;
				width: calc(100% - 16px);
				@include breakpoint(notebook) {
					width: 100%;
				}
			}
		}

		&__buttons-wrapper {
			padding: 12px 8px 0;
			@include breakpoint(notebook) {
				position: relative;
			}
		}

		&__contact-widget-wrapper {
			position: fixed;
			bottom: 0;
			right: 0;
			z-index: 9995;
			@include breakpoint(notebook) {
				bottom: 50%;
				right: 50%;
				position: absolute;
			}
		}

		&__contact-widget-wrapper--loaded {
			@include breakpoint(phablet) {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				padding: $site-gutter--tablet;
				overflow: auto;
			}
		}
	}

	&__more-details {
		margin: 20px auto;
		text-align: center;

		@include breakpoint(notebook) {
			position: relative;
			grid-column: 3;
			grid-row: 1/3;
			width: 100%;
			margin: 0;
			text-align: left;

			img {
				display: block;
			}
		}

		a {
			@extend %boldWeight;
			color: $c-primary-blue;
			border-bottom: 1px solid $c-primary-blue;

			img {
				display: none;
			}

			@include breakpoint(notebook) {
				color: transparent;
				font-size: 0;
				line-height: 0;
				border-bottom: none;

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					padding: 0;
					margin: 0;
					transform: translate(-50%, -50%);
					display: block;
				}
			}
		}
	}

	&__cover {
		position: fixed;
		@include inset(0, 0, 0, 0);
		background-color: rgba($c-grey-dark, 0);
		z-index: 9995;
		transition: background-color 500ms;
		pointer-events: none;
		display: none;

		@include breakpoint(notebook) {
			display: block;
		}

			&--visible {
			pointer-events: auto;
			background-color: rgba($c-grey-dark, 0.4);
		}
	}
}