// Base font size of site
$base-font-size: 16px;

// Directory that the fonts are served from
$fonts-directory: '/assets/fonts/';

// Font definitions
$f-primary-stack: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$f-primary: (
	font-family: $f-primary-stack
);

$f-secondary-stack: 'Calligraffitti', 'Brush Script MT', cursive;
$f-secondary: (
		font-family: $f-secondary-stack
);

// Site max width
$site-max-width: 1400px;

// Horizontal spacing
$site-gutter: 15px;
$site-gutter--tablet: 30px;

// Header height
$header-height: 56px;
$header-height--phablet: 148px;