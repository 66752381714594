/// Construct a media query using a given breakpoint
///
/// @param {String} $breakpoint The name of the breakpoint at which to apply the styles
/// @author Joe Adcock <joe.adcock@damdigital.com>

@mixin breakpoint($breakpoint) {
    @if map-get($breakpoints, $breakpoint) {
        @media screen and (map-get($breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @media screen and ($breakpoint) {
            @content;
        }
    }
}