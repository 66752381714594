.request-call-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 40px 10px;

	&__title {
		@include font(20px);
		color: $c-grey-dark;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 25px;

		@include breakpoint(phablet) {
			@include font(22px);
		}
	}

	&__body {
		@include font(16px, 22px);
		color: $c-grey-dark;
		text-align: center;
		margin-bottom: 25px;

		p {
			margin-bottom: 1em;
		}
	}

}