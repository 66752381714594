.file-upload-input {
	margin-bottom: 35px;
	position: relative;

	&--inline {
		flex: 1;
		background-color: pink;
		margin: 0 auto 10px;

		.file-upload-input__drop-zone {
			height: 42px;
		}
	}

	&__drop-zone {
		@include font(16px);
		background-color: $c-grey-05;
		border-radius: 4px;
		border: 1px solid $c-grey-03;
		text-align: center;
		padding: 0 15px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 70px;

		&--active {
			cursor: pointer;
		}

		&__text {
			max-width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			line-height: 30px;
		}

		&__remove-button {
			@extend %hideText;
			flex-shrink: 0;
			height: 20px;
			width: 20px;
			margin-left: 5px;
			border-radius: 100%;
		}

		label {
			@include apply-map($f-primary);
			color: $c-grey-dark;
			cursor: pointer;
		}
	}
}