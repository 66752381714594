.bank-transfer-instructions {
	position: relative;
}

	.bank-transfer-instructions__intro{
		@include font(16px, 22px);
	}

	.bank-transfer-instructions__grid {
		@include font(16px, 22px);
		min-height: 200px;
		position: relative;
		margin-top: 35px;
		margin-bottom: 45px;
		display: grid;
		row-gap: 18px;
		column-gap: 15px;
		grid-template-columns: 1fr 1.5fr;
		word-break: break-word;

		@include breakpoint(tablet) {
			grid-template-columns: 1fr 2fr;
			margin-bottom: 60px;
		}
	}