.sort {
	width: 100%;
}

.sort--active {
	position: relative;
	z-index: 1;
}

	.sort__container {
		height: 100%;
		border: 1px solid rgba($c-grey-03, 0.3);
		border-radius: 4px;
	}

		.sort__toggle {
			@include font(14px, 19px);

			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 16px;
			color: $c-primary-blue;
			text-align: left;
			white-space: nowrap;

			@include breakpoint(tablet) {
				@include font(16px, 19px);
			}

			.sort--active & {
				border-radius: 4px 4px 0 0;
			}

			img {
				margin-left: 10px;

				.sort--active & {
					transform: rotate(180deg);
				}
			}
		}

		.sort__content {
			position: relative;
		}

			.sort__dropdown {
				position: absolute;
				top: -2px;
				left: -1px;
				width: calc(100% + 2px);
				display: none;

				.sort--active & {
					display: block;
				}
			}

				.sort__options {
					padding: 2px 16px 16px;
					background: $c-white;
					border: 1px solid rgba($c-grey-03, 0.3);
					border-top: none;
					border-radius: 0 0 4px 4px;

					&:before {
						content: '';
						display: block;
						height: 1px;
						margin-bottom: 16px;
						background: rgba($c-gold, 0.5);
					}
				}

					.sort__option {
						@include margin(bottom, 24px);
						@extend %lightWeight;

						display: flex;
						align-items: flex-start;
						text-align: left;
					}

					.sort__option--active {
						@extend %regularWeight;
					}