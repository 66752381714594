.photo-carousel {
    position: absolute;
    height: 100%;
    width: 100%;
}

    .photo-carousel__counter {
        @extend %lightWeight;
        @include inset(8px, 8px);
        @include font(16px, 16px);
        background: rgba($c-primary-blue, .5);
        color: $c-grey-01;
        display: flex;
        padding: 5px 17px;
        border-radius: 2px;
        position: absolute;
    }

    .photo-carousel__banner-title{
        @extend %lightWeight;
        @include inset(0, 0, auto, 0);
        @include font(20px, 22px);
        display: flex;
        padding: 0 15px;
        justify-content: center;
        align-items: center;
        position: absolute;
        background: $c-gold;
        height: 42px;
        text-transform: uppercase;

        @include breakpoint(phablet){
            justify-content: center;
        }
    }

    .photo-carousel__single-image {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }