.link-with-icon {
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	margin-top: 3px;
	line-height: 20px;

	img {
		width: 24px;
		margin-right: 5px;
	}

	span {
		border-bottom: solid 1px $c-grey-dark;
	}
}