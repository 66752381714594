.background {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	filter: grayscale(20%) contrast(110%);

	&--absolute {
		position: absolute;
	}

	&--full-height {
		width: 100%;
		height: 100%;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: top left;
	}

	&__video {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;

		video {
			position: absolute;
			left: 0;
			top: 0;
			min-width: 100vw;
			min-height: 100vh;
		}

		&--loaded {
			opacity: 1;
		}
	}

	&__cover {
		@include inset(0, 0, 0, 0);

		position: absolute;
		background-color: $c-black;
		opacity: 0.4;
	}
}