.newsletter {
    height: 620px;
    background: rgba(0, 0, 0, 0.3);

    @include breakpoint(phablet) {
        height: 591px;
    }

    @include breakpoint(tablet) {
        height: 494px;
    }
}