.three-dots {
	position: relative;
}

	.three-dots__button {
		@extend %hideText;
		background: transparent;
		position: relative;
		height: 36px;
		width: 28px;
	}

	.three-dots__button--active {
		z-index: 1;
	}

		.three-dots__button__dots-wrapper {
			@include inset(0, 0, 0, 0);
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 8px 0;

			span {
				position: relative;
				margin: 0;
				padding: 0;
				background: $c-primary-blue;
				height: 3.5px;
				width: 3.5px;
				border-radius: 100%;
			}
		}

	.three-dots__content {
		position: absolute;
		padding: 5px;
		top: 50%;
		right: calc(100% + 5px);
		transform: translate(0, -50%) scale(0.8);
		background-color: $c-grey-01;
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		opacity: 0;
		transition: opacity 250ms, transform 350ms, visibility 0s 350ms;
		visibility: hidden;
		box-shadow: 2px 2px 6px rgba($c-grey-dark, 0.25);
	}

		.three-dots__content__tail-container {
			position: absolute;
			height: 50px;
			width: 20px;
			right: 0.25px;
			top: 50%;
			transform: translate(100%, -50%);
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:before {
				display: block;
				content: '';
				height: 20px;
				width: 20px;
				margin-left: -14px;
				background-color: $c-grey-01;
				border-radius: 3px;
				transform: rotate(35deg) skewX(-20deg);
				box-shadow: 2px 2px 6px rgba($c-grey-dark, 0.25);
			}
		}

	.three-dots__content--visible {
		transition: opacity 250ms, transform 350ms;
		opacity: 1;
		transform: translate(0, -50%) scale(1);
		visibility: visible;
		z-index: 1;
	}