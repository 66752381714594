.card-payment {}

	.card-payment__card-info {
		@extend %lightWeight;
		@include font(14px, 17px);
		margin-top: -24px;
		margin-bottom: 20px;
	}

	.card-payment__notice {
		display: flex;
		align-items: flex-start;
		margin: 0 auto 30px;
		width: 330px;
		max-width: calc(100vw - 64px);

		img {
			margin-top: 3px;
			margin-right: 10px;
		}
		
		p {
			@include font(14px, 20px);
			@extend %lightWeight;

			@include breakpoint(tablet) {
				@include font(16px, 22px);
			}
		}
	}

	.card-payment__select-card {
		margin: 0 auto 30px;
		width: 330px;
		max-width: calc(100vw - 64px);

		p {
			@include font(16px, 22px);
			@extend %lightWeight;
			margin-top: 0.25em;
			margin-bottom: 1em;

			b {
				font-weight: 400;
			}
		}
	}