.ambassador-form {

    @include breakpoint(tablet) {
        max-width: 772px + ($site-gutter--tablet * 2);
        margin: 0 auto;
    }

    &--thanks {
        text-align: center;
    }
}

    .ambassador-form__intro {
        @include margin(bottom, 40px);

        @include breakpoint(tablet) {
            @include margin(bottom, 35px);
        }

        p {
            @include font(16px, 22px);
            @include margin(bottom, 1em);

            &:last-child {
                @include font(14px, 22px);
                @extend %lightWeight;

                @include breakpoint(tablet) {
                    @include font(16px, 22px);
                }
            }
        }
    }

    .ambassador-form__fields {
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -6px 30px;
        }

        > * {

            @include breakpoint(tablet) {
                width: 50%;
                padding: 0 6px;
            }

            &:last-child {
                
                @include breakpoint(tablet) {
                    width: 100%;
                }
            }
        }

        .text-input__input {
            color: $c-grey-01;
            background: transparent;

            &:focus,
            &:not(.text-input__input--error, .text-input__input--success) {
                border-color: $c-pending;
            }
        }

        .text-area-input__text-area {
            color: $c-grey-01;
            background: transparent;

            &:focus,
            &:not(.text-area-input__text-area--error, .text-area-input__text-area--success) {
                border-color: $c-pending;
            }
        }

            .label {
                color: inherit;
            }

        .select-input {

            .select-input__control {
                background: transparent;
                border-color: $c-pending;
            }

            &.select-input--error .select-input__control {
                border-color: $c-red;
            }
        }

        .select-input--form {
            color: $c-grey-01;

            .select-input__placeholder,
            .select-input__single-value {
                color: inherit;
            }
        }
    }

    .ambassador-form__captcha {
        margin-bottom: 45px;
    }

// #crmWebToEntityForm.zcwf_lblLeft {
//     width:100%;
//     padding: 25px;
//     margin: 0 auto;
//     box-sizing: border-box;
// }
// #crmWebToEntityForm.zcwf_lblLeft * {
//     box-sizing: border-box;
// }
// #crmWebToEntityForm{text-align: left;}
// #crmWebToEntityForm * {
//     direction: ltr;
// }
// .zcwf_lblLeft .zcwf_title {
//     word-wrap: break-word;
//     padding: 0px 6px 10px;
//     font-weight: bold;
// }
// .zcwf_lblLeft .zcwf_col_fld input[type=text], .zcwf_lblLeft .zcwf_col_fld textarea {
//     width: 60%;
//     border: 1px solid #c0c6cc !important;
//     resize: vertical;
//     border-radius: 2px;
//     float: left;
// }
// .zcwf_lblLeft .zcwf_col_lab {
//     width: 30%;
//     word-break: break-word;
//     padding: 0px 6px 0px;
//     margin-right: 10px;
//     margin-top: 5px;
//     float: left;
//     min-height: 1px;
// }
// .zcwf_lblLeft .zcwf_col_fld {
//     float: left;
//     width: 68%;
//     padding: 0px 6px 0px;
//     position: relative;
//     margin-top: 5px;
// }
// .zcwf_lblLeft .zcwf_privacy{padding: 6px;}
// .zcwf_lblLeft .wfrm_fld_dpNn{display: none;}
// .dIB{display: inline-block;}
// .zcwf_lblLeft .zcwf_col_fld_slt {
//     width: 60%;
//     border: 1px solid #ccc;
//     background: #fff;
//     border-radius: 4px;
//     font-size: 12px;
//     float: left;
//     resize: vertical;
//     padding: 2px 5px;
// }
// .zcwf_lblLeft .zcwf_row:after, .zcwf_lblLeft .zcwf_col_fld:after {
//     content: '';
//     display: table;
//     clear: both;
// }
// .zcwf_lblLeft .zcwf_col_help {
//     float: left;
//     margin-left: 7px;
//     font-size: 12px;
//     max-width: 35%;
//     word-break: break-word;
// }
// .zcwf_lblLeft .zcwf_help_icon {
//     cursor: pointer;
//     width: 16px;
//     height: 16px;
//     display: inline-block;
//     background: #fff;
//     border: 1px solid #c0c6cc;
//     color: #c1c1c1;
//     text-align: center;
//     font-size: 11px;
//     line-height: 16px;
//     font-weight: bold;
//     border-radius: 50%;
// }
// .zcwf_lblLeft .zcwf_row {margin: 15px 0px;}
// .zcwf_lblLeft .formsubmit {
//     margin-right: 5px;
//     cursor: pointer;
//     color: var(--baseColor);
//     font-size: 12px;
// }
// .zcwf_lblLeft .zcwf_privacy_txt {
//     width: 90%;
//     color: rgb(0, 0, 0);
//     font-size: 12px;
//     font-family: Arial;
//     display: inline-block;
//     vertical-align: top;
//     color: var(--baseColor);
//     padding-top: 2px;
//     margin-left: 6px;
// }
// .zcwf_lblLeft .zcwf_button {
//     font-size: 12px;
//     color: var(--baseColor);
//     border: 1px solid #c0c6cc;
//     padding: 3px 9px;
//     border-radius: 4px;
//     cursor: pointer;
//     max-width: 120px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }
// .zcwf_lblLeft .zcwf_tooltip_over{
//     position: relative;
// }
// .zcwf_lblLeft .zcwf_tooltip_ctn{
//     position: absolute;
//     background: #dedede;
//     padding: 3px 6px;
//     top: 3px;
//     border-radius: 4px;word-break: break-word;
//     min-width: 100px;
//     max-width: 150px;
//     color: var(--baseColor);
//     z-index: 100;
// }
// .zcwf_lblLeft .zcwf_ckbox{
//     float: left;
// }
// .zcwf_lblLeft .zcwf_file{
//     width: 55%;
//     box-sizing: border-box;
//     float: left;
// }
// .clearB:after{
//     content:'';
//     display: block;
//     clear: both;
// }
// @media all and (max-width: 600px) {
//     .zcwf_lblLeft .zcwf_col_lab, .zcwf_lblLeft .zcwf_col_fld {
//                     width: auto;
//                     float: none !important;
//     }
//     .zcwf_lblLeft .zcwf_col_help {width: 40%;}
// }
