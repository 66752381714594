.compare {
	@extend %lightWeight;
	@include font(16px, 18px);
	color: $c-grey-dark;
	margin-bottom: 40px;

	&__scroll-wrapper {
		@extend %scrollHorizontal;
		width: 100%;
		max-width: 100vw;
		position: relative;
		padding: 1px 0 15px;
		margin: 0 auto 50px;

		@include breakpoint(phablet) {
			margin-top: 10px;
		}

		@include breakpoint(tablet) {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			width: fit-content;
		}
	}

	&__table-fixed-header {
		@extend %mediumWeight;
		margin-bottom: 5px;

		@include breakpoint(tablet) {
			margin-bottom: 0;
			margin-right: 2px;

			.compare__table {
				.compare__table__cell--title {
					opacity: 0;
				}

				.compare__table__cell--header {
					width: 200px;
					height: 52px;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					padding-right: 10px;
				}
			}
		}
	}

	&__table-backgrounds {
		position: relative;
		width: fit-content;

		&__background1 {
			position: absolute;
			top: -1px;
			left: 0;
			bottom: -1px;
			width: 151px;
			background-color: $c-grey-07;
			@include breakpoint(tablet) {
				display: none;
			}
		}

		&__background2 {
			position: absolute;
			top: -1px;
			left: 155px;
			bottom: -1px;
			right: 0;
			background-color: $c-grey-07;
			@include breakpoint(tablet) {
				display: none;
			}
		}

		&__background-desktop {
			position: absolute;
			display: none;
			width: auto;
			top: 87px;
			bottom: 0;
			left: 0;
			right: 0;
			background: repeating-linear-gradient(
							rgba($c-grey-01, 0.6),
							rgba($c-grey-01, 0.6) 52px,
							transparent 52px,
							transparent 53px,
							rgba($c-grey-01, 0.6) 53px,
							rgba($c-grey-01, 0.6) 105px,
							transparent 105px,
							transparent 106px
			);
			@include breakpoint(tablet) {
				display: block;
			}
		}
	}

	&__table {
		width: fit-content;
		display: grid;
		grid-template-columns: repeat(20, 1fr);
		row-gap: 1px;
		column-gap: 1px;
		position: relative;

		@include breakpoint(tablet) {
			grid-template-columns: none;
			grid-auto-flow: column;
			grid-template-rows: auto repeat(19, 52px);
			grid-auto-rows: auto;
			grid-auto-columns: auto;
			column-gap: 6px;
		}

		&__cell {
			background-color: $c-grey-for-table;
			width: 60px;
			text-align: center;
			padding-top: 20px;
			padding-bottom: 30px;

			@include breakpoint(tablet) {
				width: 160px;
				padding: 5px;
				justify-content: center;
				align-items: center;
				display: flex;
			}

			&__text {
				display: none;
				@extend %lightWeight;
				@include breakpoint(tablet) {
					margin-right: 8px;
					display: block;
				}
			}

			&--title {
				position: sticky;
				left: 0;
				background-color: $c-pending;
				padding: 16px 10px 12px 8px;
				margin-right: 5px;
				width: 150px;
				text-align: left;

				b {
					@extend %mediumWeight
				}

				@include breakpoint(tablet) {
					@include font(16px, 22px);
					position: relative;
					display: block;
					padding: 10px;
					text-align: center;
					margin-right: 0;
					width: 160px;
					border-radius: 4px 4px 0 0;
				}
			}

			&--gold {
				background-color: $c-gold;

				@include breakpoint(tablet) {
					background-color: $c-gold-light;
				}
			}

			img {
				margin-top: -2px;
				height: 22px;
			}

			&--header {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				height: 50px;

				img {
					margin: 0;
					height: 28px;
					max-width: 28px;
				}

				@include breakpoint(tablet) {
					margin-bottom: 1px;
				}
			}

			&--cruise-speed {
				width: 100px;

				@include breakpoint(tablet){
					width: 160px;
				}
			}
		}

		@include breakpoint(tablet) {
			.compare__table__cell--title.compare__table__cell--gold {
				background-color: $c-gold;
			}
		}
	}
}