.share-recipient {

	&:last-child {

		&:after {
			display: none;
		}
	}

	&:after {
		content: '';
		display: block;
		width: 100px;
		height: 1px;
		margin: 16px auto;
		background-color: $c-gold;

		@include breakpoint(tablet) {
			width: 200px;
			margin: 20px auto;
		}
	}
}

	.share-recipient__name {
		@include font(14px, 19px);
		@include margin(bottom, 4px);
		@extend %lightWeight;

		@include breakpoint(tablet) {
			@include font(16px, 22px);
		}
	}

	.share-recipient__email {
		@include margin(bottom, 10px);
	}

	.share-recipient__remove-recipient {
		@include margin(bottom, 35px);

		text-decoration: underline;

		&:before {
			content: '- ';
		}
	}

	.share-recipient__add-recipient {
		@include margin(bottom, 35px);

		text-decoration: underline;

		&:before {
			content: '+ ';
		}
	}

	.share-recipient__request-approval {
		display: flex;

		label {
			margin-right: 30px;
		}

		button {
			flex: 0 0 auto;
			margin-right: 20px;
		}
	}