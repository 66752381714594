/// Set a font-weight
///
/// @param {String} $font-weight The desired font-weight (optional)
/// @author Joe Adcock <joe.adcock@damdigital.com>

@mixin font-weight($font-weight: regular) {
    @if $font-weight == 'thin' {
        font-weight: 100;
    }
    @if $font-weight == 'light' {
        font-weight: 300;
    }
    @if $font-weight == 'regular' {
        font-weight: 400;
    }
    @if $font-weight == 'medium' {
        font-weight: 500;
    }
    @if $font-weight == 'bold' {
        font-weight: 700;
    }
}