.footer {
	position: relative;
	padding: 40px 0 60px;
	text-align: center;

	// theme
	color: var(--footer-color);
	background: var(--footer-bg);

	@include breakpoint(notebook) {
		padding: 10px 0 60px;
	}

	.wrap {
		position: relative;
	}

	&__logo {
		width: 124px;
		margin: auto;

		@include breakpoint(notebook) {
			position: absolute;
			left: 30px;
			
		}
	}

    &__powered-by-logo {

		@include breakpoint(notebook) {
			position: absolute;
			top: 39%;
			left: 30px;
			transform: translateY(-50%);
		}

        img {
            width: 300px;

            @include breakpoint(notebook) {
            	width: 200px;
            }

            @include breakpoint(laptop) {
            	width: 250px;
            }

            @include breakpoint(desktop) {
            	width: 300px;
            }
        }

        p {
            @include font(14px, 17px);
            @extend %boldWeight;

            margin-top: 5px;
            color: $c-gold;
        }
    }

	&__socials {
		margin: 40px auto;
		width: 85vw;
		display: flex;
		flex-direction: row;
		justify-content: center;
		max-width: 360px;

		@include breakpoint(notebook) {
			max-width: 420px;
		}

		a {
			@extend %hideText;

			position: relative;
			flex-shrink: 0;
			height: 36px;
			width: 36px;
			margin: 0 10px;

			@include breakpoint(phablet) {
				height: 40px;
				width: 40px;
				margin: 0 20px;
			}

			&:hover {

				img:nth-child(1) {
					opacity: 0;
				}

				img:nth-child(2) {
					opacity: 1;
				}

				svg {
					fill: var(--footer-hover-icon);
				}
			}

			img,
			svg {
				width: 100%;
				transition: opacity $footer-icon-speed ease;

				&:nth-child(2) {
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
				}
			}
		}
	}

	&__links {
		display: flex;
		flex-direction: row;
		margin: auto;

		@include breakpoint(phablet) {
			width: 81vw;
			max-width: 480px;
		}

		@include breakpoint(tablet) {
			flex-direction: column;
			width: 100%;
			max-width: none;
			flex-wrap: wrap;
			padding-bottom: 30px;
		}

		&__column {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@include breakpoint(tablet) {
				display: block;
				width: 100%;
			}

			a,
			button {
				border-bottom: 1px solid $c-grey-01;
				margin-bottom: 40px;
				padding-bottom: 2px;
				line-height: 1;

				&:hover {

					// theme
					color: var(--footer-hover-color);
					border-bottom-color: currentColor;
				}

				@include breakpoint(tablet) {
					margin: 0 20px 40px;
					display: inline-block;
				}

				@include breakpoint(notebook) {
					margin: 0 30px 40px;
				}
			}
		}
	}

	&__lifestyle-info {
		padding: 10px;
		border-top: 0.5px solid $c-gold;
		border-bottom: 0.5px solid $c-gold;
		@include breakpoint(phablet) {
			padding: 15px;
		}

		&__text {
			@include font(16px, 24px);
			@extend %lightWeight;
			max-width: 200px;
			margin: auto;
			border-bottom: 1px solid transparent;

			@include breakpoint(phablet) {
				max-width: none;
			}

			a {
				@extend %regularWeight;

				margin-left: 2px;
				border-bottom: 1px solid $c-grey-01;
			}
		}
	}

	&__final-links {
		@include breakpoint(notebook) {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding-top: 20px;
		}

		&__bitpay {
			width: 100%;
			margin: 45px auto;
			max-width: 200px;

			@include breakpoint(notebook) {
				margin: 0;
				order: 3;
			}

			@include breakpoint(notebook) {
				max-width: 240px;
			}
		}

		&__legal-info {
			@extend %thinWeight;

			@include breakpoint(notebook) {
				display: flex;
				flex-direction: row;
				order: 1;
				justify-items: center;
				align-items: center;
				margin-top: 3px;
			}

			a,
			button {
				@extend %thinWeight;
				display: inline-block;
				margin-bottom: 20px;
				border-bottom: 0.5px solid transparent;
				padding-bottom: 2px;

				&:hover {
					border-bottom: 0.5px solid $c-grey-01;
				}

				@include breakpoint(notebook) {
					margin: 0;
				}
			}

			&__divide {
				@include breakpoint(notebook) {
					height: 10px;
					width: 1px;
					background-color: $c-grey-01;
					margin: auto 10px;
				}
			}
		}

		&__copyright {
			@extend %thinWeight;

			@include breakpoint(notebook) {
				order: 0;
			}
		}
	}
}

// Hide the cookieyes button off the page
.cky-btn-revisit-wrapper,
.cky-btn-revisit {
	top: 100vh!important;
	left: 100vh!important;
}