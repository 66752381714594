.payment {
	display: flex;
	flex-direction: column;

	@include breakpoint(tablet) {
		flex-direction: row;
		justify-content: center;
	}
}

	.payment__body {
		margin: auto;
		width: 100%;
		max-width: 500px;

		@include breakpoint(tablet) {
			max-width: 600px;
			flex: 1;
			margin: 40px 0;
		}
	}

	.payment__aside {
		max-width: 500px;
		margin: auto;

		@include breakpoint(tablet) {
			max-width: 300px;
			margin: 0;
		}
	}