.quote-query {
	color: $c-grey-06;
	position: relative;
	max-width: 500px;
	margin: auto;
	width: 100%;
    padding-bottom: 60px;
	
	@include breakpoint(tablet) {
		width: calc(100% - 200px);
		max-width: none;
    	padding-bottom: var(--quote-query-padding-bottom);
	}

	@include breakpoint(notebook) {
		width: 100%;
	}
}

.quote-query--on-page {
    padding-top: var(--quote-query-padding-top);
}

.quote-query--scrolled {
	position: fixed;
	top: $header-height;
	right: 0;
	left: 0;
	max-width: none;
    box-shadow: 0px 1px 4px 0px rgba($c-grey-dark, 0.4);
	z-index: 1;

    // theme
    background: var(--quote-query-scrolled-bg);

	@include breakpoint(tablet) {
		width: 100%;
	}
}

.quote-query--scrolled-overflow {
	bottom: 0;
	overflow: auto;
}

.quote-query--in-modal {

	@include breakpoint(tablet) {
		width: 100%;
	}
}

.quote-query__content {

	.quote-query--scrolled & {
		display: none;

		@include breakpoint(tablet) {
			display: block;
		}
	}
}

.quote-query__sticky-title {
	@extend %mediumWeight;

	margin: 56px 15px 7px;
	text-transform: uppercase;
	letter-spacing: 0.05em;

	// theme
	color: var(--quote-query-scrolled-color);
}

.quote-query__content--active {
	margin-top: 40px;
	margin-bottom: 40px;
	display: block;

	.quote-query--scrolled & {
		display: block;
	}
}


.quote-query__expand-button {
	position: absolute;
	top: 0;
	left: 0;
    flex-direction: row-reverse;
    width: 100%;
    border: none;
    border-radius: 0;

    // theme
    color: var(--quote-query-scrolled-color);
    background: var(--quote-query-scrolled-bg);

	@include breakpoint(tablet) {
		display: none;
	}
	
	.quote-query__content--active + & img,
	.quote-query__content--active + & svg { 
		transform: rotate(180deg);
	}

	img,
	svg {
		width: 12px;
		margin-left: 15px;
		margin-top: 5px;
	}

	svg {
		fill: var(--quote-query-expand-icon);
	}
}

.quote-query__inputs {
	display: flex;
	flex-direction: column;
	padding: 16px 16px 5px;
	border-radius: 4px;
	background-color: transparent;
	transition: background-color 200ms;
	flex-wrap: wrap;
	position: relative;

	@include breakpoint(tablet) {
		padding: 0;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.quote-query--in-modal & {
		display: block;
		padding: 0;
	}

	fieldset {
		margin-bottom: 16px;

		@include breakpoint(tablet) {
			margin-bottom: 8px;
		}

		.quote-query--in-modal & {
			margin-bottom: 16px;
		}
	}

	.quote-query--in-modal & .fieldset--has-error {
		margin-bottom: 32px;
	}
}

.quote-query__inputs--with-delete {
	margin-bottom: 22px;
	padding: 16px;
	background-color: rgba($c-grey-04, 0.4);
	@include breakpoint(tablet) {
		padding: 0;
		background-color: transparent;
		margin-bottom: 12px;
	}
	@include breakpoint(notebook) {
		margin-bottom: 0;
	}

	.quote-query--in-modal & {
		margin: -14px -14px (14px + 16px);
		padding: 14px 14px 24px;
		background: rgba($c-input-grey, 0.4);
	}
}

	.quote-query__inputs__column {
		flex: 1;
		min-width: 150px;

		fieldset:last-of-type {
			margin-right: 0;
		}

		@include breakpoint(tablet) {
			flex: 1;
			min-width: calc(33.333% - 8px);
			margin-right: 8px;

			&:nth-of-type(3) {
				margin-right: 0;
			}
		}

		@include breakpoint(notebook) {
			flex: 1;
			min-width: 0;
			&:nth-of-type(3) {
				margin-right: 8px;
			}
		}

		.quote-query--in-modal & {
			margin-right: 0;
		}
	}

	.quote-query__inputs__column--medium-width {
		@include breakpoint(desktop) {
			flex: 0.75;
		}
	}

	.quote-query__inputs__column--shared {
		display: flex;
		flex-direction: row;

		fieldset {
			margin-right: 8px;
		}

		@include breakpoint(mobile) {
			width: 75%;
		}

		@include breakpoint(tablet) {
			margin-right: 0;
			min-width: calc(66.6666% - 3px);
		}

		@include breakpoint(notebook) {
			max-width: 275px;
			min-width: 275px;
		}
	}

	.quote-query__actions {
		padding: 0 16px;
		display: flex;
		flex-direction: column;

		@include breakpoint(tablet) {
			padding: 0;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.quote-query--in-modal & {
			display: block;
		}
	}

		.quote-query__submit-container {
			width: 100%;

			@include breakpoint(tablet) {
				width: calc(33.333% - 5px);
			}

			@include breakpoint(notebook) {
				width: 275px;
			}

			.quote-query--in-modal & {
				width: 100%;

				.button {
					@include margin(bottom, 16px);
				}
			}

			.quote-query--skeleton & {
				position: relative;

				&:after {
					@extend %skeleton;

					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border-radius: 4px;
				}
			}

			.button {

				.quote-query--scrolled & {
					// border-color: var(--quote-query-scrolled-color);
					border-color: var(--button-border);
				}
			}
		}

		.quote-query__error {
			@include font(14px, 18px);
			background-color: $c-error-red;
			color: $c-grey-01;
			height: 42px;
			padding: 0 14px;
			margin-bottom: 16px;
			border-radius: 4px;
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;

			img,
			svg {
				margin-right: 8px;
			}

			@include breakpoint(tablet) {
				order: -1;
			}

			@include breakpoint(notebook) {
				width: 300px;
				flex-shrink: 0;
				margin-right: 100%;
				order: 1;
			}
		}

		.quote-query__actions__add-leg-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 20px;
            color: var(--quote-query-add-button-color);

			img,
			svg {
				margin-right: 5px;
				
			}

			@include breakpoint(tablet) {
				margin-bottom: 0;
			}

			.quote-query--in-modal & {
				color: $c-grey-02;

				@include breakpoint(tablet) {
					margin-bottom: 20px;
				}
			}

			.quote-query--on-page & {
            	color: var(--quote-query-add-button-alt-color);
			}

			.quote-query--scrolled & {
            	color: var(--quote-query-scrolled-color);
			}

			.quote-query--skeleton & {
				@extend %skeleton;
				
				pointer-events: none;

				img,
				svg {
					display: none;
				}
			}

			svg {
				stroke: currentColor;
			}
		}

	.quote-query__delete-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 10px auto;
        color: var(--quote-query-delete-button-color);

		@include breakpoint(tablet) {
			margin: 0;
			top: 0;
			bottom: 8px;
			left: 100%;
			position: absolute;
			background-color: rgba($c-grey-07, 0.2);
			border-radius: 0 4px 4px 0;
			padding: 20px 18px 20px 20px;
		}

		@include breakpoint(notebook) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			position: relative;
			left: auto;
			top: auto;
			bottom: auto;
			padding: 0 15px;
			margin-bottom: 8px;
			width: 120px;
		}

		@include breakpoint(laptop) {
			width: 160px;
		}

		img,
		svg {
			margin-right: 10px;
		}

		.quote-query--in-modal & {
			width: auto;
			padding: 0;
			color: $c-grey-02;

			@include breakpoint(tablet) {
				background: transparent;
			}
		}

		.quote-query--on-page & {
        	color: var(--quote-query-delete-button-alt-color);
		}

		.quote-query--scrolled & {
        	color: var(--quote-query-scrolled-color);
		}

		svg {
			fill: currentColor;
		}
	}