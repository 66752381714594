@keyframes carouselIndicatorNext {
    from {
        transform: none
    }
    to {
        transform: translateX(-18px);
    }
}

@keyframes carouselIndicatorNextItem2 {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
    }
}

@keyframes carouselIndicatorNextItem3 {
    from {
        transform: scale(1.666666)
    }
    to {
        transform: scale(1);
    }
}

@keyframes carouselIndicatorNextItem4 {
    from {
    	margin: 0 10px;
        transform: scale(2.666666)
    }
    to {
    	margin: 0 6px;
        transform: scale(1.666666);
    }
}

@keyframes carouselIndicatorNextItem5 {
    from {
    	margin: 0 6px;
        transform: scale(1.666666)
    }
    to {
    	margin: 0 10px;
        transform: scale(2.666666);
    }
}

@keyframes carouselIndicatorNextItem6 {
    from {
        transform: scale(1)
    }
    to {
        transform: scale(1.666666);
    }
}

@keyframes carouselIndicatorNextItem7 {
    from {
        opacity: 0
    }
    to {
        opacity: 1;
    }
}

@keyframes carouselIndicatorPrev {
    from {
        transform: none
    }
    to {
        transform: translateX(18px);
    }
}

@keyframes carouselIndicatorPrevItem1 {
    from {
        opacity: 0
    }
    to {
        opacity: 1;
    }
}

@keyframes carouselIndicatorPrevItem2 {
    from {
        transform: scale(1)
    }
    to {
        transform: scale(1.666666);
    }
}

@keyframes carouselIndicatorPrevItem3 {
    from {
        margin: 0 6px;
        transform: scale(1.666666)
    }
    to {
        margin: 0 10px;
        transform: scale(2.666666);
    }
}

@keyframes carouselIndicatorPrevItem4 {
    from {
        margin: 0 10px;
        transform: scale(2.666666)
    }
    to {
        margin: 0 6px;
        transform: scale(1.666666);
    }
}

@keyframes carouselIndicatorPrevItem5 {
    from {
        transform: scale(1.666666)
    }
    to {
        transform: scale(1);
    }
}

@keyframes carouselIndicatorPrevItem6 {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
    }
}