.share {
	@include font(16px, 19px);

	padding: 13px 8px 26px;
	color: $c-grey-dark;

	@include breakpoint(tablet) {
		padding: 32px;
	}
}

	.share__message {
		margin: -13px 0 13px;

		@include breakpoint(tablet) {
			margin: -32px -32px 32px;
		}
	}

	.share__title {
		@include font(16px, 19px);
		@include margin(bottom, 22px);
		@extend %mediumWeight;

		padding-bottom: 13px;
		text-align: center;
		border-bottom: 1px solid $c-gold;

		@include breakpoint(tablet) {
			@include font(18px, 22px);
			@include margin(bottom, 32px);
		}
	}

	.share__intro {
		@include margin(bottom, 20px);

		@include breakpoint(tablet) {
			@include margin(bottom, 32px);
		}
	}

	.share__form {

		.fieldset--margin-bottom {
			margin-bottom: 24px;

			@include breakpoint(tablet) {
				margin-bottom: 32px;
			}
		}
	}

		.share__form-intro {
			@include margin(bottom, 14px);
			@include font(14px, 19px);
			@extend %lightWeight;

			display: flex;
			justify-content: space-between;

			@include breakpoint(tablet) {
				@include margin(bottom, 32px);
				@include font(16px, 22px);
			}
		}

		.share__form-intro--center {
			justify-content: center;
		}

			.share__add-recipient {
				@include font(16px, 19px);
				@include margin(bottom, 30px);

				text-decoration: underline;

				&:before {
					content: '+ '
				}
			}

		.share__toggle {
			display: flex;
			align-items: center;
			color: $c-grey-03;
			text-decoration: underline;

			.share__footer & {

				@include breakpoint(tablet) {
					margin-top: 30px;
					margin-right: auto;
				}
			}

			img {
				margin-right: 7px;
				transition: all $aircraft-filter-speed;

				.share--share-link-open & {
					transform: rotate(180deg)
				}
			}
		}

		.share__recipients {
			@include margin(bottom, 40px);

			@include breakpoint(tablet) {
				@include margin(bottom, 32px);
			}

			.share--2 & {
				@include margin(bottom, 15px);
			}
		}

		.share__footer {

			@include breakpoint(tablet) {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row-reverse;
			}

			.button {

				@include breakpoint(tablet) {
					width: 50%;
				}

				.share--2 & {

					@include breakpoint(tablet) {
						width: 100%;
					}
				}
			}
		}