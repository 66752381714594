.login-form {}

	.login-form__body {
		color: $c-grey-03;
		padding: 22px 15px;

		@include breakpoint(phablet) {
			padding: 45px 55px;
		}
	}

	.login-form__body--less-padding {
		padding: 22px;

		@include breakpoint(phablet) {
			padding: 30px 45px;
		}
	}

		.login-form__body__title {
			@include font(16px, 18px);
			@extend %boldWeight;
			color: $c-grey-02;
			margin-bottom: 5px;
		}

		.login-form__body__intro {
			@include font(16px, 20px);
			color: $c-grey-03;
			margin-bottom: 25px;
		}

		.login-form__body__forgot-password {
			@extend %boldWeight;
			color: $c-gold;
			float: right;
			margin-top: -5px;
			margin-bottom: 40px;
			border-bottom: 1px solid $c-gold;
		}

		.login-form__password-strength {
			@include margin(bottom, 30px);

			display: flex;
			flex-direction: row-reverse;

			> div {
				flex: 1 1 auto;
				background: $c-grey-06;
				box-shadow: inset 0px 4px 4px rgba($c-black, 0.1);
				border-radius: 10px;
				overflow: hidden;

				div {
					height: 12px !important;

					&:nth-child(2n) {
						display: none;
					}
				}
			}

			p {
				@include font(16px, 19px);

				flex: 0 0 auto;
				margin: 0 15px 0 0 !important;
				color: $c-grey-03 !important;
			}
		}

		.login-form__body__submit-container {
			max-width: 288px;
			margin: auto;
		}

		.login-form__body__recover-password-icon {
			margin: 8px auto 18px;
			display: block;
		}

		.login-form__body__recover-password-title {
			@include font(20px);
			color: $c-grey-dark;
			text-align: center;
			margin-top: 10px;
			margin-bottom: 25px;

			@include breakpoint(phablet) {
				@include font(22px);
			}
		}

		.login-form__body__recover-password-text {
			@include font(16px, 22px);
			color: $c-grey-dark;
			text-align: center;
			margin-bottom: 25px;

			p {
				margin-bottom: 1em;
			}

			a {
				text-decoration: none;
				border-bottom: 1px solid $c-grey-dark;
			}
		}

		.login-form__body__back-to-login {
			color: $c-gold-03;
			margin: 30px auto 10px;
			display: block;
			text-transform: uppercase;
		}

	.login-form__success {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 50px;
		padding-bottom: 30px;

		&__tick {
			margin-bottom: 10px;
		}

		&__message {
			@include font(16px, 22px);
			text-align: center;
			max-width: 200px;
			margin-bottom: 60px;
		}
	}