.block-text-and-image {
	max-width: 1340px;
	margin-right: auto;
	margin-left: auto;

	.block-header {
		@include margin(bottom, 32px);
	}
}

	.block-text-and-image__grid {
		overflow: hidden;

		@include breakpoint(tablet) {
			display: flex;
			flex-direction: row-reverse;
		}

		.block-text-and-image--alt & {

			@include breakpoint(tablet) {
				flex-direction: row;
			}
		}

		.parallax-block-hero + .parallax-block-hero &,
		.parallax-block-hero + .parallax-block-textWithCta &,
		.parallax-block-hero + .parallax-block-testimonial &,
		.parallax-block-textWithCta + .parallax-block-hero &,
		.parallax-block-textWithCta + .parallax-block-textWithCta &,
		.parallax-block-textWithCta + .parallax-block-testimonial &,
		.parallax-block-testimonial + .parallax-block-hero &,
		.parallax-block-testimonial + .parallax-block-textWithCta &,
		.parallax-block-testimonial + .parallax-block-testimonial &,
		.block-hero + .block-hero &,
		.block-hero + .block-text-and-image &,
		.block-text-and-image + .block-hero &,
		.block-text-and-image + .block-text-and-image & {

			@include breakpoint(tablet) {
				border-top: 1px solid $c-primary-blue;
			}
		}
	}

		.block-text-and-image__image {
			@include aspect(375 / 240);

			position: relative;
			margin: 0 #{-$site-gutter};

			@include breakpoint(tablet) {
				width: 50%;
				margin: 0;
			}

			.block-text-and-image--skeleton & {
				@extend %skeleton;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.block-text-and-image__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 24px $site-gutter;
			background: $c-grey-01;

			@include breakpoint(tablet) {
				width: 50%;
				min-height: calc(100vh - $header-height); // viewport height - collapsed header height
				padding: 48px;
			}

			.landing-page__content > :first-child .block-text-and-image--private-jets & {

				@include breakpoint(tablet) {
					min-height: calc(100vh - $header-height--phablet - 152px); // viewport height - header height - quote height
				}

				@include breakpoint(notebook) {
					min-height: calc(100vh - $header-height--phablet - 102px); // viewport height - header height - quote height
				}
			}

			.landing-page__content > :first-child .block-text-and-image--service & {

				@include breakpoint(tablet) {
					min-height: calc(100vh - $header-height--phablet); // viewport height - header height
				}
			}

			.testimonials {
				margin-right: -#{$site-gutter};
				margin-left: -#{$site-gutter};
				@include margin(top,48px);
			}
		}

			.block-text-and-image__text {
				@include font(14px, 19px);
				@extend %lightWeight;

				color: $c-grey-02;

				@include breakpoint(tablet) {
					@include font(16px, 22px);

					text-align: center;
				}

				p {
					@include margin(bottom, 19px);

					@include breakpoint(tablet) {
						@include margin(bottom, 22px);
					}

					.block-text-and-image--skeleton & {
						@extend %skeleton;

						&:nth-child(1) {
							height: 3 * 19px;

							@include breakpoint(tablet) {
								height: 3 * 22px;
							}
						}

						&:nth-child(2) {
							height: 6 * 19px;

							@include breakpoint(tablet) {
								height: 6 * 22px;
							}
						}

						&:nth-child(3) {
							height: 4 * 19px;

							@include breakpoint(tablet) {
								height: 4 * 22px;
							}
						}
					}
				}
			}

			.block-text-and-image__footer {
				display: flex;
				flex-direction: column;
				align-items: center;
				@include margin(top, 48px);

				.button {

					@include breakpoint(phablet) {
						max-width: 350px;
					}
				}
			}