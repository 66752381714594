.quote-header {
	position: sticky;
	padding-top: 30px;
	padding-bottom: 40px;
	transition: height 600ms;
	z-index: 1;

	// theme
	color: var(--quote-header-color);
	background: var(--quote-header-bg);

	@include breakpoint(phablet) {
		position: relative;
		padding-top: 20px;
		top: auto!important; //Overwrite the sticky position on phablet
	}

	@include breakpoint(phablet) {
		padding: 30px 30px 40px;
		flex-direction: row;
	}

	@include breakpoint(notebook) {
		padding: 30px 100px 50px;
		flex-direction: row;
	}
}

.quote-header--is-simple {
	color: $c-grey-dark;
	position: relative;
	z-index: 1;
	padding-top: 5px;
	padding-bottom: 35px;

	@include breakpoint(tablet) {
		padding-bottom: 70px;
	}

	.quote-header__minified {
		position: relative;
		opacity: 1!important;
		pointer-events: auto;
		padding: 0;
		bottom: auto;
		margin-top: 10px;

		@include breakpoint(tablet) {
			display: none;
		}
	}

	.quote-header__maximised {
		display: none;

		@include breakpoint(tablet) {
			display: flex;
		}
	}
}

	.quote-header__background-image {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: none;
		overflow: hidden;

		// theme
		background: var(--quote-header-bg);

		@include breakpoint(phablet) {
			display: none!important; //Overwrite the header image on phablet
		}

		// we need to hide the video background when showing PBS instance
		.is-powered-by & {

			.background {
				display: none !important;
			}
		}
	}

	.quote-header__maximised {
		transition: height .3s ease;

		@include breakpoint(phablet) {
			opacity: 1!important;
		}
		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}
	}

	.quote-header__maximised-expanded {
		position: absolute;
		top: 30px;
		right: 0;
		left: 0;
		opacity: 0;
		z-index: 1;

		@include breakpoint(phablet) {
			right: 30px;
			left: 30px;
		}

		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		@include breakpoint(notebook) {
			right: 100px;
			left: 100px;
		}

		.quote-header--is-simple & {
			top: 5px;

			@include breakpoint(tablet) {
				top: 5px;
			}
		}

		&:not(.quote-header__maximised-expanded--active) {
			pointer-events: none;
		}

		.wrap {
			position: relative;
			z-index: 1;
		}
	}

	.quote-header__tail-number-row {
		position: relative;
		display: flex;
		min-width: 100%;
		text-transform: uppercase;
		justify-content: space-between;
		align-items: center;

		@include breakpoint(phablet) {
			justify-content: flex-start;
		}
	}

	.quote-header__tail-number-row--is-simple {
		@include breakpoint(tablet) {
			display: none;
		}
	}

		.quote-header__tail-number {
			@extend %boldWeight;
			display: flex;
			align-items: center;

			img {
				margin-right: 7px;
			}
		}

		.quote-header__trip-type {
			@include font(14px, 17px);
			@extend %lightWeight;

			margin-right: auto;
			margin-left: 10px;
			text-transform: none;
			color: $c-pending;

			.quote-header--is-simple & {
				color: inherit;
			}
		}

		.quote-header__edit-button {
			@extend %lightWeight;
			padding: 0;
			display: flex;
			align-items: center;
			background: transparent;
			margin-left: 30px;
			padding-bottom: 2px;

			img {
				margin-right: 3px;
			}
		}

	.quote-header__journey-row {
		margin-top: 20px;
		margin-left: 11px;

		@include breakpoint(tablet) {
			margin-top: 0;
			margin-right: 5px;
			margin-bottom: 20px;
			width: 100%;
			max-width: 900px;
		}
	}

	.quote-header__itinerary-button {
		margin: 20px auto 0;
		text-transform: uppercase;
	}

	.quote-header__itinerary-button--collapse {
		margin-bottom: 20px;

		img {
			transform: rotate(180deg);
		}
	}

	.quote-header__itinerary-button--active {
		opacity: 0;
	}

	.quote-header__small-print {
		@extend %lightWeight;
		@include font(12px, 15px);
		position: relative;
		width: 100%;
		margin-top: 20px;

		@include breakpoint(tablet) {
			margin-top: 15px;
			padding: 0 13px;
			@include font(14px, 18px);
		}
	}

	// Minified version
	.quote-header__minified {
		position: absolute;
		bottom: 22px;
		opacity: 0;
		left: 0;
		width: 100%;
		pointer-events: none;

		@include breakpoint(phablet) {
			opacity: 0 !important;
		}

		.quote-header__maximised-expanded--active + & {
			
			.quote-header--is-simple & {
				display: none;
			}
		} 
	}