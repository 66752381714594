/// Set a relative font sizes and a unitless line height, based on the required line height and a font size
///
/// @param {Number} $font-size The desired font-size
/// @param {Number} $line-height The desired line-height (optional)
/// @author Joe Adcock <joe.adcock@damdigital.com>

@mixin font($font-size, $line-height: false) {
    @include rem($font-size);

    @if $line-height {
        line-height: ($line-height / $font-size);
    }
}