.flight-brief {
	p {
		margin-bottom: 1em;
	}
}

	.flight-brief__buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		button {
			width: calc(50% - 10px);

			.flight-brief--skeleton & {
				@extend %skeleton;

				border: none;
				pointer-events: none;
			}

			img {

				.flight-brief--skeleton & {
					display: none;
				}
			}
		}

		@include breakpoint(phablet) {
			justify-content: flex-end;
			button {
				max-width: 160px;
				margin-left: 20px;
			}
		}
	}

	.flight-brief__top-details {
		@include font(16px, 22px);
		display: flex;
		flex-direction: column;
		margin-top: 35px;
		white-space: nowrap;

		@include breakpoint(notebook) {
			flex-direction: row;
		}
	}

		.trip-detail {
			width: 100%;
			display: flex;
			flex-direction: row;

			@include breakpoint(notebook) {
				margin-right: 20px;
				width: auto;
				align-items: center;
			}
		}

			.trip-detail__title {
				width: 120px;
				margin-right: 6px;
				color: $c-grey-02;

				@include breakpoint(phablet) {
					width: 150px;
				}

				@include breakpoint(notebook) {
					width: auto;
				}

				.flight-brief--skeleton & {
					@extend %skeleton;
				}
			}

			.trip-detail__info {
				@extend %boldWeight;

				.flight-brief--skeleton & {
					@extend %skeleton;
				}
			}

				.trip-detail__info__light {
					@extend %mediumWeight;
					color: $c-grey-02;

					.flight-brief--skeleton & {
						color: transparent;
					}
				}

	.flight-brief__expand-all {
		color: $c-grey-02;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: auto;
		padding: 0;
		margin-top: 20px;

		@include breakpoint(notebook) {
			margin: 0 0 0 auto;
			padding: 0;
		}

		.flight-brief--skeleton & {
			@extend %skeleton;
		}

		img {
			margin-left: 5px;

			.flight-brief--skeleton & {
				display: none;
			}
		}
	}

	.flight-brief__content-grid {
		@include font(16px, 22px);
		@include margin(bottom, 40px);

		display: grid;
		gap: 32px;

		@include breakpoint(tablet) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	// .flight-brief__content-grid--2 {

	// 	@include breakpoint(tablet) {
	// 		grid-template-columns: auto;
	// 	}

	// 	@include breakpoint(notebook) {
	// 		grid-template-columns: 1fr 1fr 1fr;
	// 	}
	// }

	.flight-brief__content-grid--people {
		@include breakpoint(tablet) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			margin-bottom: 20px;
		}
	}

	.flight-brief__aircraft-wrapper {
		margin-top: 18px;

		@include breakpoint(tablet) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}

		@include breakpoint(desktop) {
			width: 85%;
		}
	}

		.flight-brief__photos-wrapper {
			margin-left: -18px;
			margin-right: -18px;
			padding-bottom: 70%;
			position: relative;

			@include breakpoint(tablet) {
				width: calc(50% - 10px);
				padding-bottom: 35%;
				margin: 0;
			}
		}

		.flight-brief__blueprint {
			margin-top: 18px;
			margin-left: -12px;
			margin-right: -12px;
			width: calc(100% + 24px);

			@include breakpoint(tablet) {
				width: calc(50% - 10px);
				margin: 0;
			}
		}

		.flight-brief__itinerary-wrapper {
			margin-bottom: 20px;

			@include breakpoint(notebook) {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
			}
		}

			.flight-brief__itinerary-content {

				@include breakpoint(notebook) {
					width: 60%;
					padding-left: 50px;
				}
			}

			.flight-brief__plane-type {
				@extend %boldWeight;
				color: $c-gold-02;
				margin-bottom: 10px;
			}

			.flight-brief__map {
				width: 100%;
				max-width: 400px;
				margin-bottom: 20px;

				@include breakpoint(notebook) {
					width: 40%;
				}

				img {
					width: 100%;
				}
			}

			.flight-brief__item {
				margin-bottom: 5px;
			}

			.flight-brief__group-title {
				@extend %boldWeight;
				text-transform: capitalize;
				color: $c-gold-02;
			}

			.flight-brief__gold-title {
				@extend %boldWeight;
				text-transform: capitalize;
				color: $c-gold-02;
			}

			.flight-brief__dark-title {
				@extend %boldWeight;
			}

			.flight-brief__body-text--light {
				color: $c-grey-02;
				display: inline;
			}

			.flight-brief__link {
				text-decoration: none;
				border-bottom: 1px solid $c-grey-dark;
			}

			.flight-brief__body-text--dark {
				@extend %boldWeight;
				display: inline;
			}

			.flight-brief__body-text--w3w {
				color: $c-w3w-red;
			}

			.flight-brief__body-text--link {
				color: $c-link-blue;
				text-decoration: none;
				border-bottom: 1px solid $c-link-blue;
			}