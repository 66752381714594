.blog-article {
	// padding: 5px 0 20px;
	overflow: hidden;
}

	.blog-article__header {
		padding: 20px;

		@include breakpoint(tablet) {
			position: relative;
			padding: 45px (50px + 100px + 20px);
		}
	}

		.blog-article__date {
			@include font(16px, 19px);
			@include margin(bottom, 8px);
			@extend %italicStyle;

			color: $c-gold;
			text-align: center;

			@include breakpoint(tablet) {
				@include font(18px, 22px);
			}

			.blog-article--skeleton & {
				@extend %skeleton;
			}
		}

		.blog-article__title {
			@include font(24px, 29px);
			@include margin(bottom, 50px);
			@extend %italicStyle;

			color: $c-grey-01;
			text-align: center;

			@include breakpoint(tablet) {
				@include font(32px, 38px);

				margin-bottom: 0;
			}

			.blog-article--skeleton & {
				@extend %skeleton;
			}
		}

		.blog-article__back {
			@include font(16px, 19px);

			display: inline-flex;
			align-items: center;
			color: $c-grey-06;

			@include breakpoint(tablet) {
				position: absolute;
				top: 50%;
				left: 50px;
				transform: translateY(-50%);
			}

			.blog-article--skeleton & {
				@extend %skeleton;

				pointer-events: none;
			}

			img {
				margin-right: 10px;
			}
		}
	
	.blog-article__image {

		.blog-article--skeleton & {
			@include aspect(16 / 9);
			@extend %skeleton;
		}

		img {
			display: block;
			width: 100%;
		}
	}

	.blog-article__content {
		padding-top: 30px;
		padding-bottom: 100px;
		background: $c-white;

		@include breakpoint(tablet) {
			padding-top: 50px;
			padding-bottom: 110px;
		}
	}

		.blog-article__cta {
			@include font(16px, 19px);

			text-align: center;

			.blog-content-block + & {
				margin-top: 72px;
			}

			.button {
				max-width: 230px;
				margin: 24px auto 0;
			}
		}

	.blog-article__footer {
		background: $c-white;
	}

		.blog-article__navigation {
			display: flex;
			align-items: flex-start;
			padding: 24px 12px 32px;
			background: rgba($c-gold, 0.1);

			@include breakpoint(tablet) {
				padding: 30px 24px;
			}
		}

			.blog-article__link {
				@include font(18px, 22px);

				position: relative;
				width: 50%;
				color: $c-gold;
				letter-spacing: 0.02em;

				@include breakpoint(tablet) {
					@include font(22px, 26px);
				}

				img {
					position: absolute;
					top: 24px;

					@include breakpoint(tablet) {
						top: 8px;
						width: 14px;
						height: 22px;
					}
				}

				small {
					@include font(14px, 20px);
					@extend %lightWeight;

					display: block;
					color: $c-grey-dark;

					@include breakpoint(tablet) {
						@include font(16px, 22px);
					}
				}
			}

			.blog-article__link--previous {
				padding-right: 4px;
				padding-left: 17px;

				@include breakpoint(tablet) {
					padding-left: 30px;
				}
				
				img {
					left: 0;
				}
			}

			.blog-article__link--next {
				padding-right: 17px;
				padding-left: 4px;
				text-align: right;

				@include breakpoint(tablet) {
					padding-right: 30px;
				}

				&:only-child {
					margin-left: auto;
				}
				
				img {
					right: 0;
				}
			}