.contract-status {
	position: sticky;
	top: 0;
	z-index: 3;
}

	.contract-status__outer {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		color: $c-grey-dark;
		background: $c-gold;
		z-index: 2;
	}

		.contract-status__slidedown {
			transition-duration: 350ms;
		}

			.contract-status__inner {
				padding: 10px 0 11px;
				display: flex;
				align-items: center;
				justify-content: center;

				@include breakpoint(phablet) {
					padding: 13px 0;
				}
			}

				.contract-status__text {
					@include font(16px, 19px);
					@extend %boldWeight;

					display: flex;
					align-items: center;

					img {
						width: 24px;
						height: 24px;
						margin-right: 15px;
					}
				}