.credit-bar {
	@include font(16px, 19px);

	display: flex;
	justify-content: center;
	padding: $site-gutter;
	color: $c-grey-dark;
	background: $c-grey-05;

	@include breakpoint(tablet) {
		padding: $site-gutter--tablet;
	}

	p {
		text-align: center;
	}

	span {
		@include margin(bottom, 7px);
		@include font(24px, 29px);
		@extend %mediumWeight;

		display: flex;
		letter-spacing: 0.06em;

		@include breakpoint(tablet) {
			@include font(34px, 40px);
		}
	}
}