.sign-contract {
	@include breakpoint(tablet) {
		margin-bottom: 80px;
		margin-top: 50px;
	}

	h3 {
		@extend %boldWeight;
		color: $c-gold;
		margin-bottom: 1em;
	}

	p {
		@include font(16px, 18px);
		margin-bottom: 40px;

		&:last-of-type {
			margin-bottom: 50px;
			@include breakpoint(tablet) {
				margin-bottom: 60px;
			}
		}
	}
}

.sign-contract--max500 {
	max-width: 500px;
	@include breakpoint(tablet) {
		max-width: 700px;
	}
}

