.block-read-more {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

	.block-read-more__container {
		max-height: #{19px * 10};
		overflow: hidden;

		@include breakpoint(phablet) {
			max-height: #{19px * 7};
		}

		@include breakpoint(tablet) {
			max-height: none;
		}

		.block-read-more--3 & {
			max-height: #{19px * 3};

			@include breakpoint(phablet) {
				max-height: #{19px * 7};
			}

			@include breakpoint(tablet) {
				max-height: none;
			}
		}

		.block-read-more--active & {
			max-height: none;
		}
	}

		.block-read-more__content {}

	.block-read-more__button {
		@include font(16px, 19px);

		align-self: flex-end;
		margin-top: 16px;
		color: var(--block-read-more-button-color);
		text-decoration: underline;

		@include breakpoint(tablet) {
			display: none;
		}

		.block-read-more--active & {
			display: none;
		}
	}