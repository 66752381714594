.react-slidedown.return-message-slidedown {
	transition-duration: 350ms;
}

.return-message {
	position: sticky;
	top: 45px;
	background-color: $c-green;
	color: black;

	@include breakpoint(phablet) {
		top: 50px;
	}
}

.return-message--error {
	background-color: $c-pink;
}

.return-message--inline {
	position: relative;
	top: 0;
}

	.return-message__inner {
		padding-top: 16px;
		padding-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

		.return-message__inner__text{
			@include font(16px, 21px);
			font-weight: 700;
			padding-right: 10px;
			margin: auto;
			display: flex;
			align-items: center;
		}

			.return-message__inner__tick {
				margin-right: 10px;
				height: 26px;
				margin-bottom: 2px;
				margin-left: -3px;
			}

			.return-message__inner__alert {
				margin-right: 10px;
				height: 25px;
				margin-bottom: 2px;
				margin-left: -3px;
			}

		.return-message__inner__close-cross-container {
			align-self: flex-start;
			justify-self: flex-end;
			margin-top: -2px;
		}