.clipboard-input {
	position: relative;
	cursor: pointer;

	img {
		position: absolute;
		bottom: (45px / 2);
		right: 8px;
		transform: translateY(50%);
		pointer-events: none;
	}
}

.clipboard-input--large {

	img {
		bottom: (54px / 2);
	}
}

	.clipboard-input__label {
		@include margin(bottom, 10px);
		@include font(14px, 17px);
		@extend %lightWeight;

		display: block;
		color: $c-grey-dark;
	}

	.clipboard-input__input {
		@include font(14px, 20px);
		@extend %lightWeight;

		padding-left: 8px;
		padding-right: 8px;
		color: $c-primary-blue;
		height: 45px;
		width: 100%;
		border: solid 1px $c-gold;
		border-radius: 6px;
		box-shadow: 0px 2px 4px rgba($c-grey-03, 0.16);
		pointer-events: none;

		.clipboard-input--large & {
			@include font(26px, 31px);
			@extend %mediumWeight;

			height: 54px;
			padding-left: 26px;
			padding-right: 26px;
			letter-spacing: 0.02em;
		}
	}