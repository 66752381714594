.contact-widget {
	position: absolute;
	bottom: 50%;
	right: 50%;
	background-color: $c-grey-01;
	z-index: 9995;
	opacity: 0;
	transition: all 350ms;
	transform: translate(0, 8%) scale(0.92);
	width: 320px;
	max-width: 90vw;
	height: auto;
	border-radius: 12px;

	.listing__ctas__contact-widget-wrapper--loaded & {
		position: static;
		margin: auto;
	}
}

.contact-widget--open {
	transform: translate(0) scale(1);
	opacity: 1;
}

	.contact-widget__header {
		background-color: $c-gold;
		height: 105px;
		position: relative;
		overflow: hidden;
		transition: height 300ms;
		border-radius: 12px 12px 4px 4px;

		.contact-widget--blue & {
			background: $c-primary-blue;
		}
	}

	.contact-widget__header--short {
		height: 60px;
	}

		.contact-widget__header__inner {
			padding: 20px;
			color: $c-grey-01;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			opacity: 0;
			visibility: hidden;
			transition: opacity 300ms, visibility 0ms 300ms;
		}

		.contact-widget__header__inner--visible {
			transition: opacity 300ms, visibility 0ms;
			opacity: 1;
			visibility: visible;
		}

		.contact-widget__header__title {
			@extend %boldWeight;
			text-transform: capitalize;
			margin-bottom: 0.5em;
		}

		.contact-widget__header__text {
			@extend %lightWeight;
			@include font(16px, 18px);
		}

		.contact-widget__header__back {
			display: flex;
			margin-right: auto;
			align-items: center;

			img {
				margin-right: 10px;
			}
		}

		.contact-widget__close-wrapper {
			position: absolute;
			top: 14px;
			right: 14px;
		}

		.contact-widget__body {
			padding: 15px 10px;

			.swiper-slide {
				min-height: auto;
				visibility: hidden;

				form {
					padding: 2px;
				}
			}

			.swiper-slide.swiper-slide-active {
				visibility: visible;
			}
		}

	.contact-widget__button {
		@include font(16px);
		color: $c-grey-dark;
		height: 48px;
		border: 0.5px solid $c-grey-03;
		border-radius: 4px;
		width: 99%;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: left;
		margin-bottom: 10px;
		text-decoration: none;

		&:focus-visible,
		&:hover {
			background-color: rgba($c-gold, .1);
			outline: none;
		}

		&:focus-visible {
			outline-offset: -2px;
			outline: 2px $c-gold solid;
		}

		img {
			height: 28px;
			width: 28px;
			margin-left: 12px;
			margin-right: 12px;
		}
	}

		.contact-widget__button__number {
			@extend %lightWeight;
			@include font(14px);
			color: $c-grey-02;
			margin-right: 5px;
		}

			.contact-widget__button__247 {
				@include font(14px);
				color: $c-grey-dark;
			}

.contact-widget__tail {
	position: absolute;
	bottom: -10px;
	right: 10px;

	.listing__ctas__contact-widget-wrapper--loaded & {
		display: none;
	}
}

// Error message
.contact-widget {
	.return-message {
		margin-bottom: 20px;
	}
}

// Overwrite popup style
#livechat-eye-catcher{
	bottom: -100px!important;
	right: -100px!important;
}

#chat-widget-container {
	z-index: 9995!important;
	max-height: calc(100vh - 150px)!important;
}

#chat-widget-minimized {
	display: none!important;
}

#chat-widget-container {
	bottom: -100px!important;
	overflow: visible!important;
}

#chat-widget {
	transform: translate(0, -100px) !important
}