.title-bar {
	position: sticky;
	top: $header-height;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 45px;
	background: $c-grey-04;
	color: $c-primary-blue;
	padding: 0 $site-gutter;
	box-shadow: 0 2px 4px rgba($c-grey-04, 0.6);
	z-index: 2;

    @include breakpoint(phablet) {
		height: 50px;
	}

	&__link {
		display: flex;
		flex-direction: row;
		align-items: center;

		&__text {
			display: none;
			padding-top: 4px;
			padding-bottom: 3px;
			border-bottom: 1px solid transparent;
			text-transform: uppercase;
			@include breakpoint(tablet) {
				display: block;
			}
		}

		&:hover {
			.title-bar__link__text {
				border-bottom: 1px solid $c-primary-blue;
			}
		}

		img {
			margin-right: 14px;
			width: 20px;

			@include breakpoint(tablet) {
				width: 22px;
			}
		}
	}

	&__title-tabs-wrapper {
		position: absolute;
		left: 50%;
		height: 100%;
		transform: translate(-50%);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	&__title {
		@include font(16px, 20px);
		margin: 0;
		text-transform: uppercase;
		letter-spacing: 0.02em;
		white-space: nowrap;
		@include breakpoint(phablet) {
			@include font(18px, 24px);
		}
		@include breakpoint(notebook) {
			@include font(20px, 24px);
		}
	}
}