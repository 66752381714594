.label {
	@include font(16px);
	@extend %lightWeight;
	color: $c-grey-02;
	margin-bottom: 5px;
	position: absolute;
	top: 14px;
	left: 0;
	right: 30px;
	height: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 10px;
	padding-right: 10px;
	pointer-events: none;
	transition: font-size 300ms, top 300ms;
}

	.label--minimised {
		@include font(12px);
		top: 6px;
	}

	.label--skip-animation {
		transition: none;
	}