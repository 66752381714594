.form-button-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 20px;

	* {
		width: 100%;
		text-decoration: none;
	}

	@include breakpoint(phablet) {
		a,
		button {
			margin: auto;
			max-width: 350px;
		}
	}

	@include breakpoint(tablet) {
		grid-column: span 2;
	}

	button:nth-of-type(2) {
		margin-top: 10px;
	}

	.button--secondary {
		margin-top: 16px;
	}
}
