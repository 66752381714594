.manage-passengers__multi-select {
	background-color: $c-grey-01;
	padding: 18px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px -6px 4px rgba(27, 38, 70, 0.1);

	button:nth-of-type(2) {
		display: none;
		@include breakpoint(notebook) {
			display: block;
		}
	}

	@include breakpoint(notebook) {
		position: absolute;
		width: 70%;
		top: 60px;
		left: auto;
		right: 25px;
		padding-top: 25vh;
		box-shadow: none;
	}

	&__title {
		@include font(16px);
		margin-bottom: 18px;

		@include breakpoint(notebook) {
			@include font(18px);
		}

		@include breakpoint(laptop) {
			@include font(20px);
		}
	}

	&__close-cross {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}

.manage-passengers__title {
	@include font(22px, 28px);
	max-width: 330px;
	text-align: center;
}

.manage-passengers__sub-title {
	margin-top: 20px;
	margin-bottom: 22vh;
}