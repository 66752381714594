.modal-form-wrapper {
	&__header {
		@extend %boldWeight;
		color: $c-primary-blue;
		width: 100%;
		height: 55px;
		box-shadow: 0 2px 4px rgba(125, 125, 125, 0.16);
		display: flex;
		flex-direction: row;
		align-items: center;
		text-transform: uppercase;
		justify-content: center;

		&--big-title {
			@include font(20px);
			@extend %regularWeight;
			text-transform: none;
			height: 60px;

			@include breakpoint(phablet) {
				@include font(22px);
				height: 65px;
			}

			@include breakpoint(tablet) {
				@include font(24px);
				height: 70px;
			}

			@include breakpoint(notebook) {
				@include font(26px);
				height: 80px;
			}
		}
	}

	&__body {
		min-height: 150px;
	}
}
