.profile-button {
    @include font(14px, 14px);
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;

    // theme
    color: var(--header-color);
}

    .profile-button--icon {
        height: 18px;
        margin-left: 10px;

        @include breakpoint(phablet) {
            height: 20px;
        }
    }