/// Define the layout for a button part
///
/// @author Joe Adcock <joe.adcock@damdigital.com>

@mixin button-part {
    position: absolute;
    content: '';
    height: 0;
    width: 0;
    display: block;
    opacity: 0;
}