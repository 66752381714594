.thumbs {
	display: flex;
	flex-direction: row;

	.thumb {
		height: 55px;
		width: 55px;
		margin-right: 5px;
		position: relative;

		span {
			background-color: rgba($c-white, 0.3);
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			height: 45px;
			width: 45px;
			border-radius: 100%;
			transform: translate(-50%, -50%) scale(0);
			transition: transform 250ms;
		}

		img {
			position: relative;
			height: 45px;
		}

		&--selected {
			img {
				animation: thumbAnim forwards 500ms;
			}

			span {
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
}

@keyframes thumbAnim {
	50% {
		transform: scale(1.7) rotate(-15deg);
	}
}