@import "../../node_modules/rc-slider/assets/index.css";

.rc-slider {
	z-index: 1;
}

.rc-slider-rail {
	// background: $c-pending;
	border-radius: 0;

	// theme
	background: var(--rc-slider-rail-bg);
}

.rc-slider-track {
	background: transparent;
}

.rc-slider-handle {
	width: 111px;
	height: 8px;
	margin-top: -2px;
	border-radius: 0;
	border: none;
	opacity: 1;

	// theme
	background: var(--rc-slider-handle-bg);
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
	border: none;
	box-shadow: none;
}