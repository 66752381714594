.filter {
	width: 100%;
}

.filter--active {
	position: relative;
	z-index: 1;
}

	.filter__container {
		height: 100%;
		border: 1px solid rgba($c-grey-03, 0.3);
		border-radius: 4px;
	}

		.filter__toggle {
			@include font(14px, 19px);

			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 16px;
			color: $c-primary-blue;
			text-align: left;

			@include breakpoint(tablet) {
				@include font(16px, 19px);
			}

			.filter--active & {
				border-radius: 4px 4px 0 0;
			}

			img {
				margin-left: 10px;

				.filter--active & {
					transform: rotate(180deg);
				}
			}
		}

		.filter__content {
			position: relative;
		}

			.filter__dropdown {
				position: absolute;
				top: -2px;
				left: -1px;
				width: calc(100% + 2px);
				display: none;

				.filter--active & {
					display: block;
				}
			}

				.filter__options {
					padding: 2px 16px 16px;
					background: $c-white;
					border: 1px solid rgba($c-grey-03, 0.3);
					border-top: none;
					border-radius: 0 0 4px 4px;

					.filter--checkbox & {
						max-height: 150px;
						overflow: auto;

						@include breakpoint(tablet) {
							max-height: 300px;
						}
					}

					&:before {
						content: '';
						display: block;
						height: 1px;
						margin-bottom: 16px;
						background: rgba($c-gold, 0.5);
					}
				}

					.filter__option {
						@include margin(bottom, 24px);
						@extend %lightWeight;

						text-align: left;

						.filter--checkbox & {
							display: flex;
							align-items: flex-start;
						}
					}

						.filter__status {
							flex: 0 0 auto;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 18px;
							height: 18px;
							margin-right: 8px;
							border: 1px solid $c-gold;
						}