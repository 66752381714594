.date-picker-input {
	background-repeat: no-repeat;
	background-position: 10px center;
	background-color: $c-pending;
	border-radius: 4px;
	position: relative;
	width: 100%;
	background-size: 24px;
}

.date-picker-input--error {
	outline: solid 2px $c-error-red;
	outline-offset: -2px;

	.date-picker-input__input {
		color: $c-error-red;
	}

	.date-picker-input__input:focus {
		box-shadow: none;
	}
}

.date-picker-input__chevron {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translate(0, -50%);
	cursor: pointer;
}

.date-picker-input__input {
	@include font(16px);
	width: 100%;
	box-sizing: border-box;
	color: $c-primary-blue;
	background-color: transparent;
	height: 42px;
	border: none;
	padding: 20px 45px;
	cursor: pointer;

	@include breakpoint(tablet) {
		font-weight: 700;
	}
}

.date-picker-input__input:focus {
	border: none;
	outline: none;
	border-radius: 4px;
	box-shadow: 0 0 0 1px $c-grey-02, 0 0 0 2px $c-white;
}

// Datepicker in form
.date-picker-input--form,
.date-picker-input--form.date-picker-input--error{
	background-color: transparent;
	background-position: right 10px center;
	border-radius: 0;
	border: solid 1px $c-grey-03;
	height: 45px;
	outline: none;

	.date-picker-input__input {
		@extend %lightWeight;
		@include font(16px);
		text-align: left;
		padding: 14px 40px 0 8px;
	}
}

.date-picker-input--form.date-picker-input--error {
	border: solid 1px $c-red;
}

.date-picker-input--form--selected {
	border: solid $c-primary-blue 1px;
}

// Overwrite react-datepicker style
.react-datepicker {
	@include apply-map($f-primary);
}

.react-datepicker__header {
	@include apply-map($f-primary);
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--selected:hover {
	background-color: $c-primary-blue;
	border-radius: 100%;
}

.react-datepicker__time-container {
	width: 130px;
}

.react-datepicker__year-dropdown--scrollable {
	width: 200px;
	height: 200px;
	left: 50%;
	transform: translate(-50%);
}

.react-datepicker__header__dropdown {
	@include font(18px);
	margin-top: 7px;
}

.react-datepicker__year-read-view--down-arrow {
	margin-top: 3px;
}

.react-datepicker__year-option {
	@extend %lightWeight;
	min-height: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
	width: 100%;
}

.react-datepicker__time-list-item {
	@include font(16px)
}

// Overwrite react-timepicker style
.react-datepicker--time-only {
	.react-datepicker__triangle {
		left: -48%!important;
	}
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
	background-color: $c-primary-blue;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
	height: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}