.edit-cta {
	display: flex;
	flex-direction: row;
	align-items: center;

	&__text {
		color: $c-grey-03;
		border-bottom: 1px solid $c-grey-03;
	}

	img {
		margin-right: 4px;
	}
}