.image {
	position: relative;

	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}

/// Image fills parent container
.image--fill-parent {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	img {
		object-fit: cover;
		object-position: center;
		height: 100%;
		width: 100%;
	}
}

/// Loading
.image--loading {
	img {
		opacity: 0;
	}

	.image__loaded {
		animation: fadeImageIn forwards 2s;
	}

	.image__loading {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(-45deg, rgba($c-grey-02, 0.8), rgba($c-white, .2), rgba($c-grey-02, .3));
		background-size: 400% 400%;
		animation: gradient-bg 7s ease infinite;
	}
}

@keyframes fadeImageIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes gradient-bg {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/// Decorative modifiers
///
/// e.g. when you want an image to adhere to a particular decoration

.image--placeholder {
	background: $c-image-placeholder;
}

.image--border {
	border: 5px solid $c-border;
}

/// Width modifiers
///
/// e.g. when you want an image to adhere to a particular width restriction

.image--full {
	width: 100%;

	img {
		width: 100%;
	}
}

.image--mw-300 {
	max-width: 300px;
}

.image--mw-500 {
	max-width: 500px;
}

/// Layout modifiers
///
/// e.g. when you want an image to adhere to a particular layout

.image--cover {
	position: relative;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.image--inline-block {
	display: inline-block;
}

/// Aspect ratio modifiers
///
/// e.g. when you want an image to adhere to a particular width and height relationship

.image--1x1 {
	@include aspect(1);
}

.image--3x2 {
	@include aspect(3 / 2);
}