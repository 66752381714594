.contract {
	@include font(16px, 19px);

	padding-top: 48px;
	color: $c-grey-dark;

	@include breakpoint(tablet) {
		padding-top: 64px;
		padding-bottom: 90px;
	}

	.wrap {
		max-width: 1000px + 60px;
	}
}

	.contract__section {
		@include margin(bottom, 40px);

		padding-bottom: 40px;
		border-bottom: 1px solid $c-gold;

		&:last-child {
			padding-bottom: 0;
			border-bottom: none;
		}

		@include breakpoint(tablet) {
			@include margin(bottom, 64px);

			padding-right: 40px;
			padding-bottom: 72px;
			padding-left: 40px;
		}
	}

	.contract__section--flex-desktop {

		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
			padding-right: 20px;
			padding-left: 20px;
		}

		.contract__title {

			@include breakpoint(tablet) {
				width: 100%;
				padding-right: 20px;
				padding-left: 20px;
			}
		}
	}

		.contract__grid {

			@include breakpoint(tablet) {
				display: flex;
			}

			.contract__data-list {

				@include breakpoint(tablet) {
					margin-bottom: -24px;
				}
			}
		}

		.contract__title {
			@include font(20px, 24px);
			@include margin(bottom, 40px);

			text-align: center;
			letter-spacing: 0.02em;

			@include breakpoint(tablet) {
				@include font(26px, 31px);
				@include margin(bottom, 56px);

				text-align: left;
			}
		}

		.contract__data-list {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 40px - 16px;

			&:last-child {
				margin-bottom: -16px;

				@include breakpoint(tablet) {
					margin-bottom: -24px;
				}
			}

			dt,
			dd {
				width: 50%;
				margin-bottom: 16px;

				@include breakpoint(tablet) {
					margin-bottom: 24px;
				}
			}

			dt {
				padding-right: 16px;
				text-align: right;
				text-transform: uppercase;

				@include breakpoint(tablet) {
					padding-right: 32px;
				}
			}

			dd {
				@extend %boldWeight;
			}
		}

		.contract__data-list--stack-mobile {
			display: block;

			@include breakpoint(tablet) {
				display: flex;
				flex-wrap: wrap;
			}

			&:last-child {
				margin-bottom: 0;
			}

			dt,
			dd {
				width: auto;
			}

			dt {
				@include margin(bottom, 10px);

				padding-right: 0;
				text-align: left;

				@include breakpoint(tablet) {
					width: 180px + 32px;
					padding-right: 32px;
					text-align: right;
				}
			}

			dd {
				@include margin(bottom, 40px);

				@include breakpoint(tablet) {
					width: calc(100% - (180px + 32px));
				}

				p {
					@include font-weight(regular);
					@include margin(bottom, 16px);
				}
			}

			dd span,
			.dd--light {
				@include font(14px, 19px);
				@include font-weight(light);
				margin-top: 5px;

				@include breakpoint(tablet) {
					@include font(16px, 19px);
					margin-top: 0;
				}
			}

			.dd--uppercase {
				text-transform: uppercase;
			}
		}

		.contract__data-list--journey {

			dt {
				width: 90px + 16px;

				@include breakpoint(tablet) {
					width: 90px + 32px;
				}
			}

			dd {
				width: calc(100% - (90px + 16px));

				@include breakpoint(tablet) {
					width: calc(100% - (90px + 32px));
				}
			}
		}

		.contract__text {

			p {
				@include margin(bottom, 1em);
			}
		}

		.contract__text--small {
			@include font(14px, 19px);
			@extend %lightWeight;

			@include breakpoint(tablet) {
				@include font(16px, 22px);
			}

			p {
				margin-bottom: 0;
			}
		}

		.contract__journeys {

			@include breakpoint(phablet) {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -20px;
			}

			@include breakpoint(notebook) {
				margin: 0 -25px;
			}

			@include breakpoint(laptop) {
				margin: 0 -40px;
			}
		}

			.contract__group-title {
				margin-bottom: 20px;

				@include breakpoint(phablet) {
					width: 100%;
					margin-bottom: 0;
					padding: 0 20px;
				}

				@include breakpoint(tablet) {
					@include font(22px, 26px);
				}

				@include breakpoint(notebook) {
					padding: 0 25px;
				}

				@include breakpoint(laptop) {
					padding: 0 40px;
				}
			}

			.contract__journey {
				margin-bottom: 40px;

				@include breakpoint(phablet) {
					width: 50%;
					padding: 20px;
				}

				@include breakpoint(notebook) {
					padding: 25px;
				}

				@include breakpoint(laptop) {
					padding: 40px;
				}
			}

				.contract__subtitle {
					@include margin(bottom, 32px);
					@extend %mediumWeight;

					display: flex;
					align-items: center;

					@include breakpoint(tablet) {
						@include font(18px, 22px);
					}

					img {
						margin-right: 10px;
					}
				}

		.contract__name {
			padding: 26px 16px;
			background: rgba($c-gold, 0.24);

			@include breakpoint(tablet) {
				margin: 0 -40px;
				padding: 40px;
			}
		}

		.contract__terms {
			@include font(14px, 19px);
			@extend %lightWeight;
			width: 100%;
			max-width: 700px;
			margin-top: -40px;

			@include breakpoint(tablet) {
				margin-left: 20px;
				margin-right: 20px;
			}

			h3 {
				@include margin(bottom, 24px);
				text-transform: uppercase;
			}

			p {
				@include margin(bottom, 1em);
				@extend %lightWeight;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			ul,
			ol {
				@extend %lightWeight;
				list-style-type: disc;
				padding: 5px 30px;
				margin: -15px 0 10px;
				@include breakpoint(phablet) {
					padding: 10px 40px;
				}

				ol {
					padding: 5px 10px 0 30px;
					margin: 0;
					@include breakpoint(phablet) {
						padding: 10px 40px;
					}
				}
			}

			&__term {

				h3 {
					@include font(18px, 22px);
					margin: 0;
					padding: 30px 0 20px;
					@extend %mediumWeight;
				}

				&__number {
					@extend %mediumWeight;
					position: relative;
				}
			}

			table {
				border-collapse: collapse;
				width: 100%;
				margin: 0 auto 20px;
			}

			th, td {
				border: 1px solid #ccc;
				padding: 10px;
				text-align: left;
			}

			b, strong {
				@extend %mediumWeight;
			}

			th {
				@extend %boldWeight;
				background-color: $c-grey-05;
				@include breakpoint(phablet) {
					white-space: nowrap;
				}
			}
		}