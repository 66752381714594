// Content tray
.react-slidedown.aircraft-card-more-less-tray {
    transition-duration: 500ms;
}

.aircraft-card-more-less-tray {
    position: relative;
    width: 100%;
    margin-top: -15px;

    @include breakpoint(phablet) {
        margin-top: -31px;
        margin-bottom: 15px;
    }

    @include breakpoint(tablet) {
        padding: 0 7.5px;
    }
}

// 2 per line (column 1)
.aircraft-card-more-less-tray--2_0 {
    .aircraft-card-more-less-tray__join {
        width: calc(50% - (15px / 2));
        .aircraft-card-more-less-tray__join__curve--left{
            display: none;
        }
    }
    .aircraft-card-more-less-tray__inner {
        @include breakpoint(tablet) {
            border-radius: 0 6px 6px 6px;
        }
    }
}

// 2 per line (column 2)
.aircraft-card-more-less-tray--2_1 {
    .aircraft-card-more-less-tray__join {
        margin-left: auto;
        width: calc(50% - (15px / 2));
        .aircraft-card-more-less-tray__join__curve--right{
            display: none;
        }
    }
    .aircraft-card-more-less-tray__inner {
        @include breakpoint(tablet) {
            border-radius: 6px 0 6px 6px;
        }
    }
}

// 3 per line (column 1)
.aircraft-card-more-less-tray--3_0 {
    .aircraft-card-more-less-tray__join {
        width: calc(33.333% - ((15px * 2) / 3));
        .aircraft-card-more-less-tray__join__curve--left{
            display: none;
        }
    }
    .aircraft-card-more-less-tray__inner {
        @include breakpoint(tablet) {
            border-radius: 0 6px 6px 6px;
        }
    }
}

// 3 per line (column 2)
.aircraft-card-more-less-tray--3_1 {
    .aircraft-card-more-less-tray__join {
        margin-left: calc(33.333% + ((15px * 2) / 6));
        width: calc(33.333% - ((15px * 2) / 3));
    }
}

// 3 per line (column 3)
.aircraft-card-more-less-tray--3_2 {
    .aircraft-card-more-less-tray__join {
        margin-left: auto;
        width: calc(33.333% - ((15px * 2) / 3));
        .aircraft-card-more-less-tray__join__curve--right{
            display: none;
        }
    }
    .aircraft-card-more-less-tray__inner {
        @include breakpoint(tablet) {
            border-radius: 6px 0 6px 6px;
        }
    }
}

    // The Join
    .aircraft-card-more-less-tray__join {
        @include breakpoint(phablet) {
            position: relative;
            height: 30px;
            margin-bottom: -1px;

            // theme
            background: var(--aircraft-card-bg);
        }
    }

        // Curves
        .aircraft-card-more-less-tray__join__curve {
            position: absolute;
            left: -15px;
            bottom: -9px;
            height: 30px;
            width: 15px;
            display: none;
            overflow: hidden;
            &:before {
                content:'';
                position:absolute;
                width: 30px;
                height: 30px;
                bottom: 10px;
                right: 0;
                border-radius: 10px;
                transition: all $aircraft-filter-speed;

                // theme
                box-shadow: 0 0 0 2000px var(--aircraft-card-bg);
            }

            @include breakpoint(tablet) {
                display: block;
            }

            img {
                @include inset(0, 0, 0, 0);
                position: absolute;
            }
        }

        .aircraft-card-more-less-tray__join__curve--right {
            left: auto;
            right: -15px;
            &:before {
                content: '';
                position: absolute;
                width: 30px;
                height: 30px;
                bottom: 10px;
                left: 0;
                border-radius: 10px;
                transition: all $aircraft-filter-speed;

                // theme
                box-shadow: 0 0 0 2000px var(--aircraft-card-bg);
            }
        }

        .aircraft-card-more-less-tray__join__curve--dim:before {

            // theme
            box-shadow: 0 0 0 2000px var(--aircraft-card-bg);
        }

    // Tray inner
    .aircraft-card-more-less-tray__inner{
        position: relative;
        padding-bottom: 30px;

        // theme
        background: var(--aircraft-card-bg);

        @include breakpoint(phablet) {
            border-radius: 0 0 6px 6px;
        }

        @include breakpoint(tablet) {
            border-radius: 6px;
        }
    }

    .aircraft-card-more-less-tray__close-container {
        @include breakpoint(tablet) {
            position: absolute;
            top: 36px;
            right: 16px;
            display: block;
        }
    }