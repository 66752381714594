// SHY Blues
$c-primary-blue: #1B2646;
$c-blue-04: #E6EBF9;
$c-home-blue: #2f3644;
$c-link-blue: #1A73E8;
$c-footer-blue: #2A3449;
$c-blue-dark: #191A2A;
$c-itinerary-blue: #242935;

// Greys
$c-grey-dark: #333333;
$c-grey-01: #FFFFFE;
$c-grey-02: #595959;
$c-grey-03: #949494;
$c-grey-04: #E5E5E5;
$c-grey-05: #F6F6F8;
$c-grey-06: #E6E6E6;
$c-grey-07: #CCCCCC;
$c-grey-08: #D2D2D2E5;
$c-input-grey: #D3D4D7;
$c-pending: #EEF0F6;
$c-site-background-colour: #334054;
$c-dropdown-focus: #f0f0f0;
$c-filter-selection: #f3f3f3;
$c-filters-clear: #7d7d7d;
$c-grey-for-table: #f8f9fb;

// Gold
$c-gold: #D4BC87;
$c-gold-02: #BDAB84;
$c-gold-03: #D6960A;
$c-gold-light: #EEE4CE;

// Environmental
$c-environmental-blue: #CDDAF3;
$c-environmental-green: #1D3015;
$c-environmental-green-02: #436F30;
$c-environmental-green-03: #9FDB85;

// Other
$c-black: #000000;
$c-white: #FFFFFF;
$c-red: #EB001C;
$c-w3w-red: #E10721;
$c-green: #B7EDCE;
$c-pink: #F5BDC3;
$c-success-green: #008036;
$c-error-red: #AF1C2D;
$c-image-placeholder: #EEEEEE;
$c-border: #DDDDDD;
$c-payment-due: #FDE5E8;
$c-payment-complete: $c-pending;
$c-background-overlay: #060835;
$c-header-bg: #232B37;