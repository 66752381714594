.big-message {
	@include font(16px, 19px);

	margin: 0 auto;
	text-align: center;
	color: $c-grey-dark;


	.button {
		display: inline-flex;
	}
}

	.big-message__container {
		@include margin(bottom, 12px);

		@include breakpoint(tablet) {
			@include margin(bottom, 16px);

			padding: 10px 25px 25px;
			border: 0.5px solid rgba($c-grey-06, 0.2);
			box-shadow: -2px 2px 4px rgba(212, 188, 135, 0.04), 2px -2px 4px rgba(212, 188, 135, 0.04), -2px -2px 4px rgba(212, 188, 135, 0.04), 2px 2px 4px rgba(212, 188, 135, 0.04), inset 2px 1px 2px rgba(212, 188, 135, 0.02), inset -1px -1px 2px rgba(212, 188, 135, 0.02);
		}
	}

		.big-message__image {

			@include breakpoint(tablet) {
				@include margin(bottom, 18px);
			}

			img {
				width: 70px;
				height: 70px;

				@include breakpoint(tablet) {
					width: auto;
					height: auto;
				}
			}
		}

		.big-message__title {
			@include font(18px, 22px);
			@include margin(bottom, 7px);

			letter-spacing: 0.02em;
			color: $c-primary-blue;

			@include breakpoint(tablet) {
				@include font(22px, 26px);
				@include margin(bottom, 20px);
			}
		}

		.big-message__intro {
			margin: 18px auto;
			padding-top: 12px;
			color: $c-gold;
			border-top: 1px solid currentColor;
			max-width: 450px;
		}

		.big-message__text {
			@include font(16px, 22px);
			padding: 0 15px;
			text-align: center;
			max-width: 560px;
			margin: 20px auto 40px;

			@include breakpoint(tablet) {
				margin: 20px auto 10px;
				padding: 0;
			}

			p {
				@include margin(bottom, 1em);
			}

			a {
				text-decoration: underline;
			}
		}

		.big-message__button-wrapper {
			margin-top: 30px;
		}

		.big-message__id {
			@include font(14px, 19px);
			@include margin(bottom, 30px);

			text-align: left;

			@include breakpoint(tablet) {
				@include font(16px, 19px);
				@include margin(bottom, 24px);

				display: flex;
			}


			dt {
				@extend %lightWeight;

				@include breakpoint(tablet) {
					margin-right: 10px;
				}
			}

			dd {
				@extend %boldWeight;
			}
		}

	.big-message__footer {
		color: $c-gold;

		p {
			@include margin(bottom, 12px);
		}
	}