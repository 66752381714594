body {
    @include apply-map($f-primary);
    @include font(16px);
    margin: 0;

    // theme
    background: var(--body-bg) !important;
}

.body--model-open {
    overflow: hidden;
}