.aircraft-card {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 100%;
    transform: translateZ(0); // Safari bug fix
}

    .aircraft-card__content {
        padding-bottom: 20px;

        // theme
        background: var(--aircraft-card-bg);

        @include breakpoint(phablet) {
            border-radius: 10px;
            overflow: hidden;
        }

        .aircraft-card--preview & {
            border-radius: 0;
        }
    }

    .aircraft-card__content--enter {
        animation: fadeIn $aircraft-filter-speed;
    }

    .aircraft-card__content--exit {
        animation: fadeOut $aircraft-filter-speed;
    }

        .aircraft-card__photos-browser {
            position: relative;
            @include aspect(1.5/1)
        }

            .aircraft-card__gradient {
                @include inset('auto', 0, 0, 0);
                pointer-events: none;
                color: white;
                position: absolute;
                padding: 30px 16px 10px;
                background: linear-gradient(0deg, $c-black 0%, rgba($c-black, 0.24) 53.1%, rgba($c-black, 0) 96.21%);
            }

                .aircraft-card__category {
                    @include font(16px, 22px);
                    @extend %mediumWeight;
                    margin-bottom: 1px;
                }

                .aircraft-card__aircraft-name {
                    @include font(20px, 24px);
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                }


            .aircraft-card__price-row {
                padding: 12px 8px 12px 16px;
                display: flex;
                align-items: center;
                background: $c-blue-04;
                min-height: 70px;
            }

            .aircraft-card__price-row--shy-recommends {
                background: $c-gold;
            }

            .aircraft-card__buttons-wrapper {
                display: flex;
                margin-right: 8px;
                margin-left: 20px;

                .button {
                    padding: 0 20px;
                }
            }

                .aircraft-card__prices {
                    @extend %mediumWeight;

                    position: relative;
                    margin-right: auto;
                    overflow: hidden;
                    flex: 1;
                }

                    .aircraft-card__price {
                        white-space: nowrap;
                        color: $c-primary-blue;
                        position: relative;
                        width: 100%;
                        min-height: 24px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .aircraft-card__price--customer {
                        @extend %lightWeight;
                        width: calc(100% - 18px);
                        max-width: 90px;

                        color: $c-grey-dark;
                        @include breakpoint(tablet) {
                            @include font(16px, 22px);
                        }
                    }

                    .aircraft-card__info {
                        @extend %hideText;
                        height: 16px;
                        width: 16px;
                        position: absolute;
                        bottom: calc(50% - 9px);
                        right: -18px;

                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }

            .aircraft-card__notification {
                @include font(16px, 20px);
                background-color: $c-blue-04;
                color: $c-grey-dark;
                position: absolute;
                bottom: 10px;
                left: 10px;
                right: 10px;
                padding: 20px 40px 20px 20px;
                border-radius: 4px;
                box-shadow: 0 4px 6px 2px rgba(35, 35, 35, 0.5);
                opacity: 0;
                transform: translate(0, 100%);
                transition: opacity 450ms, transform 450ms;

                &__close-wrapper {
                    position: absolute;
                    top: 5px;
                    right: 8px;
                }

                &--entering {
                    opacity: 1;
                    transform: translate(0);
                }

                &--enter-done {
                    opacity: 1;
                    transform: translate(0);
                }

                &--exiting {
                    transition: opacity 450ms, transform 450ms;
                    opacity: 0;
                    transform: translate(0, -60%);
                }
            }

            .aircraft-card__icons-row {
                margin-top: 10px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                min-height: 70px;
            }

                .aircraft-card__icon-wrapper {
                    @extend %lightWeight;
                    @include font(16px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    white-space: nowrap;

                    img {
                        margin-bottom: 5px;
                    }
                }

                    .aircraft-card__icon-wrapper__tick-cross {
                        width: 22px;
                    }

                .aircraft-card__checkbox-wrapper{
                    position: absolute;
                    top: 8px;
                    left: 16px;
                }

    .aircraft-card__big-expand-button {
        @include inset(0, 0, 0, 0);
        position: absolute;
    }