@keyframes openButtonA {
    0% {
        width: 0;
        height: 0;
    }
    50% {
        width: calc(50% + 1px);
        height: 0;
    }
    100% {
        width: calc(50% + 1px);
        height: 100%;
    }
}