:root {
    --body-bg: #{$c-site-background-colour};
	--header-color: #{$c-grey-01};
	--header-bg: transparent;
    --header-bg-scrolled: #{$c-header-bg};
    --nav-color: #{$c-grey-01};
    --nav-secondary-color: inherit;
    --nav-hover-color: inherit;
    --nav-bg: #{$c-primary-blue};
    --nav-overlay-opacity: .7;
    --home-query-title-color: #{$c-gold};
    --home-query-sub-title-color: #{$c-grey-06};
    --quote-bg: transparent;
    --quote-query-scrolled-bg: #{$c-primary-blue};
    --quote-query-scrolled-color: #{$c-grey-01};
    --quote-query-personal-details-header-color: #{$c-grey-01};
    --quote-query-personal-details-header-bg: #{$c-primary-blue};
    --journey-leg-dot-bg: #{$c-gold};
    --home-query-are-you-looking-for-color: inherit;
    --brochure-h1: #{$c-grey-01};
    --brochure-h2: #{$c-gold};
    --brochure-panel-bg: rgba(0, 0, 0, .15);
    --brochure-panel-border: #{$c-gold-02};
    --brochure-panel-title-color: #{$c-grey-01};
    --brochure-panel-body-color: #{$c-grey-06};
    --brochure-text-color: inherit;
    --home-service-bg: rgba(0, 0, 0, .15);
    --home-service-title-color: #{$c-grey-01};
    --home-service-body-color: #{$c-grey-06};
    --home-service-cta-color: #{$c-gold};
    --quote-header-color: #{$c-grey-01};
    --quote-header-bg: transparent;
    --quote-cta-bar-bg: #{$c-grey-01};
    --quote-cta-bar-divide-bg: #{$c-primary-blue};
    --quote-no-results-color: #{$c-white};
    --aircraft-card-bg: #{$c-grey-01};
    --aircraft-card-extra-actions-bg: #{$c-grey-04};
    --button-color: #{$c-grey-01};
    --button-bg: #{$c-primary-blue};
    --button-border: transparent;
    --button-nav-color: #{$c-grey-dark};
    --button-nav-bg: #{$c-grey-04};
    --button-nav-border: #{$c-gold};
    --button-nav-hover-border: #{$c-gold};
    --button-nav-hover-color: #{$c-grey-dark};
    --aircraft-browser-filters-color: #{$c-grey-06};
    --aircraft-browser-filters-active-color: #{$c-gold};
    --aircraft-browser-results-color: #{$c-gold-02};
    --aircraft-browser-results-title-color: inherit;
    --aircraft-browser-results-list-color: #{$c-grey-06};
    --rc-slider-handle-bg: #{$c-gold};
    --rc-slider-rail-bg: #{$c-pending};
    --swiper-pagination-bullet-bg: #{$c-grey-01};
    --swiper-pagination-bullet-active-bg: #{$c-gold};
    --swiper-button-color: #{$c-gold};
    --swiper-button-bg: #{$c-primary-blue};
    --swiper-pagination-color: inherit;
    --swiper-pagination-bg: rgba(27, 38, 70, 0.5);
    --footer-color: #{$c-grey-01};
    --footer-hover-color: #{$c-gold};
    --footer-bg: rgba(0, 0, 0, 0.3);
    --checkbox-success-bg: #{$c-gold};
    --loading-width: 50px;
    --loading-height: 43px;
    --loading-big-width: 65px;
    --loading-big-height: 56px;
    --block-header-h1-color: #{$c-primary-blue};
    --block-header-h1-color-light: #{$c-grey-01};
    --block-header-h1-color-light-alt: #{$c-grey-01};
    --block-header-h2-color: #{$c-gold};
    --quote-query-padding-top: 0;
    --quote-query-padding-bottom: 20px;
    --quote-query-delete-button-color: inherit;
    --quote-query-delete-button-alt-color: inherit;
    --quote-query-add-button-color: inherit;
    --quote-query-add-button-alt-color: inherit;
    --block-text-bg: #{$c-primary-blue};
    --block-faqs-bg: #{$c-primary-blue};
    --testimonials-item-bg: #{$c-primary-blue};
    --testimonials-author-color: #{$c-gold};     
    --blog-article-preview-cta-color: #{$c-gold};
    --block-read-more-button-color: #{$c-gold};
    --home-hero-bg-img: cover;
    --quote-query-expand-icon: #{$c-white};
    --fold-indicator-icon: #{$c-gold};
    --footer-hover-icon: #{$c-grey-01};
    --brochure-panel-icon: #{$c-gold};
    --loading-icon: #{$c-gold};
    --button-nav-icon: #{$c-grey-03};
    --skeleton: #{$c-grey-06};
    --skeleton-highlight: #{$c-grey-01};
}