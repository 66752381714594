.journey-leg {
	@include font(16px, 22px);
	display: flex;
	justify-content: space-between;
}

	.journey-leg__locations-wrapper {
		position: relative;
		margin-right: 10px;
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;
		text-transform: capitalize;
	}

		.journey-leg__location-and-date {
			position: relative;
			margin-left: 0;
			display: flex;
			flex-direction: row;

			&:last-of-type {
				padding-bottom: 0;
			}
		}

			.journey-leg__long-form-location {
				display: none;
			}

			.journey-leg__date-and-time {
				display: flex;
				flex-direction: column;
				margin-left: 5px;
			}

				.journey-leg__date {
					@extend %lightWeight;
					display: inline-block;

					p {
						display: none;
					}
				}

				.journey-leg__time {
					@extend %lightWeight;
					display: inline-block;
				}

		.journey-leg__line-container{
			flex: 1;
			height: 20px;
			margin: 7px 12px;
			display: flex;
			flex-direction: row;
			align-items: center;

			@include breakpoint(phablet) {
				margin: 7px 22px;
			}

			p {
				@include font(14px, 17px);
				@extend %lightWeight;

				margin-right: 10px;
				text-transform: uppercase;
				color: $c-pending;
				display: none;

				.quote-header--is-simple & {
					color: inherit;
				}
			}
		}

			.journey-leg__location__dot {
				height: 8px;
				width: 8px;
				border-radius: 100%;
				background: var(--journey-leg-dot-bg);
			}

			.journey-leg__location__dotted-line {
				flex: 1;
				margin-top: 1px;
				background-image: radial-gradient(circle, var(--journey-leg-dot-bg) 0.85px, transparent 0);
				height: 3px;
				background-size: 6px 3px;
				background-repeat: repeat-x;
			}

			.journey-leg__location__plane {
				display: none;
			}

		.journey-leg__icons-container {
			position: relative;
			display: flex;
			flex-direction: column;
		}

		.journey-leg__people,
		.journey-leg__luggage {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 3px;

			img {
				margin-right: 3px;
				width: 22px;
			}
		}

// Layout 2
.journey-leg--2 {
	@include font(15px, 21px);
	padding-top: 15px;

	@include breakpoint(phablet) {
		@include font(16px, 24px);
	}

		.journey-leg__locations-wrapper {
			margin-right: 2px;
		}

		.journey-leg__icons-container {
			flex-direction: row;
			align-items: flex-start;
		}

		.journey-leg__luggage {
			margin-bottom: 0;
			margin-left: 5px;
		}

		.journey-leg__people{
			margin-bottom: 0;
			margin-left: 0;
		}

		.journey-leg__line-container {
			margin: 1px 12px;
			@include breakpoint(phablet) {
				margin: 2px 12px;
			}
		}
}

// Layout 3
.journey-leg--3 {
	padding-top: 20px;
	overflow: hidden;
	@include font(16px, 24px);

		.journey-leg__location-and-date {
			flex-wrap: wrap;
			flex: 0;
			align-items: flex-start;
		}

			.journey-leg__location {
				display: flex;
				flex-direction: row;
				white-space: nowrap;
			}

			.journey-leg__long-form-location {
				display: block;
				margin-bottom: 0;
				margin-top: 2px;
				max-width: 25vw;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.journey-leg__short-form-location {
				display: none;
			}

			.journey-leg__date-and-time {
				flex-direction: row;
				width: 100%;
				margin: 0;
			}

				.journey-leg__date {
					margin-right: 10px;
				}

			.journey-leg__icons-container {
				flex-direction: row;
				align-items: flex-start;
			}

			.journey-leg__line-container{
				margin: 5px 10px;
			}

				.journey-leg__location__dot {
					height: 10px;
					width: 10px;
				}

				.journey-leg__location__dotted-line {
					height: 4px;
					background-size: 7px 4px;
				}

			.journey-leg__people,
			.journey-leg__luggage {
				margin-left: 14px;
				margin-top: 4px;

				img {
					margin-right: 5px;
					width: 25px;
				}
			}
}

// Layout 4
.journey-leg--4 {
	@include font(14px, 22px);
	flex-direction: column;
	margin-left: 20px;

	@include breakpoint(phablet) {
		@include font(16px, 24px);
	}

	&.journey-leg--has-subtitle {
		padding-top: 17px + 10px;
	}

	.journey-leg__icons-container {
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
	}

		.journey-leg__locations-wrapper {
			flex-direction: column;
		}

			.journey-leg__location-and-date {
				width: 100%;
				flex-direction: column;
				margin-bottom: 20px;
			}

				.journey-leg__location {
					display: flex;
					flex-wrap: wrap;
				}

					.journey-leg__long-form-location {
						display: block;
						margin-right: 5px;
					}

				.journey-leg__date-and-time {
					margin-left: 0;
					flex-direction: row;
				}

					.journey-leg__time {
						margin-left: 10px;
					}

		.journey-leg__icons-container {
			@include font(16px);
			margin-top: 13px;
			margin-left: -3px;

			img {
				height: 22px;
			}
		}

		&.journey-leg--hide-luggage-and-passengers .journey-leg__icons-container {
			display: none;
		}

			.journey-leg__people{
				margin-right: 12px;
				margin-bottom: 0;
				margin-left: -3px;
			}

			.journey-leg__luggage {
				margin-bottom: 0;
			}

		.journey-leg__line-container {
			position: absolute;
			top: 0;
			left: -38px;
			height: calc(50% + 20px);
			width: 20px;
			display: flex;
			flex-direction: column;

			@include breakpoint(phablet) {
				margin: 7px 12px;
			}

			p {
				display: block;
				position: absolute;
				bottom: calc(100% + 7px + 10px);
				left: 0;
			}
		}

			.journey-leg__location__dotted-line {
				flex: 1;
				margin-top: 0;
				background-image: radial-gradient(circle, $c-gold 0.85px, transparent 0);
				width: 3px;
				background-size: 3px 6px;
				background-repeat: repeat-y;
			}
}

// Layout 5
.journey-leg--5 {
	@include font(14px, 22px);

	@include breakpoint(phablet) {
		@include font(16px, 24px);
	}

	.journey-leg__location-and-date {
		flex-direction: column;
	}

		.journey-leg__date-and-time {
			margin: 0;
		}

			.journey-leg__date {
				position: relative;

				p {
					position: absolute;
					top: 0;
					left: 100%;
					padding-left: 10px;
					white-space: nowrap;
				}
			}

	.journey-leg__line-container {
		margin: 2px 11px 0 5px;
	}

	.journey-leg__icons-container {
		margin-left: 10px;
	}
}

// Layout 6
.journey-leg--6 {
	@include font(16px, 24px);
	margin-top: 50px;

	.journey-leg__location-and-date {
		flex-direction: column;
		min-width: 50%;
		margin-right: 20px
	}

		.journey-leg__location {
			display: flex;
			flex-wrap: wrap;
		}

			.journey-leg__long-form-location {
				display: block;
				margin-right: 5px;
			}

			.journey-leg__date-and-time {
				margin-left: 0;
			}

				.journey-leg__date-and-time {
					margin-left: 0;
					flex-direction: row;
				}

					.journey-leg__time {
						margin-left: 10px;
					}

	.journey-leg__line-container {
		position: absolute;
		top: -30px;
		margin-left: 0;
		left: -1px;
		width: calc(50% + 29px);

		p {
			display: block;
		}
	}

	.journey-leg__icons-container{
		flex-direction: row;
		align-items: flex-start;
		margin-left: 20px;
	}

	&.journey-leg--hide-luggage-and-passengers .journey-leg__icons-container {
		opacity: 0;
		pointer-events: none;
	}

		.journey-leg__people,
		.journey-leg__luggage {
			margin-left: 14px;

			img {
				margin-right: 3px;
				width: 25px;
			}
		}
}

// Layout 7
.journey-leg--7 {
	flex-direction: column;
	margin-left: 20px;
	margin-top: 20px;

	.journey-leg__icons-container {
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.journey-leg__locations-wrapper {
		flex-direction: column;
	}

	.journey-leg__location-and-date {
		width: 100%;
		flex-direction: column;
		margin-bottom: 25px;
	}

	.journey-leg__location {
		display: flex;
		flex-wrap: wrap;
	}

	.journey-leg__long-form-location {
		display: block;
		margin-right: 5px;
	}

	.journey-leg__date-and-time {
		margin-left: 0;
		flex-direction: row;
	}

	.journey-leg__time {
		margin-left: 10px;
	}

	.journey-leg__icons-container {
		margin-left: -22px;
		margin-top: -10px;
	}

	.journey-leg__people{
		margin-right: 10px;
		margin-bottom: 0;
		margin-left: -3px;
	}

	.journey-leg__luggage {
		margin-bottom: 0;
	}

	.journey-leg__line-container {
		position: absolute;
		top: 7px;
		left: -20px;
		height: calc(50% + 20px);
		width: 20px;
		display: flex;
		flex-direction: column;
		margin: 0;
	}

	.journey-leg__location__dotted-line {
		margin-left: 2.5px;
		flex: 1;
		margin-top: 0;
		background-image: radial-gradient(circle, $c-gold 0.85px, transparent 0);
		width: 3px;
		background-size: 3px 6px;
		background-repeat: repeat-y;
	}
}

// Layout 8
.journey-leg--8 {
	color: $c-primary-blue;
	@include font(16px, 24px);

	.journey-leg__location-and-date {
		flex-wrap: wrap;
		flex: 0;
		align-items: flex-start;
		max-width: 45%;
	}

	.journey-leg__location {
		display: flex;
		flex-direction: column;
		white-space: nowrap;
	}

	.journey-leg__short-form-location {
		@include font(22px);
		@extend %mediumWeight;
	}

	.journey-leg__long-form-location {
		display: block;
		width: 17vw;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: -4px 0 -1px;
	}

	.journey-leg__date-and-time {
		@include font(14px);
		white-space: nowrap;
		flex-direction: row;
		width: 100%;
		margin: 0;
	}

	.journey-leg__date {
		display: flex;
		align-items: center;
		margin-right: 10px;
		text-transform: none;

		p {
			display: block;
			margin-right: 5px;
			text-transform: uppercase;
		}
	}

	.journey-leg__icons-container {
		padding-top: 10px;
		flex-direction: column;
		justify-content: space-between;
	}

	.journey-leg__line-container{
		margin: 4px 10px 9px -60px;
	}

	.journey-leg__location__dot {
		display: none;
	}

	.journey-leg__location__dotted-line {
		height: 4px;
		margin-top: -10px;
		background-size: 7px 4px;
	}

	.journey-leg__location__plane {
		display: block;
		position: absolute;
		left: calc(50% - 55px);
		top: -5px;
	}

	&.journey-leg--is-return-trip .journey-leg__location__plane {
		top: -9px;
	}

	.journey-leg__people,
	.journey-leg__luggage {
		margin-left: 14px;
	}
}

// Layout 9
.journey-leg--9 {
	color: $c-primary-blue;
	position: relative;

	.journey-leg__short-form-location {
		@include font(22px);
		@extend %boldWeight;
	}

	.journey-leg__location-and-date {
		flex-direction: column;
	}

	.journey-leg__date-and-time {
		@include font(14px);
		margin-left: 0;
		min-height: 35px;
	}

	.journey-leg__date {
		text-transform: none;

		p {
			display: block;
			margin-right: 5px;
			text-transform: uppercase;
		}
	}

	.journey-leg__time {
		max-width: 35px;
		white-space: nowrap;
		overflow: hidden;
	}

	.journey-leg__line-container{
		margin: 9px 13px 9px -20px;
	}

	.journey-leg__location__dotted-line {
		margin-top: -10px;
	}

	.journey-leg__location__dot {
		display: none;
	}

	.journey-leg__location__plane {
		display: block;
		position: absolute;
		left: calc(50% - 7px);
		top: 0;
	}

	&.journey-leg--is-return-trip .journey-leg__location__plane {
		top: -9px;
	}

	.journey-leg__icons-container {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
		flex-direction: row;
	}

	.journey-leg__people {
		margin-right: 10px;
	}
}