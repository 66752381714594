.modal-error {

	&__inner {
		@include font(16px, 22px);
		padding: 30px;
	}

	p {
		margin-bottom: 1em;
	}

	button {
		margin: 40px auto 10px;
		min-width: 200px;
	}
}