.testimonials {
	@include font(14px, 19px);
	@extend %italicStyle;

	display: flex;
	justify-content: center;
	color: $c-blue-04;
	border-radius: 4px;

	@include breakpoint(tablet) {
		@include font(16px);
	}

	.swiper {
		width: 100%;
		padding-bottom: 19px;
		padding-left: $site-gutter;
	}

		.swiper-slide {
			display: flex;
			height: auto;
			padding-right: $site-gutter;
		}

		.swiper-pagination {
			position: static;
			margin-top: 10px;
		}

			.swiper-pagination-bullet {
				background: $c-grey-07;
			}

			.swiper-pagination-bullets .swiper-pagination-bullet {
				margin: 0 12px;
			}

			.swiper-pagination-bullet-active {
				background: var(--swiper-pagination-bullet-active-bg);
			}
}

	.testimonials__item {
		display: flex;
		width: 100%;
		padding: 24px 16px;
		background: var(--testimonials-item-bg);
		border-radius: 4px;
	}

		.testimonials__icon {
			flex: 0 0 auto;
			margin-right: 10px;
		}

		.testimonials__content {
			flex: 1 1 auto;
		}

			.testimonials__author {
				@extend %italicStyle;
				
                color: var(--testimonials-author-color);
				text-align: end;
				margin-top: 32px;
				font-family: Georgia;
				line-height: 17px;
			}