// Brochure style covers several public facing pages
// Home, Careers and Ambassadors
// A good place for shared general styles

.brochure {
	color: $c-grey-06;
	position: relative;
	width: 100%;
	overflow: hidden;

	.home__services {

		.brochure__subtitle {
			max-width: 250px;
			margin-right: auto;
			margin-left: auto;

			@include breakpoint(phablet) {
				max-width: none;
			}
		}
	}

	// H1 section title 
	.brochure__title {
		@include font(24px, 28px);
		@extend %lightWeight;

		text-transform: uppercase;
		letter-spacing: 0.05em;
		text-align: center;
		max-width: 800px;
		margin: 0 auto 1em;

		// theme
		color: var(--brochure-h1);

		@include breakpoint(phablet) {
			@include font(26px, 32px);
		}

		@include breakpoint(tablet) {
			@include font(28px, 34px);
		}

		@include breakpoint(laptop) {
			@include font(32px, 36px);
		}
	}

	// H2 section title
	.brochure__subtitle {
		@include font(16px, 20px);
		padding-bottom: 0.7em;
		text-align: center;
		text-transform: uppercase;

		// theme
		color: var(--brochure-h2);

		@include breakpoint(tablet) {
			@include font(18px, 22px);
		}
	}

	// Sections
	section {
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
	}

	&__first-section {
		margin-top: 85px
	}

	&__section--white {
		background-color: $c-grey-01;
		color: $c-grey-dark;

		.brochure__title {
			color: $c-primary-blue;
		}
	}

	&__section--blue {
		background-color: $c-blue-dark;
	}

	&__inner {
		padding-top: 5vh;
		padding-bottom: 7vh;

		@include breakpoint(tablet) {
			padding-top: 6vh;
			padding-bottom: 8vh;
		}
	}

	// Banner
	&__banner {
		width: 100vw;
		height: 45vw;
		overflow: hidden;
		position: relative;

		.brochure--skeleton & {
			@extend %skeleton;
		}
	}

	// Title band
	&__title-band {
		animation: fadeImageIn forwards 1s;
		text-align: center;
		padding: 30px $site-gutter;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: rgba($c-black, 0.2);

		@include breakpoint(phablet) {
			padding: 40px $site-gutter 50px;
		}

		.brochure__title {
			@include font(24px, 28px);
			text-transform: none;
			padding: 0;
			margin: 0;
			letter-spacing: normal;

			@include breakpoint(phablet) {
				@include font(26px, 30px);
			}

			@include breakpoint(tablet) {
				@include font(28px, 34px);
			}

			@include breakpoint(notebook) {
				@include font(30px, 36px);
			}

			.brochure--skeleton & {
				@extend %skeleton;
				
				width: 50%;
			}
		}

		&--grey {
			background-color: $c-grey-01;

			.brochure__title {
				color: $c-primary-blue;
				@extend %italicStyle;
			}
		}
	}

	// General centralised paragraph
	&__centered-text {
		@include font(16px, 24px);
		@extend %lightWeight;

		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		max-width: 900px;
		margin: auto;

		@include breakpoint(laptop) {
			@include font(18px, 26px);
		}

		p {
			margin-bottom: 1.6em;

			// theme
			color: var(--brochure-text-color)
		}
	}

	// Small print
	&__small-print {
		@include font(14px, 18px);
		color: $c-grey-02;
		text-align: center;
		position: relative;
		width: 80vw;
		max-width: 800px;
		margin: auto;
		padding-top: 35px;
		margin-top: 10px;

		&:before {
			content: '';
			width: 150px;
			height: 1px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%);
			background-color: $c-gold;
		}
	}

	// Button wrapper
	&__button-wrapper {
		margin-top: 40px;
		width: 100%;

		@include breakpoint(phablet) {
			width: 75vw;
			max-width: 350px;
		}

		@include breakpoint(tablet) {
			margin-top: 50px;
		}
	}

	// 2 column layout
	&__2-column-layout {
		display: grid;
		row-gap: 30px;
		justify-content: center;
		align-items: center;
		margin-bottom: 7vh;
		margin-top: 3vh;
		@include font(16px, 24px);
		@extend %lightWeight;

		@include breakpoint(laptop) {
			@include font(18px, 26px);
		}

		&:first-of-type {
			margin-top: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&--flipped {
			.brochure__2-column-layout__image {
				order: 2;
			}
		}

		@include breakpoint(tablet) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
			margin-bottom: 2vh;
			margin-top: 5vh;
		}

		&__image {
			width: 100%;
			height: 0;
			padding-bottom: 70%;
			position: relative;
		}

		&__image,
		img {
			grid-row: 1;
			@include breakpoint(tablet) {
				grid-row: auto;
			}
		}

		&__text {
			max-width: 600px;
			margin: auto;
			grid-row: 2;

			@include breakpoint(tablet) {
				max-width: 450px;
				grid-row: auto;
			}
		}

		p {
			margin-bottom: 1em;
		}

		.brochure__subtitle {
			@extend %boldWeight;
			@include font(20px, 22px);
			color: $c-grey-01;
			margin-top: 5px;
			margin-bottom: 0.2em;

			@include breakpoint(tablet) {
				text-align: left;
			}

			@include breakpoint(notebook) {
				@include font(22px, 24px);
			}
		}

		b,
		strong {
			@extend %boldWeight;
		}
	}

	// Swiper
	&__swiper-wrapper {
		max-width: 100vw;
		position: relative;

		@include breakpoint(laptop) {
			max-width: 1350px;
			mask-image: linear-gradient(90deg, rgba(2,0,41,0) 0%, rgba(2,0,36,1) 3%, rgba(9,9,121,1) 97%, rgba(255,255,255,0) 100%);
		}

		.swiper {
			padding-bottom: 50px;
		}

		.swiper-slide {
			height: auto;
		}

		.brochure__panel {
			max-width: none;
			height: 100%;
			margin-left: $site-gutter;
			@include breakpoint(tablet) {
				margin-left: $site-gutter--tablet;
			}
		}
	}

	// Panels wrapper
	&__panels {
		display: grid;
		row-gap: 30px;
		margin: auto;
		justify-content: stretch;
		align-items: stretch;
		width: 100%;
		max-width: 400px;

		@include breakpoint(tablet) {
			width: 100%;
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
			max-width: 800px;
			margin-top: 50px;
		}

		@include breakpoint(notebook) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			max-width: none;
		}
	}

	// Panel (single)
	&__panel {
		border-radius: 4px;
		padding: 30px 20px 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		backdrop-filter: blur(5px);

		// theme
		background: var(--brochure-panel-bg);
		border: 0.5px solid var(--brochure-panel-border);

		@include breakpoint(tablet) {
			width: auto;
		}

		&__icon {
			margin-bottom: 18px;
		}

		&__title {
			@include font(22px, 28px);
			text-transform: capitalize;
			margin-bottom: 0.8em;
			text-align: center;

			// theme
			color: var(--brochure-panel-title-color);
		}

		&__body {
			@include font(16px, 24px);
			@extend %lightWeight;

			text-align: center;
			white-space: pre-line;

			// theme
			color: var(--brochure-panel-body-color);

			a {
				text-decoration: none;

				&:hover {
					border-bottom: 1px solid $c-grey-01;
				}
			}
		}

        svg {
            stroke: var(--brochure-panel-icon)
        }
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}