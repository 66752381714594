.filters {
	padding: 48px 0 16px;
	background-color: $c-grey-01;

	@include breakpoint(tablet) {
		padding-top: 0;
	}

	.wrap {

		@include breakpoint(tablet) {
			display: flex;
		}
	}
}

.filters--active {

	@include breakpoint(tablet) {
		position: sticky;
		top: 50px + 62.5px;
		z-index: 1;
	}

	.modal & .wrap {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - 48px - 16px);
	}
}

	.filters__inner {
		@include margin(bottom, 45px);

		@include breakpoint(tablet) {
			display: flex;
			flex-direction: column;
			margin-bottom: 0;
			padding-top: 18px;
		}
	}

	.filters__inner--filters {

		@include breakpoint(tablet) {
			width: (100% / 6) * 5;
			padding-right: 12px;
		}
	}

	.filters__inner--sort {

		@include breakpoint(tablet) {
			width: (100% / 6) * 1;
		}
	}

		.filters__options {

			@include breakpoint(tablet) {
				@include margin(bottom, 32px);

				flex: 1 1 auto;
				display: flex;
				margin-right: -12px;
			}

			.filter,
			.sort {
				@include margin(bottom, 8px);

				@include breakpoint(tablet) {
					flex: 0 0 20%;
					margin-bottom: 0;
					padding-right: 12px;
				}

				.filters__inner--sort & {

					@include breakpoint(tablet) {
						flex-basis: 100%;
					}
				}
			}
		}

			.filters__title {
				@include margin(bottom, 16px);

				width: 100%;
			}

	.filters__footer {

		.modal & {
			margin-top: auto;
		}
	}