.close-cross{
  @extend %hideText;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  background-color: transparent;

  .styleguide__item > & {
    position: static;
  }
}