.timeline-point {
	@include font(16px, 19px);
	padding-bottom: 50px;
	padding-left: 40px;
	padding-right: 20px;
	position: relative;
	text-align: left;

	&:last-of-type {
		padding-bottom: 0;
		.timeline-point__line {
			display: none;
		}
	}

	@include breakpoint(tablet) {
		padding-left: 50px;
		padding-bottom: 65px;
	}

	&--offset {
		margin-left: -6px;

		@include breakpoint(tablet) {
			margin-left: -10px;
		}
	}
}

.timeline-point--selected {
	@extend %boldWeight;
	
	color: $c-gold-02;
}

	.timeline-point__unselected {
		height: 24px;
		width: 24px;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include breakpoint(tablet) {
			height: 32px;
			width: 32px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	.timeline-point__unselected--selected {}

	.timeline-point__line {
		position: absolute;
		top: 21px;
		bottom: -2px;
		left: 11px;
		border-left: 2px dashed $c-gold;

		@include breakpoint(tablet) {
			top: 32px;
			left: 15px;
		}
	}