.fold-indicator {
	position: fixed;
	bottom: 35px;
	left: 50%;
	transform: translateX(-50%);
	transition: opacity .3s ease;
	display: none;

	@include breakpoint(tablet) {
		display: block;
	}
}

	.fold-indicator__content {

		img,
		svg {
			display: block;
		}
		
		svg {
			fill: var(--fold-indicator-icon);
		}
	}