/// Set the select input to fill the full width
///
/// @author Dudley Rees <dudley.rees@damdigital.com>

@mixin selectInputFullWidth() {
	*:first-child {
		width: 100%;
	}

	.select-input__control {
		max-width: none;
		width: 100%;
	}

	.select-input__menu {
		max-width: none;
	}
}