fieldset {
    padding: 0;
}

button,
input[type='submit'],
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='image'],
label[for] {
    cursor: pointer;
}

button {
    border: none;
}

button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
    cursor: default;
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea {
    appearance: none;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    appearance: none;
}

textarea {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

button,
select {
    text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input::placeholder {
    color: inherit;
    opacity: 1;
}

textarea::placeholder {
    color: inherit;
    opacity: 1;
}

input:-webkit-autofill {
    -webkit-text-fill-color: inherit;
    box-shadow: 0 0 0 62.5rem var(--color-primary) inset;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}