.filters-summary {
	padding-top: 20px;
	background: $c-white;

	@include breakpoint(tablet) {
		padding-top: 14px;
	}
}

	.filters-summary__selections {
		display: flex;
		flex-wrap: wrap;
		margin: -8px -8px 8px;

		&:empty {

			& + .filters-summary__clear {
				display: none;
			}
		}
	}

		.filters-summary__selection {
			@include font(14px, 17px);

			display: flex;
			align-items: center;
			min-height: 34px;
			margin: 8px;
			padding: 0 10px;
			background: $c-filter-selection;

			span {
				@extend %lightWeight;

				margin-right: 5px;
				text-transform: capitalize;
			}

			img {
				width: 12px;
				height: 12px;
				margin-left: 15px;
			}
		}

	.filters-summary__clear {
		@include font(14px, 17px);

		color: $c-filters-clear;
		text-decoration: underline;
	}