.more-less-button {
	@include font(18px);
	@extend %lightWeight;
	margin: auto;
	background: transparent;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;

	img {
		height: 8px;
		margin-right: 8px;
		margin-top: 3px;
		transition: transform 250ms;
	}
}

.more-less-button--selected {
	img{
		transform: rotate(180deg);
	}
}