.transaction-history {
	@include font(16px, 19px);
}

	.transaction-history__title {
		@include margin(bottom, 10px);
		@include font(18px, 22px);
		@extend %boldWeight;

		display: flex;
		justify-content: space-between;
		width: 100%;
		letter-spacing: 0.02em;
	}

		.transaction-history__icon {
			margin-top: 4px;
			transform-origin: center;
			transition: all $aircraft-filter-speed;

			@include breakpoint(phablet) {
				width: 22px;
			}
		}

		.transaction-history__icon--rotated {
			transform: rotate(-180deg);
		}

	.transaction-history__list {}

		.transaction-history__item {
			padding: 12px 0;
			border-bottom: 1px solid $c-grey-04;

			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}

			@include breakpoint(tablet) {
				padding: 18px 0;
			}
		}

			.transaction-history__row {
				@include margin(bottom, 2px);

				display: flex;
				justify-content: space-between;
				align-items: center;
			}

				.transaction-history__ref,
				.transaction-history__amount {
					@extend %boldWeight;
				}

				.transaction-history__method,
				.transaction-history__date {
					@include font(14px, 19px);
					@extend %lightWeight;
				}