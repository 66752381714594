.collapsible-content {
	color: $c-grey-dark;
}

	.collapsible-content__header {
		@include margin(bottom, 20px);
		@include font(16px, 19px);

		display: flex;
		flex-direction: column;

		@include breakpoint(tablet) {
			@include margin(bottom, 24px);

			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

		.collapsible-content__intro {
			flex: 1 1 auto;

			p {
				.collapsible-content--skeleton & {
					@extend %skeleton;
				}
			}
		}

		.collapsible-content__toggle {
			display: flex;
			align-items: center;
			margin-top: 20px;
			margin-left: auto;
			white-space: nowrap;

			@include breakpoint(tablet) {
				margin-top: 0;
				margin-left: 20px;
			}
			
			.collapsible-content--skeleton & {
				@extend %skeleton;
			}

			img {
				margin-left: 12px;
				
				.collapsible-content--skeleton & {
					display: none;
				}
			}
		}

	.collapsible-content__items {
		@include margin(bottom, 40px);

		@include breakpoint(tablet) {
			@include margin(bottom, 56px);
		}

		ul,
		ol{
			margin: 0;
			margin-bottom: 1em;
			margin-top: -8px;
			padding: 0 25px;
			@include breakpoint(phablet) {
				padding: 0 50px;
			}
		}

		ol{
			list-style-type: lower-alpha;
			@include breakpoint(phablet) {
				padding: 0 50px;
			}

			ol {
				margin-top: 0;
				list-style-type: lower-roman;
			}
		}

		a {
			@extend %mediumWeight;
			border-bottom: 1px solid $c-grey-dark;
		}

		table {
			border-collapse: collapse;
			width: 100%;
			margin: 0 auto 20px;
		}

		th, td {
			border: 1px solid #ccc;
			padding: 10px;
			text-align: left;
		}

		b, strong {
			@extend %mediumWeight;
		}

		th {
			@extend %boldWeight;
			background-color: $c-grey-05;
			@include breakpoint(phablet) {
				white-space: nowrap;
			}
		}
	}

	.collapsible-content__footer {
		display: flex;
		justify-content: center;
	}

		.collapsible-content__to-top {
			display: flex;
			align-items: center;
			
			.collapsible-content--skeleton & {
				@extend %skeleton;
			}

			img {
				margin-right: 4px;
				
				.collapsible-content--skeleton & {
					display: none;
				}
			}
		}