.block-articles {
	padding: 50px 0 30px;

	@include breakpoint(tablet) {
		padding: 64px $site-gutter 110px;
	}

	.wrap {
		max-width: 1096px;
	}

	.block-header {
		@include margin(bottom, 32px);
	}
}

	.block-articles__content {
		@include margin(bottom, 40px);

		position: relative;
		margin-right: -$site-gutter;
		margin-left: -$site-gutter;

		@include breakpoint(tablet) {
			margin-right: -8px;
			margin-left: -8px;
		}
	}

		.block-articles__swiper {
			padding-bottom: 32px;

			.swiper-wrapper {
				display: flex;
			}

			.swiper-slide {
				width: 311px + 16px;
				height: auto;
				padding: 0 8px;

				&:first-child {
					margin-left: 16px;
				}
			}

				.blog-article-preview {
					height: 100%;
				}

			.swiper-pagination {
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 1;
			}

				.swiper-pagination-bullet {
					width: 8px;
					height: 8px;
					margin: 0 12px;
					background: $c-grey-04;
					opacity: 1;
				}

				.swiper-pagination-bullet-active {
					background: var(--swiper-pagination-bullet-active-bg)
				}
		}

	.block-articles__grid {
		display: flex;
		justify-content: center;

		.blog-article-preview {
			width: calc(25% - 16px);
			margin: 0 8px;
		}
	}