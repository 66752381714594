.quote-query-personal-details {}

	.quote-query-personal-details__header {
		padding: 25px 15px 30px 15px;
		border-radius: 4px;

		// theme
		color: var(--quote-query-personal-details-header-color);
		background: var(--quote-query-personal-details-header-bg);

		@include breakpoint(phablet) {
			padding: 32px 25px 35px 25px;
		}

		@include breakpoint(notebook) {
			padding: 45px 35px
		}

		.quote-query--in-modal & {
			margin: -26px -26px 0;
		}
	}

		.quote-query-personal-details__header__legs-count {
			@include font(20px);
			letter-spacing: 0.1em;
			text-transform: uppercase;
			text-align: center;
			margin-top: 3px;
			margin-bottom: 5px;

			@include breakpoint(phablet) {
				margin-top: 0;
				margin-bottom: 10px;
			}

			@include breakpoint(tablet) {
				@include font(22px);
			}
		}

		.quote-query-personal-details__header__more {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: auto;
			transform: translate(0, 5px);

			span:nth-of-type(2) {
				display: none;
			}

			img {
				margin-right: 7px;
			}

			@include breakpoint(tablet) {
				@include font(18px);

				img {
					margin-right: 10px;
				}

				span:nth-of-type(1) {
					display: none;
				}

				span:nth-of-type(2) {
					display: inline-block;
				}
			}

		}

		.quote-query-personal-details__header__more--less {
			img {
				transform: rotate(180deg);
			}
		}

	.quote-query-personal-details__form {
		padding: 22px 22px 40px;
	}

		.quote-query-personal-details__form-group {

			.fieldset:not(.fieldset--margin-bottom) + & {
				margin-top: 30px;

				@include breakpoint(tablet) {
					margin-top: 0;
				}

				.contact-preferences-input {
					margin-top: 0;
				}
			}
		}

		.quote-query-personal-details__intro {
			@include font(16px, 19px);
			margin-bottom: 30px;

			@include breakpoint(phablet) {
				@include font(18px, 24px);
			}

			@include breakpoint(tablet) {
				margin-top: 10px;
				@include font(20px, 26px);
			}
		}

	.quote-query-personal-details__success {
		color: $c-grey-dark;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 40px 40px 50px;
	}

		.quote-query-personal-details__success__big-tick {
			margin-bottom: 10px;
		}

		.quote-query-personal-details__success__thanks {
			@include font(22px, 30px);
			text-align: center;
		}

		.quote-query-personal-details__success__be-in-touch {
			margin-top: 10px;
			margin-bottom: 45px;
		}

		.quote-query-personal-details__new-quote-button-container {
			max-width: 300px;
			width: 100%;
		}

		.quote-query-personal-details__success__shy-lifestyle {
			@include font(14px, 20px);
			@extend %lightWeight;
			margin-top: 60px;
			max-width: 400px;
			text-align: center;

			a {
				@extend %mediumWeight;
				text-decoration: none;
				color: $c-gold;
				border-bottom: 1px solid $c-gold;
			}
		}

	.quote-query-personal-details__text-area-container {
		@include breakpoint(tablet) {
			grid-column: span 2;
		}
	}