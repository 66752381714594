.service-request {
	max-width: 600px;
	margin: 0 auto;
	padding: 44px $site-gutter 28px;

	.return-message {
		@include margin(bottom, 20px);
	}

	.service-request__header {
		@include margin(bottom, 32px);
		@extend .block-header;
	}
}

	.service-request__text {
		@include margin(bottom, 32px);
		@include font(14px, 19px);
		@extend %lightWeight;

		text-align: center;

		@include breakpoint(tablet) {
			@include font(16px, 22px);
		}
	}

	.service-request__form {

		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
		}

		.fieldset {

			@include breakpoint(tablet) {
				width: 50%;
			}

			&:nth-child(2n) {

				@include breakpoint(tablet) {
					padding-left: 8px;
				}
			}

			&:nth-child(2n+1) {

				@include breakpoint(tablet) {
					padding-right: 8px;
				}
			}

			&:nth-child(5) {

				@include breakpoint(tablet) {
					width: 100%;
					padding: 0;
				}
			}
		}

		.service-request__submit {
			width: 100%;

			.button {
				margin: 0 auto;

				@include breakpoint(phablet) {
					max-width: 350px;
				}
			}
		}
	}

	.service-request__success {
		color: $c-grey-dark;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 20px;
	}

		.service-request__success-tick {
			margin-bottom: 10px;
		}

		.service-request__success-title {
			@include font(22px, 30px);
			@include margin(bottom, 10px);

			text-align: center;
		}