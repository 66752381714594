// popup Button
.popup-button {
	@include font(16px);
	@extend %hideText;
	background-color: $c-gold;
	position: fixed;
	bottom: 30px;
	right: 15px;
	height: 50px;
	width: 50px;
	border-radius: 100%;
	z-index: 9995;

	img {
		margin-top: 3px;
	}

	@include breakpoint(phablet) {
		width: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 48px;
		padding: 0 25px 3px 20px;
		border-radius: 12px;
		right: 30px;
		color: $c-grey-01;

		img {
			margin-top: 6px;
			margin-right: 5px;
		}
	}
}

// popup button close
.popup-button-close {
	@extend %hideText;
	background-color: $c-gold;
	position: fixed;
	bottom: 30px;
	right: 15px;
	height: 50px;
	width: 50px;
	border-radius: 100%;
	z-index: 9995;
	pointer-events: none;
	@include breakpoint(phablet) {
		display: none;
	}
}

// Pop-up
.popup-pop-up {
	@include font(16px);
	color: $c-grey-dark;
	position: fixed;
	bottom: 100px;
	right: 30px;
	background-color: $c-grey-01;
	border-radius: 12px;
	width: calc(100vw - 50px);
	max-width: 320px;
	opacity: 0;
	transition: all 350ms;
	transform: translate(0, 8%) scale(0.92);
	z-index: 9995;
}

.popup-pop-up--open {
	transform: translate(0) scale(1);
	opacity: 1;
}

	.popup-pop-up__header {
		border-radius: 12px 12px 0 0;
		height: 40px;
		padding-right: 10px;
		background-color: $c-gold;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

		.popup-pop-up__header_close {

		}

	.popup-pop-up__body {
		padding: 20px 24px;
	}

	.popup-pop-up__title {
		@include font(18px)
	}

	.popup-pop-up__text {
		@extend %lightWeight;
		margin-top: 8px;
		margin-bottom: 13px;
	}

	.popup-pop-up__link {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

		.popup-pop-up__link__text {
			@include font(14px);
			white-space: nowrap;
			border-bottom: 1px solid $c-gold;
			color: $c-gold;
			margin-right: 5px;
		}

.popup__tail {
	position: absolute;
	bottom: -10px;
	right: 10px;
}

.popup__contact-widget-wrapper {
	position: fixed;
	bottom: 100px;
	right: 30px;
}