.carbon-neutral {
	@include font(16px, 21px);
	padding: 20px;
	color: $c-grey-dark;

	@include breakpoint(phablet) {
		@include font(16px, 22px);
		padding: 30px;
	}

	a {
		border-bottom: 1px dashed $c-grey-dark;
		&:hover {
			border-bottom: 1px solid $c-grey-dark;
		}
	}

	p {
		@extend %lightWeight;
		margin-bottom: 1em;
	}

	h2 {
		@extend %boldWeight;
		@include font(18px);
		text-align: center;
		text-transform: uppercase;
		margin: 1.5em 0 0.7em;
	}

	&__swiper-wrapper {
		width: 100%;
		padding-bottom: 75%;
		position: relative;
		margin-top: 30px;
		@include breakpoint(tablet) {
			padding-bottom: 25%;
		}

		&__inner {
			@include inset(0, 0, 0, 0);
			position: absolute;
		}
	}

	&__button-wrapper {
		margin-top: 30px;
		display: flex;
		flex-direction: row;
		justify-content: center;

		button {
			max-width: 300px;
			width: 100%;
		}
	}
}