.passengers_list {
	min-height: calc(100vh - 165px);
	display: flex;
	flex-direction: column;
	padding-bottom: 16px;

	&__links_list {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;

		&__passenger {
			@include font(16px, 18px);
			padding: 18px;
			border-radius: 6px;
			background-color: $c-grey-05;
			border: 1px solid transparent;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 16px;
			text-align: left;
			text-decoration: none;

			&--selected {
				background-color: transparent;
				border: 1px solid $c-gold;

				.passenger-list__checklist__check-circle {
					border: solid 1px $c-gold;
				}
			}

			&__check-circle {
				height: 24px;
				width: 24px;
				border: solid 1px $c-grey-03;
				border-radius: 100%;
				margin-right: 18px;
				flex-shrink: 0;

				&--complete {
					background-color: $c-gold;
					border: solid 1px transparent;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					img {
						width: 60%;
						@include breakpoint(tablet) {
							width: 50%;
						}
					}
				}
			}

			&__status {
				@extend %lightWeight;
				@include font(14px);
			}
		}
	}

	&__checkbox_list {
		margin-bottom: auto;

		&__header {

		}

		&__passenger {
			display: flex;
			flex-direction: row;
			margin-bottom: 16px;

			&__checkbox__container {
				background-color: $c-grey-05;
				border-radius: 6px;
				margin-right: 6px;
				flex-shrink: 0;
				width: 50px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.checkbox {
					margin: 0;
					padding: 0;
				}
			}

			&__name_and_dob {
				border-radius: 6px;
				padding: 18px;
				background-color: $c-grey-05;
				flex: 1;
				border: 1px solid $c-gold;

				&--selected {
					background-color: transparent;
					cursor: default;
				}

				&__name {
					@include font(16px, 18px);
					margin-bottom: 4px;
				}

				&__dob {
					@extend %lightWeight;
					@include font(14px);
				}
			}

		}
	}
}

	.passengers_list__actions {
		@include margin(bottom, 10px);

		display: flex;
	}

		.passengers_list__sort {
			@include margin(right, 10px);

			width: calc(100% - 56px - 53px);
			transition: width .3s ease;

			@include breakpoint(tablet) {
				width: calc(50% - (56px / 2) - 5px);
			}

			@include breakpoint(notebook) {
				width: calc(100% - 56px - 53px);
			}

			.passengers_list__actions--small-search-active & {
				flex: 0 0 auto;
				width: calc(50% - (56px / 2) - 5px);

				@include breakpoint(notebook) {
					width: 0;
					margin-right: 0;
				}

				.sort__container {

					@include breakpoint(notebook) {
						border: none;
					}
				}
			}
		}

		.passengers_list__search {
			position: relative;
			flex: 0 0 auto;
			width: 53px;
			transition: width .3s ease;

			@include breakpoint(tablet) {
				width: calc(50% - (56px / 2) - 5px);
			}

			@include breakpoint(notebook) {
				width: 53px;
			}

			.passengers_list__actions--small-search-active & {
				width: calc(50% - (56px / 2) - 5px);

				@include breakpoint(notebook) {
					width: calc(100% - 56px);
					height: 53px;
				}
			}
		}

			.passengers_list__search-input {
				@extend %lightWeight;
				@include font(16px);

				padding-left: 15px + 12px + 12px;
				padding-right: 8px;
				color: transparent;
				width: 100%;
				height: 100%;
				border: 1px solid rgba($c-grey-03, 0.3);
				border-radius: 4px;
				cursor: pointer;

				@include breakpoint(tablet) {
					color: $c-primary-blue;
				}

				@include breakpoint(notebook) {
					color: transparent;
				}

				.passengers_list__actions--small-search-active & {
					color: $c-primary-blue;
				}

				&:focus {
					outline: none;
				}
			}

			.passengers_list__search-icon {
				position: absolute;
				top: 19px;
				left: 19px;
				pointer-events: none;

				.passengers_list__actions--small-search-active & {
					left: 12px;
				}
			}

			.passengers_list__search-close {
				position: absolute;
				top: 15px;
				right: 15px;
				cursor: pointer;
			}
