.welcome {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $c-grey-03;
	padding: 35px;
	text-align: center;

	@include breakpoint(phablet) {
		padding: 45px 55px;
	}

	img {
		@include margin(bottom, 24px);
	}
}

	.welcome__text {
		@include font(16px, 20px);
		@include margin(bottom, 64px);

		max-width: 260px;
		color: $c-grey-dark;
	}