.radio-button {
	display: flex;
	flex-direction: row;
	position: relative;
	margin: 15px 0;
}

.radio-button__button {
	flex-shrink: 0;
	height: 18px;
	width: 18px;
	border-radius: 100%;
	border: solid $c-gold 1px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include breakpoint(tablet) {
		height: 22px;
		width: 22px;
	}
}

.radio-button__button--error {
	border: solid $c-red 1px;
}

.radio-button__selected {
	height: 70%;
	width: 70%;
	border-radius: 100%;
	background-color: $c-gold;
}

.radio-button__label {
	@extend %lightWeight;
	@include font(16px, 20px);
	width: auto;
	display: block;
	margin-left: 8px;
	margin-top: -1px;

	@include breakpoint(tablet) {
		margin-left: 12px;
		margin-top: 2px;
	}

	a {
		@extend %mediumWeight;
		text-decoration: none;
		border-bottom: 1px solid $c-grey-dark;
	}
}