.from-to-input {
}

	.from-to-input__input {
		@extend %boldWeight;
		@include font(16px);
		color: $c-primary-blue;
		background-color: rgba($c-grey-01, .8);
		height: 42px;
		border-radius: 4px;
		display: block;
		border: none;
	}