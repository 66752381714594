/// Define the border-radius for a button part
///
/// @param {String} $radius The desired radius size
/// @param {String} $side The side to apply it to
/// @author Joe Adcock <joe.adcock@damdigital.com>

@mixin button-radius($radius, $side) {
    @if $side != 'all' {
        border-top-#{$side}-radius: $radius;
        border-bottom-#{$side}-radius: $radius;    
    } @else if $side == 'all' {
        border-radius: $radius;    
    }
}