.signature-input {
	position: relative;
	margin-bottom: 20px;
}
	.signature-input__input {
		@include apply-map($f-secondary);
		font-weight: bold;
		@include font(35px, 20px);
		border: none;
		width: 100%;
		outline: none;
		padding-top: 40px;
		background-color: transparent;
	}

	.signature__line {
		margin-top: -12px;
		height: 1px;
		background: $c-grey-dark;
	}

	.signature__label {
		@extend %lightWeight;
		color: $c-grey-02;
		display: block;
		padding-top: 10px;
	}

.signature-input--error {
	.signature__line {
		background-color: $c-red;
	}
}
