.share-payment-method {
	@include margin(bottom, 20px);

	padding: 20px 0;

	@include breakpoint(tablet) {
		max-width: 393px;
		margin-bottom: 0;
		padding: 30px 50px 55px;
	}

	p {
		@include font(16px, 19px);
		@include margin(bottom, 20px);
	}
}

.share-payment-method--email {

	@include breakpoint(tablet) {
		border: 0.5px solid rgba($c-grey-06, 0.2);
		box-shadow: -2px 2px 4px rgba(212, 188, 135, 0.04), 2px -2px 4px rgba(212, 188, 135, 0.04), -2px -2px 4px rgba(212, 188, 135, 0.04), 2px 2px 4px rgba(212, 188, 135, 0.04), inset 2px 1px 2px rgba(212, 188, 135, 0.02), inset -1px -1px 2px rgba(212, 188, 135, 0.02);
	}
}

	.share-payment-method__message {
		margin: -20px 0 20px;

		@include breakpoint(tablet) {
			margin: -30px -50px 55px;
		}
	}

	.share-payment-method__title {
		@include font(18px, 22px);
		@include margin(bottom, 17px);

		padding-bottom: 7px;
		color: $c-primary-blue;
		text-align: center;
		letter-spacing: 0.02em;
		border-bottom: 1px solid $c-gold;

		@include breakpoint(tablet) {
			@include font(22px, 26px);

			padding-bottom: 14px;
			text-align: left;
		}
	}

	.share-payment-method__form {

		.fieldset--margin-bottom {
			margin-bottom: 40px;
		}
	}