.not-found {
	@include font(18px, 22px);
	margin-top: 40px;
	margin-bottom: 80px;
	text-align: center;

	p {
		@include margin(bottom, 30px);
	}
}

	.not-found__links {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		p {
			@include margin(bottom, 20px);

			width: 100%;
		}

		a {
			margin: 10px;
		}
	}