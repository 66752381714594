.contract-signed {}

	.contract-signed__title {
		@extend %boldWeight;
		text-align: center;
		margin-bottom: 1.6em;

		@include breakpoint(phablet) {
			@include font(18px)
		}

		@include breakpoint(tablet) {
			@include font(20px);
			margin-top: 2em;
		}

		@include breakpoint(notebook) {
			@include font(22px)
		}
	}

	.contract-signed__intro {
		margin: auto;
		max-width: 350px;
		@include font(16px, 19px);

		@include breakpoint(phablet) {
			text-align: center;
		}
	}