.block-faqs {
	max-width: 1340px;
	margin-right: auto;
	margin-left: auto;
	padding: 48px $site-gutter;
	background: var(--block-faqs-bg);


	@include breakpoint(tablet) {
		padding: 134px $site-gutter 110px;
	}

	.wrap {
		max-width: 860px;
	}

	.block-header {
		@include margin(bottom, 32px);
	}

	.collapsible-content {
		@include margin(bottom, 64px);

		@include breakpoint(tablet) {
			@include margin(bottom, 72px);
		}
	}

	.Collapsible {
		@include margin(bottom, 8px);

		background: $c-grey-01;
		border: none;

		@include breakpoint(tablet) {
			@include margin(bottom, 16px);
		}
	}

	.Collapsible__trigger {

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			right: 15px + 5px;
			width: 2px;
			height: 12px;
			background: $c-grey-02;
			border: none;
			transform: translateY(-50%);
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 15px;
			width: 12px;
			height: 2px;
			background: $c-grey-02;
			border: none;
			transform: translateY(-50%);
		}

		&.is-open {

			&:before {
				display: none;
			}
		}
	}
}

	.block-faqs__text {
		@include margin(bottom, 32px);
		@include font(14px, 19px);

		color: $c-grey-06;

		@include breakpoint(tablet) {
			@include margin(bottom, 48px);
			@include font(16px, 22px);

			padding: 0 45px;
			text-align: center;
		}

		p {
			@include margin(bottom, 19px);
		}
	}

	.block-faqs__text--dark {
		color: $c-grey-02;
	}

	.block-faqs__footer {
		display: flex;
		flex-direction: column;
		align-items: center;

		.button {

			@include breakpoint(phablet) {
				max-width: 350px;
			}
		}
	}