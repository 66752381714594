.button {
    @include font(14px, 17px);
    @extend %regularWeight;
    height: 42px;
    padding: 0 40px;
    border-radius: 4px;
    color: var(--button-color);
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-transform: uppercase;
    margin: 0;
    position: relative;
    color: var(--button-color);

    // theme
    background: var(--button-bg);
    border-width: 1px;
    border-style: solid;
    border-color: var(--button-border);

    @include breakpoint(phablet) {
        @include font(16px, 17px);
    }

    &:hover {

    }

    &:disabled {
        opacity: 0.5;
    }

    span {
        flex: 0;
        margin-right: 4px;
    }
}

.button--secondary {
    padding: 0 20px;
    background: transparent;
    border: 1px solid $c-primary-blue;
    color: $c-primary-blue;
    text-decoration: none;
}

.button--tertiary {
    border: 1px solid $c-grey-01;
    color: var(--button-color);
    backdrop-filter: blur(5px);
    background: transparent;

    .is-powered-by & {
        border: none;

        .button__animate-1,
        .button__animate-2 {
            display: none;
        }
    }

    &:hover .button__animate-1 {

        &:before,
        &:after {
            opacity: 1;
            animation: openButtonA $button-speed;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
            animation-direction: normal;
        }
    }

    &:hover .button__animate-2 {

        &:before,
        &:after {
            opacity: 1;
            animation: openButtonB $button-speed;
            animation-delay: $button-speed;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
            animation-direction: normal;
        }
    }
}

    .button__animate-1 {
        position: absolute;
        top: -1px;
        width: 100%;
        height: calc(100% + 2px);
      
        &:before {
            @include button-part;
            @include button-radius(4px, 'left');

            right: 50%;
            border-top: 1px solid $c-gold;
            border-left: 1px solid $c-gold;
        }

        &:after {
            @include button-part;
            @include button-radius(4px, 'right');

            left: 50%;
            border-top: 1px solid $c-gold;
            border-right: 1px solid $c-gold;
        }
    }

    .button__animate-2 {
        position: absolute;
        width: 100%;
        height: calc(100% + 2px);
        
        
        &:before {
            @include button-part;
            @include button-radius(4px, 'right');

            right: 0;
            height: 100%;
            border-bottom: 1px solid $c-gold;
        }

        &:after {
            @include button-part;
            @include button-radius(4px, 'left');

            left: 0;
            height: 100%;
            border-bottom: 1px solid $c-gold;
        }
    }

.button--no-border {
    background: transparent;
    padding: 0 18px 0 8px;
    border: 1px solid transparent;
    color: $c-primary-blue;
    justify-content: flex-start;
}

.button--white {
    padding: 0 35px 0 30px;
    background: $c-grey-01;
    border: none;
    color: $c-grey-dark;
    transition: background-color 200ms;
}

.button--gold-border {
    border: 1px solid $c-gold;
    color: $c-gold;
    background-color: transparent;
}

.button--nav {
    @include font(14px);
    letter-spacing: 0.09em;
    justify-content: flex-start;
    margin-bottom: 2vh;
    padding: 2px 15px 0;

    // theme
    color: var(--button-nav-color);
    background: var(--button-nav-bg);
    border: 1px solid var(--button-nav-border);

    @include breakpoint(phablet) {
        @include font(16px, 17px);
    }

    &:hover {
        color: var(--button-nav-hover-color);
        border-color: var(--button-nav-hover-border);
    }

    .button__icon {
        margin-right: 15px;

        svg {
            stroke: var(--button-nav-icon)
        }
    }
}

.button--active.button--white{
    background: $c-blue-04;
}

.button--active.quote-cta-bar__button {
    color: $c-grey-dark;
    background: $c-blue-04;
}

.button--red {
    background: $c-red;
}

.button--black {
    color: var(--button-color);
    background: $c-black;
}

.button--gold {
    color: $c-grey-dark;
    background: $c-gold;
    text-transform: none;
}

.button--small-text {
    @include font(14px, 17px);
}

.button--light-border {
    border-color: $c-grey-03;
}

.button--remove-background-color {
    @include breakpoint(notebook) {
        background-color: transparent;
    }
}

.button--full {
    width: 100%;
}

    .button__icon {
        position: relative;
        margin-right: 5px;

        img,
        svg {
            height: 23px;

            &:nth-last-child(2) {

                .button:hover & {
                    opacity: 0;
                }
            }

            &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;

                .button:hover & {
                    opacity: 1;
                }
            }
        }
    }

.button--centered {
    margin: auto;
}