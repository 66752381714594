/// Use the floated psuedo element trick to give an element an aspect ratio
///
/// @param {int} $aspect The desired aspect ratio as width/height
/// @author Joe Adcock <joe.adcock@damdigital.com>

@mixin aspect($aspect) {
    overflow: hidden;

    &:before {
        float: left;
        content: "";
        padding-bottom: 100% / $aspect;
    }
}