/// Swiper comes pre-loaded with style. This document overrides that style.
/// Find the package here: https://swiperjs.com/react

.swiper {
	z-index: inherit;
	height: 100%;
}

.swiper-wrapper{
	z-index: inherit;
}

.swiper-slide {
	.swiper__image{
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	* {
		box-sizing: border-box;
	}
}

.swiper-nav-button {
	@extend %hideText;
	background: transparent;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(0, -50%);
	margin: 10px;
	padding: 10px;
}

.swiper-nav-button__next {
	left: auto;
	right: 0;
}

.swiper-pagination-bullet {
	background-color: $c-grey-01;
	opacity: 0.6;
}

.swiper-pagination-bullet-active {
	opacity: 1;
}