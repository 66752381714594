.block-text {
	max-width: 1340px;
	margin-right: auto;
	margin-left: auto;
	padding: 48px $site-gutter 80px;
	background: var(--block-text-bg);

	@include breakpoint(tablet) {
		padding: 64px $site-gutter 110px;
	}

	.wrap {
		max-width: 960px;
	}

	.block-header {
		@include margin(bottom, 24px);
	}
}

	.block-text__item {
		@include margin(bottom, 64px);

		@include breakpoint(tablet) {
			@include margin(bottom, 48px);
		}
	}

		.block-text__text {
			@include margin(bottom, 32px);
			@include font(14px, 19px);
			@extend %lightWeight;

			color: $c-grey-06;

			@include breakpoint(tablet) {
				@include margin(bottom, 48px);
				@include font(16px, 22px);

				text-align: center;
			}

			p {
				@include margin(bottom, 19px);
			}
		}

		.block-text__text--dark {
			color: $c-grey-02;
		}

	.block-text__footer {
		display: flex;
		flex-direction: column;
		align-items: center;

		.button {

			@include breakpoint(phablet) {
				max-width: 350px;
			}
		}
	}