.tab {
	color: $c-grey-03;
	white-space: nowrap;
	display: inline-block;
	padding: 0 10px;
	height: 100%;
	margin: 0 18px;
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
}

.tab--selected {
	@extend %boldWeight;
	color: $c-primary-blue;
	border-bottom: 2px solid $c-primary-blue;
}

// Tab in the title bar
.title-bar {
	.tab {
		@include font(16px, 22px);
		text-transform: uppercase;
		white-space: nowrap;
		@include breakpoint(phablet) {
			@include font(18px, 24px);
		}
		@include breakpoint(tablet) {
			margin: 0 40px;
		}
		@include breakpoint(notebook) {
			@include font(20px, 24px);
		}
	}

	.tab--selected {
		@extend %regularWeight;
		color: $c-primary-blue;
		border-bottom: 2px solid $c-primary-blue;
	}
}