.carrier-terms {
	@include font(14px, 19px);
	@extend %lightWeight;
	width: 100%;
	max-width: 700px;
	margin-top: 40px;

	@include breakpoint(tablet) {
		margin-left: 40px;
		margin-right: 40px;
	}

	h3 {
		@include margin(bottom, 24px);
		text-transform: uppercase;
	}

	p {
		@include margin(bottom, 1em);
		@extend %lightWeight;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	ul,
	ol {
		@extend %lightWeight;
		list-style-type: disc;
		padding: 5px 30px;
		margin: -15px 0 10px;
		@include breakpoint(phablet) {
			padding: 10px 40px;
		}

		ol {
			padding: 5px 10px 0 30px;
			margin: 0;
			@include breakpoint(phablet) {
				padding: 10px 40px;
			}
		}
	}

	&__term {

		h3 {
			@include font(18px, 22px);
			margin: 0;
			padding: 30px 0 20px;
			@extend %mediumWeight;
		}

		&__number {
			@extend %mediumWeight;
			position: relative;
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;
		margin: 0 auto 20px;
	}

	th, td {
		border: 1px solid #ccc;
		padding: 10px;
		text-align: left;
	}

	b, strong {
		@extend %mediumWeight;
	}

	th {
		@extend %boldWeight;
		background-color: $c-grey-05;
		@include breakpoint(phablet) {
			white-space: nowrap;
		}
	}
}