.payment-amount {
	text-align: center;
	padding: 12px;
	border-top: 1px solid $c-gold;
	border-bottom: 1px solid $c-gold;
	margin: 0 auto 30px;
	width: 100%;

	&__instruction {
		@extend %boldWeight;
		margin-bottom: 0.5em;

		@include breakpoint(tablet) {
			@include font(18px)
		}
	}

	&__total {
		@extend %boldWeight;
		@include font(24px, 25px);

		@include breakpoint(tablet) {
			@include font(26px, 30px);
		}
	}

	&__slide-down {
		transition-duration: 300ms;
	}

	&__additional-instruction {
		@extend %lightWeight;
		padding-top: 0.55em;

		&--red {
			color: $c-red;
		}
	}
}