.quote {
	// theme
	background: var(--quote-bg);
}

	// Filters and compare popup content
	.quote__filter-and-compare-content {
		position: sticky;
		top: 157px;
		width: 100%;
		z-index: 1;

		@include breakpoint(phablet) {
			position: relative;
			top: auto;
		}
	}

	// Tray to confirm approved selection
	.quote__confirm-approve {
		background-color: $c-grey-01;
		position: fixed;
		top: 100vh;
		left: 0;
		right: 0;
		z-index: 1;
		box-shadow: 0px -6px 4px rgba(27, 38, 70, 0.1);
		transition: transform 300ms, opacity 250ms;

		&--active,
		&--transition-in {
			transform: translate(0, -100%);
		}

		&__inner {
			padding: 18px;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;

			&__title {
				@include font(16px);
				margin-bottom: 18px;
			}

			&__buttons-wrapper {
				display: flex;
				flex-direction: row;
			}

			&__close-cross {
				position: absolute;
				top: 10px;
				right: 10px;
			}
		}
	}

	// Aircraft grid
	.quote__aircraft-grid {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		transition: margin-top 600ms;

		@include breakpoint(phablet) {
			margin-top: 0!important; //Overwrite the margin-top as we go from mobile to phablet
			.aircraft-card{
				margin-bottom: 15px;
			}
		}

		@include breakpoint(tablet) {
			margin-right: -7.5px;
			margin-left: -7.5px;

			.aircraft-card{
				width: 50%;
			}

				.aircraft-card__content {
					margin: 0 7.5px;
				}
		}

		@include breakpoint(notebook) {

			.aircraft-card{
				width: 33.3333%;
			}
		}
	}

		.quote__no-results {
			@extend %regularWeight;
			@include font(18px);
			margin: 4vh auto;
			border-radius: 6px;
			text-align: center;
			padding: 15px 40px;
			background-color: rgba(255, 255, 255, 0.15);

			// theme
			color: var(--quote-no-results-color);
		}

	.quote__header-message {
		background-color: $c-blue-04;
		color: $c-grey-dark;
		padding: 18px 30px;
		text-align: center;

		@include breakpoint(tablet) {
			width: 68vw;
			max-width: 700px;
			padding: 18px 0;
			border-radius: 6px;
			margin: auto;
		}
	}