.passenger-details-form {
	margin-top: 20px;
	min-height: calc(100vh - 200px) ;
	position: relative;
}

	.passenger-details-form__title {
		@include font(18px);
		@extend %boldWeight;
		text-align: center;

		@include breakpoint(tablet) {
			display: none;
		}
	}

	.passenger-details-form__title--tablet {
		@include font(22px);
		@extend %mediumWeight;
		display: none;
		margin-bottom: 32px;

		@include breakpoint(tablet) {
			display: block;
		}
	}

	.passenger-details-form__instruction {
		@include font(16px, 20px);
		color: $c-grey-02;
		text-align: center;
		width: 70%;
		margin: 0 auto 30px;
	}

	.passenger-details-form__doc-upload-wrapper {
		max-width: 400px;
		margin: auto;
	}

	.passenger-details-form__select-passenger-wrapper {
		max-width: 500px;
		margin: auto;
	}

	.passenger-details-form__or {
		color: $c-grey-dark;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		max-width: 400px;
		margin: 0 auto 30px;

		span {
			height: 0.5px;
			background-color: $c-grey-dark;
			flex: 1;
			width: 100%;
			display: block;
			margin-top: 2px;
		}

		span:first-of-type {
			margin-right: 14px;
		}

		span:last-of-type {
			margin-left: 14px;
		}
	}

	.passenger-details-form__shared-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

		.passenger-details-form__column {
			width: calc(50% - 10px)
		}

	.passenger-details-form__text {
		@include font(16px, 19px);
		
		color: $c-grey-02;

		a {
			text-decoration: underline;
		}
	}

	.passenger-details-form__inner-container {
		max-width: 444px;
		margin: 0 auto;

		&--wide {
			max-width: 700px;
		}
	}

	.passenger-details-form__data-list {
		display: flex;
		flex-wrap: wrap;

		@include breakpoint(tablet) {
			margin-bottom: 8px;
		}

		dt {
			@include font(16px, 18px);

			width: 150px;
			margin-bottom: 24px;
			padding-right: 17px;

			@include breakpoint(tablet) {
				@include font(16px, 19px);

				width: 50%;
				padding-right: 30px;
			}
		}

		dd {
			@include font(16px, 19px);
			@extend %boldWeight;

			width: calc(100% - (150px));
			margin-bottom: 24px;

			@include breakpoint(tablet) {
				width: 50%;
			}
		}
	}

	.passenger-details-form__flex-container {
		margin-top: 32px;
		padding-top: 32px;
		border-top: 1px solid $c-grey-dark;

		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
			grid-column: 1/3;
			margin-top: 0;
			margin-right: -30px;
			padding-top: 0;
			border-top: none;
		}

		> div {
			@include breakpoint(tablet) {
				padding-right: 30px;
			}
		}
	}

		.passenger-details-form__flex-container-intro {
			@include font(16px, 22px);
			@include margin(bottom, 24px);

			color: $c-grey-02;
			
			@include breakpoint(tablet) {
				@include font(16px, 19px);
				
				width: 100%;
			}

			h3 {
				@include font(16px, 19px);
				@include margin(bottom, 13px);
				@extend %mediumWeight;

				@include breakpoint(tablet) {
					@include margin(bottom, 8px);
				}
			}

			span {
				@include font(16px, 22px);
				@extend %lightWeight;
			
				@include breakpoint(tablet) {
					@include font(16px, 19px);
				}
			}
		}

		.passenger-details-form__flex-container-column {

			@include breakpoint(tablet) {
				width: 50%;
			}
		}

	.passenger-details-form__checklist {
		margin-top: 32px;
		color: $c-grey-dark;

		@include breakpoint(tablet) {
			grid-column: 1/3;
		}

		p {
			@include font(16px, 21px);
			@include margin(bottom, 18px);

			@include breakpoint(tablet) {
				@include font(16px, 19px);
				@include margin(bottom, 27px);
			}
		}

		ul {
			@include font(16px, 19px);

			margin: 0;
			list-style: none;
		}

		li {
			@include margin(bottom, 34px);

			position: relative;
			padding: 0 0 0 (22px + 6px);
		}

		img {
			position: absolute;
			top: -4px;
			left: 0;
		}

		h4 {
			@include margin(bottom, 8px);
			@extend %mediumWeight;

			color: $c-gold;

			@include breakpoint(tablet) {
				@include font(18px, 22px);
			}
		}
	}

		.passenger-details-form__checklist-container {
			max-width: 450px;
			margin: -15px auto 30px;
		}

		.passenger-details-form__visa-row {
			display: flex;
			width: 100%;
		}