.page {
	position: relative;
	background-color: white;
	padding-top: 30px;
	padding-bottom: 50px;
	flex: 1;
}

.page--flush {
	padding: 0;
}

.page--flex {
	display: flex;
	flex-direction: column;
}