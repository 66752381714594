.number-input {
	display: flex;
	flex-direction: row;
	align-items: center;

	&__wrapper {
		background-color: rgba($c-grey-01, .1);
		border: solid 1px $c-grey-02;
		height: 70px;
		width: 90px;
		position: relative;
	}

	&__input {
		@include font(18px);
		@extend %mediumWeight;
		border: none;
		padding-left: 8px;
		padding-right: 8px;
		color: $c-grey-01;
		height: 100%;
		width: 100%;
		position: absolute;
		letter-spacing: 0.02em;
		left: 0;
		top: 0;
		background-color: transparent;

		&:focus {
			outline: 1px solid rgba($c-grey-03, 0.5);
		}
	}

	&__increment {
		@extend %hideText;
		position: absolute;
		top: 3px;
		right: 6px;
	}

	&__decrement {
		@extend %hideText;
		position: absolute;
		bottom: 3px;
		right: 6px;
	}

	&__label {
		@include font(18px);
		display: block;
		margin-left: 7px;
		color: $c-grey-04;
	}
}