.aircraft-browser-results {
	@include margin(bottom, 10px);

	width: 100%;
}

	.aircraft-browser-results__text {
		@include font(16px, 22px);
		@include margin(bottom, 24px);

		padding: 0 32px;

		// theme
		color: var(--aircraft-browser-results-color);

		@include breakpoint(notebook) {
			@include font-weight(medium);
			@include margin(bottom, 48px);

			max-width: 473px + (32px * 2);
			margin-right: auto;
			margin-left: auto;
			text-align: center;
		}
	}

	.aircraft-browser-results__swiper-container {
		opacity: 0;

		@include breakpoint(notebook) {
			position: relative;
			max-width: 1200px;
			margin: 0 auto;
			padding: 0 (44px + 52px + 24px);
		}

		.aircraft-browser-results--active & {
			opacity: 1;
			transition: opacity 1s ease;
		}
	}

		.aircraft-browser-results__swiper-nav {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			top: 50%;
			width: 52px;
			height: 62px;
			cursor: pointer;
			transform: translateY(-50%);
			opacity: 0.5;
			transition: opacity .3s ease;

			display: none;

			// theme
			background: var(--swiper-button-bg);

			@include breakpoint(notebook) {
				display: flex;
			}

			&:hover,
			&:active {
				opacity: 1;
			}

			svg {
				stroke: var(--swiper-button-color);
			}
		}

		.aircraft-browser-results__swiper-nav--previous {
			left: 44px;
		}

		.aircraft-browser-results__swiper-nav--next {
			right: 44px;
		}

		.aircraft-browser-results__swiper {		
			max-width: 100vw;

			@include breakpoint(notebook) {
				padding-bottom: 30px + 16px;
			}

			.swiper-pagination {
				bottom: 0;
			}

			.swiper-pagination-horizontal {

				.swiper-pagination-bullet {
					margin: 0 20px;
				}
			}

				.swiper-pagination-bullet {
					width: 12px;
					height: 12px;

					// theme
					background: var(--swiper-pagination-bullet-bg);
				}

				.swiper-pagination-bullet-active {

					// theme
					background: var(--swiper-pagination-bullet-active-bg);
				}
		}

			.aircraft-browser-results__item {

				@include breakpoint(notebook) {
					display: flex;
					align-items: flex-start;
				}
			}

				.aircraft-browser-results__images {

					@include breakpoint(notebook) {
						flex: 0 0 73%;
						display: flex;
						align-items: center;
						margin: 0 -5px;
					}
				}

					.aircraft-browser-results__image {
						@include aspect(340 / 230);

						position: relative;
						background: rgba($c-gold, 0.1);

						@include breakpoint(notebook) {
							margin: 0 5px;
							width: calc(50% - 10px);
						}

						&[data-loading] {
							border: 1px solid $c-gold;
						}

						&:after {
							content: '';
							position: absolute;
							right: 0;
							bottom: 0;
							left: 0;
							height: 38%;
							background: linear-gradient(0deg, rgba($c-grey-dark, 0.8) 0%, rgba($c-grey-dark, 0.24) 43.1%, rgba($c-grey-01, 0) 96.21%);
							mix-blend-mode: multiply;
							z-index: 1;

							@include breakpoint(notebook) {
								display: none;
							}
						}

						img {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: cover;
							z-index: 1;

							opacity: 0;
							transition: opacity .3s ease;
						}

						.loading {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}

				.aircraft-browser-results__image-swiper {

					.swiper-button-prev,
					.swiper-button-next {
						width: 40px;
						height: 48px;
						margin-top: -24px;
						opacity: 0.4;

						// theme
						background: var(--swiper-button-bg);

						&:hover,
						&:active {
							opacity: 1;
						}

						&.swiper-button-disabled {
							opacity: 0.1;
							pointer-events: none;
						}

						&:after {
							@include font(10px);

							// theme
							color: var(--swiper-button-color);
						}
					}

					.swiper-button-prev {
						left: 0;
					}

					.swiper-button-next {
						right: 0;
					}

					.swiper-pagination {
						@include font(16px, 22px);
						@extend %lightWeight;

						position: absolute;
						display: flex;
						top: 10px;
						right: 10px;
						left: auto;
						bottom: auto;
						width: auto;
						padding: 2px 16px;
						border-radius: 2px;

						// theme
						color: var(--swiper-pagination-color);
						background: var(--swiper-pagination-bg);
					}
				}

				.aircraft-browser-results__content {
					padding: 24px 32px (48px + 16px);

					@include breakpoint(notebook) {
						flex: 0 0 27%;
						padding: 24px 14px;
					}
				}

					.aircraft-browser-results__title {
						@include font(18px, 22px);
						@include margin(bottom, 16px);
						@extend %boldWeight;

						margin-left: 20px;

						// theme
						color: var(--aircraft-browser-results-title-color);
					}

					.aircraft-browser-results__list {
						@include font(16px, 19px);

						margin-left: 0;
						padding-left: 20px;
						list-style: outside;

						// theme
						color: var(--aircraft-browser-results-list-color);
					}

	.aircraft-browser-results__placeholder-container {
		padding-bottom: 24px + 64px + 119px;

		@include breakpoint(notebook) {
			position: relative;
			max-width: 1200px;
			margin: 0 auto;
			padding: 0 (44px + 52px + 24px) 46px;
		}

		.loading {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

		.aircraft-browser-results__placeholder-outer {

			@include breakpoint(notebook) {
				width: 73%;
				margin: 0 -5px;
			}
		}

			.aircraft-browser-results__placeholder-inner {
				@include aspect(340 / 230);

				position: relative;

				@include breakpoint(notebook) {
					margin: 0 5px;
					width: calc(50% - 10px);
				}
			}