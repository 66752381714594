.contact-preferences-input {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 25px;

	@include breakpoint(tablet) {
		margin-top: 0;
	}

	&__label {
		@extend %lightWeight;
		margin-bottom: 15px;
		grid-column: span 2;
	}

	.checkbox {
		margin: 0 0 5px;

		@include breakpoint(tablet) {
			margin: 0 0 6px 5px;
		}
	}
}