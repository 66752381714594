.form {
	display: grid;
	grid-template-columns: 100%;
	max-width: 850px;
	margin: auto;

	@include breakpoint(tablet) {
		grid-template-columns: 1fr 1fr;
		column-gap: 30px;
	}
}

.form--full {
	display: block;
}

.form--max-350 {
	display: block;
	max-width: 350px;
	margin: auto;
}