/// The Select input comes pre-loaded with style. This document overrides that style.
/// Find the package here: https://react-select.com

.select-input {
	display: flex;
	justify-content: stretch;
	align-items: stretch;
	position: relative;

	&--disabled {
		opacity: 0.7;
	}

	.select-input__control {
		cursor: pointer;
		max-width: 500px;
		margin: auto;
		border-radius: 0;
		border: none;
		min-height: 100%;
		text-align: left;
		box-shadow: none;
		padding-left: 12px;
		padding-right: 6px;
	}

	.select-input__placeholder,
	.select-input__single-value {
		@include font(16px, 17px);
		color: $c-grey-dark;
		white-space: nowrap;
	}

	.select-input__control--menu-is-open {
		background-color: $c-grey-01;
	}

	.select-input__indicator-separator {
		display: none;
	}

	.select-input__value-container {
		padding: 0;
		margin: 8px;
	}

	.select-input__single-value,
	.select-input__placeholder {
		margin: 0;
	}

	.select-input__dropdown-indicator {
		padding-left: 3px;
	}

	.select-input__menu {
		box-shadow: none;
		border-radius: 0;
		transform: translate(-50%);
		margin-top: 0;
		left: 50%;
		max-width: 500px;
		text-align: left;
		z-index: 9999;
	}

	.select-input__menu-list {
		padding-top: 0;
	}

	.select-input__option {
		padding-left: 23px;
		padding-right: 6px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		min-height: 38px;

		&:active {
			background-color: $c-primary-blue;
			color: $c-white;
		}
	}

	.select-input__option--is-focused {
		background-color: $c-grey-04;
	}

	.select-input__option--is-selected {
		cursor: default;
		background-color: rgba($c-primary-blue, .5);
	}
}

// Select style for filter
.select-input--filter {
	.select-input__control {
		height: 42px;
	}
}

// Style when in a form
.select-input--form {
	margin-bottom: 30px;
	color: $c-primary-blue;

	.select-input__control,
	.select-input__control:hover {
		border: solid 1px $c-grey-03;
		padding-left: 0;
		padding-right: 5px;
		min-width: 120px;
		height: 45px;
		background-color: transparent;
	}

	.select-input__control--is-disabled {
		background-color: $c-grey-05;
	}

	.select-input__control--is-focused:hover,
	.select-input__control--is-focused {
		border: solid 1px $c-grey-03;
		outline: 0.75px solid $c-link-blue!important;
	}

	.select-input__control--menu-is-open,
	.select-input__control--menu-is-open:hover{
		border: solid $c-primary-blue 1px;
		outline: none!important;
	}

	.select-input__placeholder,
	.select-input__single-value {
		@extend %lightWeight;
		@include font(16px);
		color: $c-primary-blue;
		padding-top: 13px;
	}

	.select-input__single-value--is-disabled {
		color: $c-grey-02;
	}

	.select-input__dropdown-indicator {
		padding-left: 10px;
	}

	.select-input__menu-list {
		border-bottom: 1px rgba($c-grey-03, 0.5) solid;
		border-left: 1px rgba($c-grey-03, 0.5) solid;
		border-right: 1px rgba($c-grey-03, 0.5) solid;
		box-shadow: 4px 1px 30px rgba($c-grey-02, 0.1);
	}

	.select-input__option{
		@extend %lightWeight;
		@include font(16px);
		padding-left: 11px;
		padding-right: 6px;
		cursor: pointer;
		color: $c-black;
		background: $c-white;
	}

	.select-input__option--is-focused {
		background: $c-dropdown-focus;
	}

	.select-input__option--is-selected {
		color: $c-white;
		background: $c-primary-blue;
	}

	.select-input__menu {
		background-color: $c-grey-04;
	}

	@include breakpoint(tablet) {
		@include selectInputFullWidth();
	}
}

// Flight enquiry
.select-input--flight-enquiry {
	background-color: $c-pending;
	background-size: 24px;
	background-repeat: no-repeat;
	background-position: 10px center;
	border-radius: 4px;

	.select-input__control {
		height: 42px;
		background-color: transparent;
		color: $c-primary-blue;
		padding-left: 35px;

		@include breakpoint(tablet) {
			font-weight: 700;
		}
	}

	.select-input__control--is-focused:hover,
	.select-input__control--is-focused {
		border-radius: 4px;
		box-shadow: 0 0 0 1px $c-grey-02, 0 0 0 2px $c-white;
	}

	.select-input__control--menu-is-open,
	.select-input__control--menu-is-open:hover{
		box-shadow: none;
	}

	.select-input__option {
		color: $c-black;
		background: $c-white;
		padding-left: 11px;
		padding-right: 6px;
	}

	.select-input__option--is-focused {
		background: $c-dropdown-focus;
	}

	.select-input__option--is-selected {
		color: $c-white;
		background: $c-primary-blue;
	}

	.select-input__indicator {
		position: absolute;
		right: 5px;
	}
}

//Error
.select-input--flight-enquiry--error {
	outline: solid 2px $c-error-red;
	outline-offset: -2px;

	.select-input__control {
		&:hover {
			outline: solid 2px $c-error-red;
		}
	}

	.select-input__placeholder {
		color: $c-error-red;
	}
}

// From / To input
.select-input--from-to {
	.select-input__indicators {
		display: none;
	}

	.select-input__control--is-focused {
		.select-input__placeholder {
			display: none;
		}
	}
}

// Full width
.select-input--full {
	@include selectInputFullWidth();
}