.profile-panel-transition {
    .profile-panel {
        visibility: visible;
        transform: translate(0%);
    }

    .profile-panel-overlay {
        visibility: visible;

        // theme
        opacity: var(--nav-overlay-opacity);
    }
}

.profile-panel-overlay {
    @include inset(0, 0, 0, 0);
    background-color: black;
    position: fixed;
    z-index: 9999;
    opacity: 0;
    transition: opacity 500ms;
}

.profile-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    transform: translate(101%);
    transition: transform 500ms;

    // theme
    color: var(--nav-color);
    background: var(--nav-bg);

    @include breakpoint(phablet) {
        max-width: 450px;
    }

    @include breakpoint(notebook) {
        max-width: 430px;
    }

    .styleguide__item > & {
        position: static;
    }
}
    .profile-panel__scroll {
        @include inset(0, 0, 0, 0);
        position: absolute;
        @extend %scrollVertical;
    }

        .profile-panel__inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding: 4vh 32px;
            min-height: 550px;
            * {
                flex-shrink: 0;
            }
        }

            .profile-panel__inner__close-cross-container {
                position: fixed;
                top: 16px;
                right: 16px;
            }

            .profile-panel__inner__picture {
                height: 14vh;
                width: 14vh;
                max-height: 120px;
                max-width: 120px;
                min-height: 90px;
                min-width: 90px;
                border-radius: 100%;
                position: relative;
                background-color: $c-grey-04;
                margin: 0 auto 20px;

                @include breakpoint(tablet) {
                    margin: 0 auto 40px;
                }
            }

                .profile-panel__inner__picture__text {
                    @include font(24px);
                    text-transform: uppercase;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -48%);

                    // theme
                    color: var(--nav-bg)
                }

                .profile-panel__inner__picture__icon {
                    position: absolute;
                    bottom: 3%;
                    right: 3%;
                    height: 25px;
                    width: 25px;
                    background-color: $c-primary-blue;
                    border: 1px solid $c-grey-04;
                    border-radius: 100%;
                    display: flex;

                    img {
                        margin: auto;
                        padding-left: 1px;
                        height: 77%;
                    }
                }

            .profile-panel__inner__welcome {
                @include font(22px, 30px);
                margin-top: 2vh;
                margin-bottom: 0.5em;
            }

            .profile-panel__inner__buttons {
                margin-top: 4vh;
                margin-bottom: auto;
                width: 100%;
            }

.profile-panel--transition-in {
    animation: fadeIn forwards $profile-panel-speed;
}

.profile-panel--transition-out {
    animation: fadeOut forwards $profile-panel-speed;
}