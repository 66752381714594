.quote-cta-bar {
	position: sticky;
	top: 115px;

	// theme
	background: var(--quote-cta-bar-bg);

	@include breakpoint(phablet) {
		position: relative;
		top: auto;
		background: transparent;
	}

	// Style for react-select border radius
	.select-input {
		.select-input__control {
			width: 33.333vw;
			@include breakpoint(phablet) {
				width: auto;
				border-radius: 0 4px 4px 0;
			}
		}
	}

	fieldset {
		width: auto;
		margin: 0;
	}
}

	.quote-cta-bar__padding {
		display: flex;
		width: 100%;
		justify-content: stretch;

		@include breakpoint(phablet) {
			justify-content: space-between;
		}
	}

	.quote-cta-bar__button {
		@include font(16px);
		width: 100%;
		border-radius: 0;
		padding: 0;

		@include breakpoint(mobile) {
			padding: .5rem;
		}

		@include breakpoint(phablet) {
			padding: 0 35px 0 30px;
			min-width: auto;
			flex: 0;
			border-radius: 4px;
		}

		&--left-aligned {
			justify-content: flex-start;
			padding-left: 6px;
			@include breakpoint(phablet) {
				padding: 0 35px 0 30px;
				justify-content: center;
			}
		}

		&--filter {
			@media (min-width: 565px) {
				margin-right: auto;
				margin-left: 1rem;
			}
		}
	}

	.quote-cta-bar__divide {
		width: 1px;
		height: 42px;
		position: relative;
		&:before {
			position: absolute;
			z-index: 1;
			content: '';
			height: 20px;
			width: 1px;
			top: 12px;

			// theme
			background: var(--quote-cta-bar-divide-bg);
		}

		@include breakpoint(phablet) {
			display: none;
		}
	}