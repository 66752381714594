.share-payment {

	@include breakpoint(tablet) {
		display: flex;
		justify-content: center;
		padding: 40px 0 100px;
	}
}

.share-payment--success {
	padding-top: 15px;

	@include breakpoint(tablet) {
		padding: 30px 0 40px;
	}
}