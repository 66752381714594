.switch {
	height: 24px;
	width: 42px;
	border-radius: 36px;
	background: $c-pending;
	position: relative;
	transition: background-color 200ms;

	&__knob {
		display: block;
		border-radius: 100%;
		position: absolute;
		background-color: $c-grey-01;
		border: solid 0.5px $c-blue-04;
		top: 2px;
		left: 2px;
		height: 20px;
		width: 20px;
		box-shadow: 3px 0 2px rgba(0, 0, 0, 0.06);
		transition: left 200ms;
	}
}

.switch--on {
	background-color: $c-success-green;

	.switch__knob {
		left: 20px;
	}
}

.switch--large {
	width: 56px;
	height: 32px;

	&.switch--on {

		.switch__knob {
			left: 26px;
		}
	}

	.switch__knob {
		width: 28px;
		height: 28px;
	}
}