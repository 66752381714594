.page-listing {
	margin: 40px;
}

	h1 {
		@include font(26px, 30px);
		margin-bottom: 30px;
	}

	ul {
		list-style: disc;
		margin-left: 50px;
	}

		li {
			padding-top: 4px;
			padding-bottom: 4px;
		}