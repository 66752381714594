.block-aircraft {
	padding: 70px 0;

	@include breakpoint(tablet) {
		padding: 64px $site-gutter 124px;
	}

	.wrap {
		max-width: 1096px;
	}

	.block-header {
		@include margin(bottom, 32px);
	}
}

	.block-aircraft__content {
		@include margin(bottom, 40px);

		position: relative;
		margin-right: -$site-gutter;
		margin-left: -$site-gutter;

		@include breakpoint(tablet) {
			margin-right: -8px;
			margin-left: -8px;
		}
	}

		.block-aircraft__button {
			display: none;

			@include breakpoint(tablet) {
				position: absolute;
				top: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 52px;
				height: 62px;
				color: $c-gold;
				background: rgba($c-grey-dark, 0.5);
				z-index: 1;
				transition: all 0.3s ease;
			}

			&.swiper-button-disabled {
				opacity: 0.1;
				pointer-events: none;
			}

			&:hover {
				background: rgba($c-grey-dark, 0.8);
			}

			img {
				height: 14px;
			}
		}

		.block-aircraft__button--prev {

			@include breakpoint(tablet) {
				left: 8px - 26px;
			}

			@include breakpoint(laptop) {
				left: 8px - 24px - 52px;
			}
		}

		.block-aircraft__button--next {

			@include breakpoint(tablet) {
				right: 8px - 26px;
			}

			@include breakpoint(laptop) {
				right: 8px - 24px - 52px;
			}
		}

		.block-aircraft__swiper {
			padding-bottom: 16px;

			.swiper-slide {
				display: flex;
				width: 311px + 16px;
				height: auto;
				padding: 0 8px;

				@include breakpoint(tablet) {
					width: 247px + 16px;
				}

				&:first-child {
					margin-left: 16px;

					@include breakpoint(tablet) {
						margin-left: 0;
					}
				}
			}
		}

		.block-aircraft__item {
			background: rgba($c-black, 0.2);
			color: $c-grey-01;
			background-blend-mode: multiply;
			border-radius: 4px;
			overflow: hidden;
		}

			.block-aircraft__item-image {
				@include aspect(311 / 318);

				position: relative;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.block-aircraft__item-content {
				padding: 16px;
			}

				.block-aircraft__item-title {
					@include font(18px, 31px);
					@extend %boldWeight;
				}

				.block-aircraft__item-subtitle {
					@include font(14px, 17px);
					@extend %mediumWeight;
					@include margin(bottom, 12px);
				}

				.block-aircraft__item-text {
					@include font(14px, 19px);

					color: $c-grey-06;
				}

	.block-aircraft__indicator {
		display: flex;
		justify-content: center;
		align-items: center;
	}

		.block-aircraft__indicator-content {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 16px;

			.block-aircraft__indicator--next-active &,
			.block-aircraft__indicator--prev-active & {
				animation-duration: .6s;
			}

			.block-aircraft__indicator--next-active & {
				animation-name: carouselIndicatorNext;
			}

			.block-aircraft__indicator--prev-active & {
				animation-name: carouselIndicatorPrev;
			}

			span {
				width: 6px;
				height: 6px;
				margin: 0 6px;
				border-radius: 100%;
				background: $c-gold;

				.block-aircraft__indicator--next-active &,
				.block-aircraft__indicator--prev-active & {
					animation-duration: .6s;
				}

				&:nth-child(1) {
					opacity: 0;

					.block-aircraft__indicator--prev-active & {
						animation-name: carouselIndicatorPrevItem1;
					}
				}

				&:nth-child(2) {

					.block-aircraft__indicator--next-active & {
						animation-name: carouselIndicatorNextItem2;
					}

					.block-aircraft__indicator--prev-active & {
						animation-name: carouselIndicatorPrevItem2;
					}
				}

				&:nth-child(3) {
					transform: scale(1.666666);

					.block-aircraft__indicator--next-active & {
						animation-name: carouselIndicatorNextItem3;
					}

					.block-aircraft__indicator--prev-active & {
						animation-name: carouselIndicatorPrevItem3;
					}
				}

				&:nth-child(4) {
					margin: 0 10px;
					transform: scale(2.666666);

					.block-aircraft__indicator--next-active & {
						animation-name: carouselIndicatorNextItem4;
					}

					.block-aircraft__indicator--prev-active & {
						animation-name: carouselIndicatorPrevItem4;
					}
				}

				&:nth-child(5) {
					transform: scale(1.666666);

					.block-aircraft__indicator--next-active & {
						animation-name: carouselIndicatorNextItem5;
					}

					.block-aircraft__indicator--prev-active & {
						animation-name: carouselIndicatorPrevItem5;
					}
				}

				&:nth-child(6) {

					.block-aircraft__indicator--next-active & {
						animation-name: carouselIndicatorNextItem6;
					}

					.block-aircraft__indicator--prev-active & {
						animation-name: carouselIndicatorPrevItem6;
					}
				}

				&:nth-child(7) {
					opacity: 0;

					.block-aircraft__indicator--next-active & {
						animation-name: carouselIndicatorNextItem7;
					}
				}
			}
		}

	.block-aircraft__footer {
		display: flex;
		flex-direction: column;
		align-items: center;

		.button {

			@include breakpoint(phablet) {
				max-width: 350px;
			}
		}
	}