.use-credit-to-pay {
	position: relative;

	&__credit-amount {
		text-align: center;
		margin-bottom: 75px;

		&--partial-pay {
			@include font(16px, 22px);
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 330px;
			max-width: calc(100vw - 64px);
			margin: 0 auto 75px;
		}
	}

	&__link {
		display: inline-block;
		color: $c-gold;
		border-bottom: 1px solid $c-gold;
		margin-top: 10px;
	}
}