.tab-bar {
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: $c-grey-01;
	box-shadow: 0 2px 4px rgba($c-grey-02, 0.16);
	position: relative;

	a {
		height: 100%;
	}
}