.loading-overlay {
	@include inset(0, 0, 0, 0);
	position: absolute;
	background-color: rgba($c-grey-01, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include breakpoint(notebook) {
		position: absolute;
	}
}

.loading-overlay--clear {
	background: transparent;
}