.newsletter-form {
	@include font(16px, 20px);
	@extend %lightWeight;

	padding: 100px 0;
	color: $c-grey-01;
	background: rgba($c-black, 0.3);
}

	.newsletter-form__container {

		> div:first-child {
			@extend .wrap;

			@include breakpoint(tablet) {
				max-width: 630px + ($site-gutter--tablet * 2);
			}
		}

			#SIGNUP_HEADING {
				@include font(24px, 28px);
				@extend %lightWeight;

				text-transform: uppercase;
				letter-spacing: 0.05em;
				text-align: center;
				max-width: 800px;
				margin: 0 auto 1em;
				color: $c-grey-01;

				@include breakpoint(phablet) {
					@include font(26px, 32px);
				}

				@include breakpoint(tablet) {
					@include font(28px, 34px);
				}

				@include breakpoint(laptop) {
					@include font(30px, 36px);
				}
			}

			#Zc_SignupSuccess {
				display: none;
			}

			#SIGNUP_DESCRIPTION {
		        @include margin(bottom, 30px);

		        text-align: center;
			}

			#errorMsgDiv {
				@include margin(bottom, 10px);

				opacity: 0;
				text-align: center;
			}

			.zcsffieldsdiv {
				@include margin(bottom, 10px);

				display: flex;
				flex-wrap: wrap;
				margin-right: -6px;
				margin-left: -6px;

				> div {
					width: 100%;
					padding: 0 6px;

					@include breakpoint(phablet) {
						width: 50%;
					}

					&:last-child {
						width: 100%;
					}
				}
			}

				div[name=SIGNUP_FORM_LABEL] {
					display: none;
				}

				.zcinputbox {

					input {
						@extend %lightWeight;
						@include font(16px);

						padding-left: 8px;
						padding-right: 8px;
						color: $c-primary-blue;
						width: 100%;
						height: 45px;
						border: solid 1px $c-grey-01;
						border-radius: 4px;

						&:focus {
							border: 1px solid $c-primary-blue;
							outline: none;
						}
					}

					span {
						display: none;
					}
				}

			.recaptcha {
				@include margin(bottom, 30px);
			}

				#captchaParent {
					position: relative;
					display: flex;
					flex-direction: column;
					width: 200px;
					margin: 0 auto;
				}

					#relCaptcha {
						position: absolute;
						bottom: 22px;
						left: 0;
						transform: translateY(50%);
					}

					#captchaText {
						@extend %lightWeight;
						@include font(16px);

						align-self: flex-end;
						padding-left: 8px;
						padding-right: 8px;
						color: $c-primary-blue;
						width: 176px - 10px;
						height: 45px;
						border: solid 1px $c-grey-01;
						border-radius: 4px;

						&:focus {
							border: 1px solid $c-primary-blue;
							outline: none;
						}
					}

					#capRequired {
						display: none;
					}

			#selectMailingLists {
				@include margin(bottom, 30px);

				text-align: center;

				@include breakpoint(tablet) {
					@include margin(bottom, 70px);
				}


				> div {
					@include margin(bottom, 10px);
				}

				.txtnormal {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 10px;
					cursor: pointer;

					@include breakpoint(tablet) {
						margin-bottom: 0;
					}

					input {
						display: none;
					}

					input:checked + span {

						img {
							display: block;
						}
					}

					span {
						height: 18px;
						width: 18px;
						margin-right: 10px;
						border: solid $c-grey-03 1px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						@include breakpoint(tablet) {
							height: 25px;
							width: 25px;
						}

						img {
							display: none;
							width: 65%;
							height: 65%;

							@include breakpoint(tablet) {
								width: 60%;
								height: 60%;
							}
						}
					}
				}
			}

				#listRelTable {

					tbody {
					
						@include breakpoint(tablet) {
							display: flex;
							justify-content: center;
						}
					}

						tr {

							@include breakpoint(tablet) {
								display: block;
								width: 50%;
							}
						}

						td {

							@include breakpoint(tablet) {
								display: block;
								width: 100%;
							}
						}
				}

			#REQUIRED_FIELD_TEXT {
				display: none;
			}

			#zcWebOptin {
				@extend .button;
				@extend .button--tertiary;
				@extend .button--full;

				@include breakpoint(phablet) {
					max-width: 350px;
					margin: 0 auto;
				}
			}

			#privacyNotes {
				margin-top: 15px;
				text-align: center;
			}

		#zcOptinSuccessPopup {
			display: none;
		}
	}