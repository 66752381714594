// pdf-view can be added to the body element and used to overwrite the page's default styles.
// This way you can style the page to be PDF friendly

.pdf-view {
	background-color: $c-white;

	// Header
	.header {
		width: 100%;
		margin: auto;
		height: 130px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 9997;
	
		// background: rgba($c-black, 0.20);
		// background-blend-mode: multiply;
		box-shadow: 0px 0px 4px 0px rgba(112, 112, 112, 0.25);

	    // theme
	    background: var(--header-bg);
	
		@include breakpoint(phablet) {
			position: relative;
		}
	
		@include breakpoint(phablet){
			//height: 80px;
		}

		.profile-button {
			display: none;
		}
	}

		
.header--middle {
    .header__logo {
        position: absolute;
        transform: translate(-50%);
        left: 50%;
        top: 12px;
    	height: 40px;

        @include breakpoint(phablet) {
            height: 100px;
        }
    }
}

.header__logo {
	top: 12px;
    height: 40px;

	@include breakpoint(phablet) {
		height: 100px;
	}

	&--on-top {
		z-index: 9999
	}
}

.header__wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: $header-height;

	@include breakpoint(phablet) {
		height: $header-height--phablet;
	}
}

.header__toggle {
	display:none;
}

.chat-widget {
	display: none;
}

	// Footer
	footer {
		display: none;
	}

	// Background
	.background {
		video {
			display: none;
		}

		img {
			display: none;
		}

		.background__cover {
			display: none;
		}
	}

	main {
		background-color: transparent;
		padding-top: 0;
	}

	.flight-brief__itinerary-wrapper {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}

	.flight-brief__itinerary-content {
		width: 60%;
		padding-left: 50px;
	}

	// Cookie banner
	.cky-consent-container {
		display: none;
	}
}

// Hide header
.pdf-view--hide-header {
	.header {
		display: none;
	}
}