.loading {
    display: block;
    width: var(--loading-width);
    height: var(--loading-height);
    margin: auto;
    transform: translate(-50%, -50%);
    position: fixed;
    top: 50%;
    left: 50%;

    @include breakpoint(tablet) {
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0, 0);
    }

    svg {
        fill: var(--loading-icon)
    }
}

.loading--spin {
    position: absolute;
    animation: spinTranslate 1s infinite linear;

    @include breakpoint(tablet) {
        top: 50%;
        left: 50%;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.loading--generic {

    @include breakpoint(tablet) {
        top: 50%;
        left: 50%;
    }

    svg {
        fill: $c-black;
    }
}

.loading--big {
    width: var(--loading-big-width);
    height: var(--loading-big-height);
}

.loading--with-text {
    @include breakpoint(tablet) {
        transform: translate(0, -30px);
    }
}

    .loading__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .loading__text {
        @extend %boldWeight;
        color: $c-gold;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        position: absolute;
        top: calc(100% + 18px);
        left: 50%;
        transform: translate(-50%);
        width: 80vw;
        text-align: center;
    }

    .loading__fill {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        clip-path: path('M22.165 33.176V19.258C22.165 18.815 22.221 18.375 22.351 17.952 22.78 16.561 23.897 13.39 25.172 13.39 26.458 13.39 27.531 16.619 27.928 17.989 28.044 18.389 28.093 18.802 28.093 19.218V33.176L49.829 42.642 25 .086.171 42.894 22.165 33.176Z');

        .loading--big & {
            clip-path: path('M28.815 43.129V25.035C28.815 24.46 28.887 23.888 29.056 23.338 29.614 21.529 31.066 17.407 32.724 17.407 34.395 17.407 35.79 21.605 36.306 23.386 36.457 23.906 36.521 24.443 36.521 24.983V43.129L64.778 55.435 32.5.112 .222 55.762 28.815 43.129Z');
        }

        div {
            width: 100%;
            height: 200%;
            background: $c-gold;
            animation: load 2s infinite linear;
        }
    }