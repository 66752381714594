.nav-transition {
    .nav {
        visibility: visible;
        transform: translate(0%);
    }

    .nav-overlay {
        visibility: visible;

        // theme
        opacity: var(--nav-overlay-opacity);
    }
}

.nav-overlay {
    @include inset(0, 0, 0, 0);
    background-color: black;
    position: fixed;
    z-index: 9999;
    opacity: 0;
    transition: opacity 500ms;
}

.nav {
    @extend %scrollVertical;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 375px;
    padding: 24px 73px 48px;
    z-index: 9999;
    transform: translate(-101%);
    transition: transform 500ms;

    // theme
    color: var(--nav-color);
    background: var(--nav-bg);

    .button {
        flex-shrink: 0;
        margin-top: auto;
    }
}

    .nav__logo {
        height: 100px;
        margin: 0 auto 40px;
    }

    .nav__inner__close-cross-container {
        position: fixed;
        top: 16px;
        right: 16px;
    }

    .nav__links {
        width: calc(100% + 112px);
        margin: 0 -56px 32px;
    }

        .nav__group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 28px 0;
            border-bottom: 0.5px solid $c-grey-06;

            &:last-child {
                border-bottom: none;
            }
        }

            .nav__link {
                @include margin(bottom, 8px);
                @include font(16px, 20px);

                padding: 0 16px;

                &:hover {

                    // theme
                    color: var(--nav-hover-color);
                }
            }

            .nav__link--primary {
                @include margin(bottom, 16px);
                @include font(18px, 22px);

                text-transform: uppercase;
            }

            .nav__link--secondary {
                @extend %lightWeight;

                // theme
                color: var(--nav-secondary-color);
            }

.nav--transition-in {
    animation: fadeIn forwards $nav-speed;
}

.nav--transition-out {
    animation: fadeOut forwards $nav-speed;
}