.transaction-preview {
	@include font(16px, 19px);

	position: relative;
	padding: 20px 0;
	border-bottom: 1px solid $c-grey-04;
	cursor: pointer;

	&:last-child {
		border-bottom: none;
	}

	@include breakpoint(tablet) {
		padding: 20px 20px 20px 10px;
		border-bottom: none;
	}

	&:after {

		@include breakpoint(tablet) {
			content: '';
			position: absolute;
			right: 10px;
			bottom: 0;
			left: 10px;
			height: 1px;
			background: $c-grey-04;
		}
	}
}

.transaction-preview--large {
	@include margin(bottom, 20px);

	padding: 0;
	border-bottom: none;

	&:after {
		display: none;
	}
}

.transaction-preview--active {
	background: rgba($c-gold, 0.1);
	border-radius: 6px;
}

	.transaction-preview__icon {
		position: absolute;
		top: 20px;
		right: 0;

		@include breakpoint(tablet) {
			right: 10px;
		}
	}

	.transaction-preview__content {
		@include margin(bottom, 20px);

		margin-right: 25px;

		.transaction-preview--large & {
			margin-right: 0;
		}
	}

		.transaction-preview__row {
			@include margin(bottom, 2px);

			display: flex;
			justify-content: space-between;
			align-items: center;
		}

			.transaction-preview__ref {
				@extend %boldWeight;

				.transaction-preview--large & {
					@include font(24px, 29px);
					@extend %mediumWeight;
				}
			}

			.transaction-preview__status {
				@include font(14px, 17px);

				padding: 2px 8px;
				text-align: right;
				background: $c-payment-complete;
				border-radius: 4px;
				text-transform: uppercase;

				.transaction-preview--pending & {
					@extend %mediumWeight;

					color: $c-red;
					background: $c-payment-due;
				}
			}

			.transaction-preview__airports {
				@extend %lightWeight;
			}

			.transaction-preview__value {
				@extend %lightWeight;

				text-align: right;

				.transaction-preview--pending & {
					@include font-weight(regular);
				}

				.transaction-preview--large & {
					@include font-weight(bold);
				}
			}

	.transaction-preview__progress {
		@include margin(bottom, 8px);

		width: 100%;
		height: 10px;
		border-radius: 4px;
		background: rgba($c-gold, 0.1);

		div {
			width: 50%;
			height: 100%;
			background: $c-gold;
			border-radius: 4px;
		}
	}