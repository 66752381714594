.home {
	section {
		margin-bottom: 22vh;
	}
}


// --------- Specific PBS client styles -----------
.obPrivate{
	font-family: "LyonText" !important;
	font-weight: 400;
	h2{
		font-family: "StyreneA" !important;
		font-weight: 500 !important;
	}
	h3{
		font-family: "StyreneA" !important;
		font-weight: 500 !important;
	}
	button{
		font-family: "StyreneA" !important;
		font-weight: 500 !important;
	}
}
//------------------------------------------------

.private_jet {
	.btn {
		display: none;
	}

	display: flex;
	justify-content: space-between;
	background-color: white;
	height: 568px !important;
	color: #595959;
	width: 100%;

	.jet_content {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;
		width: 100%;

		.description {
			width: 510px !important;
			font-family: "Lato";
			font-size: 16px;
			font-weight: 400;
			line-height: 23px;
			letter-spacing: 0em;
			text-align: center;
		}

		h2 {
			padding-top: 51px;
		}
		h1 {
			color: #1B2646;
		}
	}

	.private_img {
		width: 50%;
		img {
			height: 568px !important;
		}
	}

}

@media (max-width: 380px) {

	.btn {
		display: block !important;
		background-color: #1B2646;
		width: 314px;
		height: 42px;
		padding: 9.33px, 0px, 9.33px, 0px;
		border-radius: 4px;
		color: #FFFFFE;
		font-size: 16px;
		font-weight: 300;
		line-height: 19.2px;
		margin: 35px 0px;
	}

	.private_jet .private_img img {
		width: 100% !important;
		min-width: 375px;
		height: 240px !important;
		object-fit: cover;
	}

	.private_jet {
		display: flex;
		flex-direction: column-reverse;
		height: unset;
		margin-top: 80px;
	}

	.private_img {
		img {
			height: 240px !important;
		}
	}

	.responsive-image {
		width: 320px !important;
		height: 240px !important;
		object-fit: contain;
	}

	.private_jet .jet_content .description {
		width: 100% !important;
		padding: 0 16px;
	}

	.private_jet .jet_content {
		height: 500px;
		width: unset;
	}

}

// Flight Query


section.home__query {
	align-items: center;
	min-height: calc(100vh - #{$header-height});
	margin-bottom: 50px;
	padding: 50px 0 25px;

	@include breakpoint(phablet) {
		min-height: calc(100vh - #{$header-height--phablet});
		margin-bottom: 100px;
		padding: 100px 0 50px;
	}
}

.home__query-background-image {
	@include inset(0, 0, 0, 0);

	position: absolute;

	&:after {
		@include inset(0, 0, 0, 0);

		content: '';
		position: absolute;
		background: rgba($c-black, 0.2);
		mix-blend-mode: multiply;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: var(--home-hero-bg-img);
	}
}

.home__query__content {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
}

.home .home__query__title {
	@include font(22px);
	@include font-weight;

	text-transform: uppercase;
	letter-spacing: 0.2em;
	margin: 0;
	text-align: center;

	// theme
	color: var(--home-query-title-color);

	@include breakpoint(phablet) {
		@include font(26px);
	}

	@include breakpoint(tablet) {
		@include font(30px);
	}

	@include breakpoint(notebook) {
		@include font(38px);
	}

	@include breakpoint(desktop) {
		@include font(42px);
	}
}

.home .home__query__sub-title {
	@include font(14px, 22px);
	text-transform: uppercase;
	letter-spacing: 0.15em;
	margin-bottom: 30px;
	margin-top: 15px;
	text-align: center;

	// theme
	color: var(--home-query-sub-title-color);

	@include breakpoint(tablet) {
		margin-bottom: 60px;
	}

	@include breakpoint(notebook) {
		margin-bottom: 12vh;
	}
}

.home__query__aircraft-type-container {
	display: flex;
	flex-direction: row;
	align-self: flex-start;
	align-items: center;
	margin-bottom: 7px;
	max-width: 500px;
	width: 100%;
	padding-left: 15px;

	@include breakpoint (phablet) {
		margin: 0 auto 10px;
		padding-left: 30px;
	}

	@include breakpoint (tablet) {
		padding-left: 10px;
		max-width: 67vw;
	}

	@include breakpoint (notebook) {
		padding-left: 15px;
		max-width: none;
	}

	&__title {
		@extend %mediumWeight;
		text-transform: uppercase;
		letter-spacing: 0.05em;

		// theme
		color: var(--home-query-title-color);
	}

	&__back-link {
		margin-left: 25px;
		display: flex;
		flex-direction: row;
		white-space: nowrap;
		align-items: center;

		button {
			@extend %boldWeight;
			border-bottom: 1px solid currentColor;
			margin-left: 6px;
		}
	}
}

.home__query__are-you-looking-for {
	@include font(16px, 26px);
	width: 100%;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	padding-bottom: 30px;
	padding-left: 15px;
	align-items: flex-start;
	max-width: 500px;
	flex-wrap: wrap;

	// theme
	color: var(--home-query-are-you-looking-for-color);

	&__comma {
		display: none;
	}

	p {
		margin-bottom: 5px;
	}

	a {
		@include font(16px, 19px);
		@extend %regularWeight;

		display: inline-block;
		margin-right: 100%;
		margin-bottom: 3px;
		text-decoration: none;
		border-bottom: 1px solid currentColor;
		white-space: nowrap;

		&:last-of-type {
			margin-right: 2px;
		}
	}

	@include breakpoint(tablet) {
		width: calc(100% - 200px);
		max-width: 85vw;
		margin: 40px auto 0;
		display: block;
		flex-direction: row;
		padding-left: 0;

		p {
			display: inline;
		}

		a {
			margin-right: 0;

			&:last-of-type {
				margin-right: 0;
			}
		}

		.home__query__are-you-looking-for__comma {
			display: inline;

			&:last-of-type {
				display: none;
			}
		}
	}

	@include breakpoint(tablet) {
		width: calc(100% - 200px);
		max-width: 85vw;
		margin: 40px auto 0;
		display: block;
	}

	@include breakpoint(notebook) {
		max-width: none;
		width: 100%;
	}
}

.home__query__are-you-looking-for--tablet {

	@include breakpoint(tablet) {
		width: calc(100% - 200px);
		max-width: 85vw;
		margin: 40px auto 0;
		display: block;
	}

	@include breakpoint(notebook) {
		max-width: none;
		width: 100%;
	}

	button {
		@extend %boldWeight;
		text-decoration: none;
		border-bottom: 1px solid currentColor;
	}
}

// Why Shy?
.home__why-shy {}

.home__panels--contact {

	@include breakpoint(tablet) {
		display: flex;
		justify-content: center;
		max-width: 1000px;
	}

	.brochure__panel {

		@include breakpoint(tablet) {
			width: 33.3333%;
		}
	}
}

.home__button-wrapper {
	margin-top: 30px;
	width: 100%;

	@include breakpoint(phablet) {
		width: 75vw;
		max-width: 350px;
		margin-top: 50px;
	}

	@include breakpoint(tablet) {
		margin-top: 80px;
	}
}

// Services
.home__services {}

.home__services__grid {
	display: grid;
	row-gap: 38px;
	max-width: 500px;

	@include breakpoint(tablet) {
		width: 100%;
		column-gap: 30px;
		margin-top: 20px;
	}

	@include breakpoint(notebook) {
		max-width: none;
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.home_service {
	display: flex;
	flex-direction: column;
	backdrop-filter: blur(5px);

	// theme
	background: var(--home-service-bg);

	&__photo {
		width: 100%;
		padding-bottom: 70%;
		border-radius: 4px;
		height: 0;
		margin-left: -0.5px;
		margin-top: -3px;
		overflow: hidden;
		position: relative;

		img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__detail {
		padding: 16px;
		display: flex;
		flex-direction: column;
		flex: 1;

		@include breakpoint(phablet) {
			padding: 16px 16px 30px;
		}
	}

	&__title {
		@include font(18px, 22px);

		letter-spacing: 0.02em;
		margin-bottom: 0.75em;

		// theme
		color: var(--home-service-title-color);

		@include breakpoint(phablet) {
			@include font(20px, 24px);
		}

		@include breakpoint(tablet) {
			@include font(22px, 28px);
		}
	}

	&__body {
		@include font(16px, 24px);
		@extend %lightWeight;

		white-space: pre-line;
		flex: 1;

		// theme
		color: var(--home-service-body-color);
	}

	&__ctas {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-top: 40px;

		button,
		a {
			@include font(16px, 19px);

			// theme
			color: var(--home-service-cta-color);
			border-bottom: 1px solid currentColor;
		}
	}
}

// Empty legs
.home__empty-legs {
	width: 90vw;

	@include breakpoint(tablet) {
		width: auto;
	}
}

.home__empty-legs__intro {
	@include font(16px, 24px);
	max-width: 800px;

	@include breakpoint(tablet) {
		text-align: center;
	}
}

// Quotes
section.home__quotes {}

.home__quotes__grid {
	width: 100vw;
	display: grid;
	row-gap: 25px;

	@include breakpoint(phablet) {
		width: 100%;
	}

	@include breakpoint(tablet) {
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 20px;
	}

	.home__quote {
		background-color: rgba($c-black, 0.15);
		border-top: solid 0.5px $c-gold-02;
		border-bottom: solid 0.5px $c-gold-02;
		padding: 20px 20px 45px;
		display: flex;
		flex-direction: column;
		backdrop-filter: blur(10px);

		@include breakpoint(phablet) {
			border-radius: 4px;
			padding: 30px 30px 50px;
			border: solid 0.5px $c-gold-02;
		}

		@include breakpoint(tablet) {
			padding: 30px 20px 35px;
		}

		&__text {
			@include font(16px, 24px);
			@extend %italicStyle;
			position: relative;
			padding: 30px 15px 20px;
			flex: 1;

			@include breakpoint(tablet) {
				padding: 30px 0 20px;
			}

			@include breakpoint(notebook) {
				padding: 30px 18px 20px;
			}

			@include breakpoint(laptop) {
				@include font(18px, 26px);
			}

			&__speech-mark {
				position: absolute;
				top: 0;
				left: 0;
			}

			&__speech-mark--bottom-right {
				top: auto;
				left: auto;
				bottom: 0;
				right: 0;
			}

			&__speech-mark--white {
				display: none;
			}
		}

		&__by {
			text-align: right;
			margin-top: 20px;
			color: $c-gold;
		}
	}

	// Even quotes
	.home__quote:nth-of-type(even) {
		background-color: $c-gold;

		.home__quote__text {
			color: $c-primary-blue;

			&__speech-mark {
				display: none;
			}

			&__speech-mark--white {
				display: block;
			}
		}

		.home__quote__by {
			color: $c-grey-01;
		}
	}
}

// Shy Lifestyle
.home__shy-lifestyle {

	&__logo {
		display: block;
		width: 100%;
		margin: 20px auto 40px;
		max-width: 300px;

		@include breakpoint(tablet) {
			margin: 20px 0 40px;
		}

		@include breakpoint(notebook) {
			margin: 3vw 0 40px;
		}
	}

	&__button-wrapper {
		margin: 40px 0;

		@include breakpoint(phablet) {
			margin: 40px auto;
		}
	}
}

// Safety
.home__safety {
	max-width: 850px;
	width: 95vw;

	@include breakpoint(phablet) {
		width: 80vw;
	}
}

// Contact
section.home__contact {
	min-height: 0;
	margin-bottom: 15vh;
}

// Animations
.fade-and-travel {
	width: 100%;
	opacity: 0;
	transform: translate(0, 100px);
	transition: 1s;

	&--visible {
		opacity: 1;
		transform: translate(0);
	}

	&--mobile {
		@include breakpoint(tablet) {
			opacity: 1;
			transform: translate(0);
		}
	}
}

@include breakpoint(tablet) {
	.home-panels-anim {
		>div {
			opacity: 0;
			transform: translate(0, 100px);
		}

		&--visible {
			>div {
				opacity: 1;
				transform: translate(0);
			}

			>div:nth-of-type(1) {
				transition: 700ms;
			}

			>div:nth-of-type(2) {
				transition: 700ms 150ms;
			}

			>div:nth-of-type(3) {
				transition: 700ms 300ms;
			}

			>div:nth-of-type(4) {
				transition: 700ms 450ms;
			}

			>div:nth-of-type(5) {
				transition: 700ms 600ms;
			}
		}
	}
}