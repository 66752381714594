.blog {
	padding: 5px 0 20px;
	overflow: hidden;
}

	// The blog looks the same as the ambassadors/careers header, so has been "borrowed", but need to discuss
	.blog__header {
		text-align: center;
		padding: 30px $site-gutter;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: $c-grey-01;

		@include breakpoint(phablet) {
			padding: 40px $site-gutter 50px;
		}

		h1 {
			@include font(24px, 28px);
			@extend %lightWeight;

			margin: 0;

			@include breakpoint(phablet) {
				@include font(26px, 30px);
			}

			@include breakpoint(tablet) {
				@include font(28px, 34px);
			}

			@include breakpoint(notebook) {
				@include font(30px, 36px);
			}

			.blog--skeleton & {
				@extend %skeleton;
			}
		}
	}

	.blog__list {
		display: grid;
		grid-column-gap: 18px;
		grid-row-gap: 35px;
		grid-template-columns: repeat(1, 1fr);

		@include breakpoint(phablet) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include breakpoint(tablet) {
			grid-template-columns: repeat(3, 1fr);
			grid-row-gap: 48px;
			grid-column-gap: 24px;
		}
	}

	.blog__footer {
		position: relative;
		margin-top: 30px;

		@include breakpoint(tablet) {
			margin-top: 24px;
		}

		.button {
			max-width: 288px;
			margin: 0 auto;
		}
	}