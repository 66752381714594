.quote-dashboard {
	padding-top: $site-gutter;
	padding-bottom: $site-gutter;

	@include breakpoint(tablet) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

	.quote-dashboard__tabs {
		@include font(12px, 12px);

		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 -#{$site-gutter} 45px;
		padding: 14px 15px 15px;
		color: $c-grey-dark;
		background: rgba($c-gold, 0.14);

		@include breakpoint(tablet) {
			margin: 0 12px 30px;
			padding-bottom: 14px;
		}

		@include breakpoint(notebook) {
			margin: 0 82px 30px;
		}
	}

		.quote-dashboard__tab {
			@include font(16px, 19px);
			@include margin(right, 32px);
			@include margin(left, 32px);

			color: $c-grey-03;

			@include breakpoint(tablet) {
				@include font(18px, 20px);
			}

			&:hover {
				color: $c-grey-dark;
			}
		}

		.quote-dashboard__tab--active {
			position: relative;
			color: $c-grey-dark;

			&:after {
				content: '';
				position: absolute;
				bottom: -15px;
				left: 50%;
				width: 60px;
				height: 1px;
				background: $c-primary-blue;
				transform: translateX(-50%);

				@include breakpoint(tablet) {
					bottom: -14px;
				}
			}
		}

	.quote-dashboard__content {
		@include margin(bottom, 40px);

		@include breakpoint(tablet) {
			@include margin(bottom, 48px);

			@include breakpoint(phablet) {
				padding: 0 12px;
			}

			@include breakpoint(notebook) {
				padding: 0 82px;
			}
		}
	}

	.quote-dashboard__content--has-sidebar {

		@include breakpoint(tablet) {
			display: flex;
			align-items: flex-start;
		}
	}

		.quote-dashboard__checklist {
			margin-bottom: 32px;

			@include breakpoint(tablet) {
				flex: 1 1 auto;
				margin-right: 52px;
				margin-bottom: 0;
			}
		}

		.quote-dashboard__sidebar {
			text-align: center;

			@include breakpoint(tablet) {
				flex: 1 1 378px;
				min-height: 438px;
				padding: 32px 25px;
				border: 1px solid rgba($c-grey-04, 0.2);
				box-shadow: -2px 2px 4px rgba(212, 188, 135, 0.04), 2px -2px 4px rgba(212, 188, 135, 0.04), -2px -2px 4px rgba(212, 188, 135, 0.04), 2px 2px 4px rgba(212, 188, 135, 0.04), inset 2px 1px 2px rgba(212, 188, 135, 0.02), inset -1px -1px 2px rgba(212, 188, 135, 0.02);
			}

			@include breakpoint(notebook) {
				flex: 0 0 378px;
			}

			&:before {
				content: '';
				display: block;
				width: 144px;
				height: 1px;
				margin: 0 auto 24px;
				background: $c-gold;

				@include breakpoint(tablet) {
					display: none;
				}
			}

			.button {
				justify-content: flex-start;
				padding: 0 10px;

				img {

					&:first-child {
						margin-right: 13px;
					}

					&:last-child {
						margin-left: auto;
						padding-left: 10px;
					}
				}
			}
		}

			.quote-dashboard__sidebar-title {
				@include font(18px, 22px);
				@include margin(bottom, 24px);
				@extend %mediumWeight;

				letter-spacing: 0.02em;
				color: $c-primary-blue;

				@include breakpoint(tablet) {
					@include font(22px, 26px);
				}
			}

			.quote-dashboard__sidebar-buttons {
				margin-bottom: 70px;

				.button {
					@include margin(bottom, 8px);
				}
			}

			.quote-dashboard__sidebar-text {
				@include font(16px, 22px);
				@extend %lightWeight;
			}