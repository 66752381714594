.quote-dashboard-article-alt {
	@include margin(bottom, 40px);

	position: relative;

	@include breakpoint(tablet) {
		@include margin(bottom, 48px);
	}

	&:last-child {

		&:before {
			display: none;
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 20px;
		left: 11px;
		height: calc(100% - 24px + 46px);
		border-left: 2px dashed $c-gold;

		@include breakpoint(tablet) {
			top: 24px;
			left: 15px;
			height: calc(100% - 32px + 59px);
		}
	}
}

.quote-dashboard-article-alt--complete {

	&:before {
		border-left-style: solid;
	}
}

	.quote-dashboard-article-alt__content {
		padding-left: 24px + 6px;

		@include breakpoint(tablet) {
			padding-left: 32px + 16px;
		}

		.button {
			max-width: 288px;
		}
	}

		.quote-dashboard-article-alt__header {
			@include margin(bottom, 12px);

			display: flex;
			margin-left: -(24px + 6px);

			@include breakpoint(tablet) {
				margin-left: -(32px + 16px);
			}
		}

			.quote-dashboard-article-alt__status {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 24px;
				height: 24px;
				margin-right: 6px;

				@include breakpoint(tablet) {
					width: 32px;
					height: 32px;
					margin-right: 16px;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}

			.quote-dashboard-article-alt__title {
				@include font(18px, 22px);
				@extend %mediumWeight;

				color: $c-gold-02;
				letter-spacing: 0.02em;

				@include breakpoint(tablet) {
					@include font(22px, 26px);
				}

				.quote-dashboard-article-alt--current & {
					@extend %boldWeight;

					color: $c-gold;
				}
			}

		.quote-dashboard-article-alt__text {
			@include font(16px, 19px);
			@include margin(bottom, 12px);
			
			color: $c-grey-02;

			ul {
				margin-left: 0;
				padding-left: 20px;
				list-style: outside;
			}

			strong {
				@extend %boldWeight;
			}

			a {
				text-decoration: underline;
			}

			p {

				&:not(:first-child) {
					@extend %lightWeight;

					a {
						@extend %boldWeight;
					}
				}
			}
		}

		.quote-dashboard-article-alt__link {
			@include font(16px, 19px);

			text-decoration: underline;
			color: $c-grey-03;
		}