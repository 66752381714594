.more-info-tray {
	border-radius: 4px;
	border: 1px solid rgba($c-gold, .3);
	margin-top: 10px;

	@include breakpoint(phablet) {
		margin-top: 12px;
	}

	@include breakpoint(notebook) {
		margin-top: 16px;
	}

	.flight-brief--skeleton & {
		@extend %skeleton;
		
		border: none;
	}
}

	.more-info-tray__button {
		@extend %boldWeight;
		color: $c-grey-dark;
		height: 55px;
		padding: 0 18px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;

		.flight-brief--skeleton & {
			pointer-events: none;
		}

		img {
			margin-left: auto;

			.flight-brief--skeleton & {
				display: none;
			}
		}
	}

	.more-info-tray__button--open {
		img {
			transform: rotate(-180deg);
		}
	}

	.more-info-tray__content {
		padding: 18px;
		border-top: 2px solid rgba(212, 188, 135, 0.1);
	}