.invoice {
	padding: 24px 40px 10px;
	background: #ffffff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	table {
		width: 100%;
	}
}

	.invoice__header {}

		.invoice__logo {

			img {
				display: block;
			}
		}

		.invoice__address {
			padding-top: 10px;
			font-size: 12px;
			line-height: 15px;
			color: #595959;
		}

		.invoice__meta {
			padding-top: 10px;
			font-size: 12px;
			line-height: 15px;
			color: #595959;
			vertical-align: bottom;
			text-align: right;

			span {
				line-height: 18px;
			}

			a {
				line-height: 18px;
				color: #D4BC87;
				text-decoration: underline;
				text-transform: uppercase;
			}
		}

		.invoice__warning {
			padding: 40px 0;
			font-size: 10px;
			line-height: 20px;
			font-weight: 500;
			color: #1B2646;
			text-align: center;
		}

	.invoice__details {
		margin-bottom: auto;
	}

		.invoice__ref {
			font-size: 12px;
			line-height: 15px;
			color: #595959;

			span {
				line-height: 18px;
			}
		}

		.invoice__table {

			thead th {
				padding: 4px 0;
				font-size: 10px;
				line-height: 18px;
				text-transform: uppercase;
				color: #1B2646;
				border-top: 1px solid black;
				border-bottom: 1px solid black;
			}

			tbody th {
				padding: 12px 8px;
				font-size: 12px;
				line-height: 24px;
				font-weight: 500;
				text-transform: uppercase;
				text-align: left;
				color: #333333;

				span {
					display: block;
					font-size: 11px;
					line-height: 15px;
					font-style: italic;
					font-weight: 400;
				}
			}

			tbody td {
				width: 0;
				padding: 12px 8px;
				font-size: 11px;
				line-height: 20px;
				font-weight: 500;
				text-transform: uppercase;
				text-align: center;
				color: #333333;
			}

			tbody tr:last-child th,
			tbody tr:last-child td {
				padding-bottom: 20px;
			}

			tbody tr:last-child th,
			tbody tr:last-child td,
			tfoot tr:last-child th,
			tfoot tr:last-child td {
				border-bottom: 1px solid black;
			}

			tfoot th {
				text-align: right;
			}

			tfoot th,
			tfoot td {
				padding: 5px 8px 5px 0;
				font-size: 10px;
				line-height: 18px;
				text-transform: uppercase;
				color: #595959;
				background: #F6F6F8;

				&.invoice__price {
					font-size: 14px;
					line-height: 25px;
					font-weight: 700;
					color: #1B2646;
				}
			}
		}

	.invoice__callout {
		margin: 100px 0 15px;
		background: #F6F6F8;
		text-align: center;
	}

		.invoice__callout-header {
			padding: 25px 25px 10px;
			font-size: 16px;
			line-height: 19px;
			font-weight: 700;
			text-transform: uppercase;
			color: #333333;

			a {
				color: #D4BC87;
				text-decoration: underline;
			}
		}

		.invoice__callout-copy {
			padding: 0 25px 25px;
			font-size: 12px;
			line-height: 19px;
			color: #595959;
		}

	.invoice__payment {
		border-top: 1px solid black;
		border-bottom: 1px solid black;

		tr:first-child {

			th,
			td {
				padding-top: 10px;
			}
		}

		tr:last-child {

			th,
			td {
				padding-bottom: 10px;
			}
		}

		th,
		td {
			width: 50%;
			font-size: 9px;
			line-height: 10px;
		}

		th {
			padding-right: 10px;
			padding-left: 10px;
			color: #747474;
			text-align: right;
		}

		td {
			padding-right: 10px;
			color: #595959;
			text-align: left;
		}
	}

	.invoice__footer {
		text-align: center;
	}

		.invoice__footer-row-1 {

			td {
				width: 33.3333%;
				padding-top: 3px;
				padding-bottom: 15px;
				font-size: 9px;
				line-height: 14px;
				text-transform: uppercase;
				color: #1B2646;

				&:first-child {
					text-align: left;
				}

				&:last-child {
					text-align: right;
				}
			}
		}

		.invoice__footer-row-2 {

			td {
				font-size: 6px;
				line-height: 11px;
				font-weight: 600;
				color: #C4B077;
			}
		}

		.invoice__footer-row-3 {

			td {
				font-size: 8px;
				line-height: 15px;
				font-weight: 600;
				text-transform: uppercase;
				color: #C4B077;
			}
		}