.Collapsible {
	@include margin(bottom, 15px);

	border: 1px solid rgba($c-gold, 0.3);
	border-radius: 4px;

	.collapsible-content--skeleton & {
		@extend %skeleton;
		border: none;
	}
}

	.Collapsible__trigger {
		@include font(16px, 19px);
		@extend %mediumWeight;

		display: flex;
		align-items: center;
		position: relative;
		min-height: 50px;
		padding: 8px 45px 8px 15px;
		color: $c-grey-dark;
		cursor: pointer;

		@include breakpoint(tablet) {
			padding: 8px 30px;
		}

		.collapsible-content--skeleton & {
			pointer-events: none;
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 15px;
			width: 12px;
			height: 12px;
			border-bottom: 2px solid $c-gold;
			border-left: 2px solid $c-gold;
			transform: translateY(-50%) rotate(-45deg);
			transform-origin: 50% 50%;

			.collapsible-content--skeleton & {
				display: none;
			}
		}

		&.is-open {

			&:after {
				transform: rotate(135deg);
			}
		}
	}

	.Collapsible__contentOuter {}

		.Collapsible__contentInner {
			@include font(14px, 20px);
			@extend %lightWeight;

			max-width: 610px + (15px * 2);
			padding: 0 15px 15px;

			@include breakpoint(tablet) {
				max-width: 610px + (30px * 2);
				padding: 0 30px 30px;
			}

			p {
				@include margin(bottom, 1em);
			}
		}