.payment-pin-form {}

	.payment-pin-form__content {
		color: $c-grey-03;
		padding: 22px 15px;

		@include breakpoint(phablet) {
			padding: 45px 55px;
		}
	}

		.payment-pin-form__text {
			@include font(16px, 20px);
			color: $c-grey-03;
			margin-bottom: 25px;
		}

		.payment-pin-form__button {
			max-width: 288px;
			margin: auto;
		}