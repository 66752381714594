.filters-bar {
	background-color: $c-grey-01;
	z-index: 2;

	@include breakpoint(phablet) {
		top: $header-height + 50px;
		position: sticky;
	}
}

	.filters-bar__inner {
		padding: 10px 0;
		display: flex;
		flex-direction: row;
		border-bottom: 0.5px solid $c-gold;
		position: relative;

		a {
			margin-left: auto;
		}
	}

		.filters-bar__toggle {
			display: flex;
			align-items: center;
			margin-right: 10px;

			.filters-bar--inactive & {
				opacity: 0.2;
				pointer-events: none;
			}

			img {
				margin-right: 10px;
			}
		}