.order-summary {
	margin-top: 30px;
	color: $c-grey-dark;

	@include breakpoint(tablet) {
		margin-top: 40px;
		max-width: 400px;
	}
}

.order-summary--flush {
	margin-top: -18px;

	@include breakpoint(tablet) {
		margin-top: 0;
	}
}

	.order-summary__line {
		height: 1px;
		background: $c-gold;
		margin-bottom: 30px;

		@include breakpoint(tablet) {
			display: none;
		}

		.order-summary--simple & {
			display: none;
		}
	}

	.order-summary__title {
		@extend %boldWeight;
		margin-bottom: 12px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&__chevron {
			margin-top: 4px;
			transform-origin: center;
			transition: all $aircraft-filter-speed;

			@include breakpoint(phablet) {
				width: 22px;
			}

			&--rotated {
				transform: rotate(-180deg);
			}

			.order-summary--simple & {

				@include breakpoint(tablet) {
					display: none;
				}
			}
		}
	}

	.order-summary__item {
		line-height: 1.4em;
		margin-bottom: 30px;

		.order-summary--simple & {
			padding: 0 15px;
		}
	}

		.order-summary__item-group-title {
			@include font(18px, 22px);
			@include margin(bottom, 5px);
			@extend %boldWeight;
		
			color: $c-gold-02;
			text-transform: uppercase;

			.order-summary--simple & {
				padding: 0 15px;
			}
		}

		.order-summary__item__gold {
			@extend %boldWeight;
			color: $c-gold-02;
		}

		.order-summary__item__bold {
			@extend %boldWeight;
		}

		.order-summary__item__detail {
			display: flex;
			flex-direction: row;
			line-height: 18px;
			margin: 5px 0;
		}

			.order-summary__item__detail__title {
				width: 120px;
				flex-shrink: 0;
				flex-grow: 0;
			}

			.order-summary__item__detail__quantity {
				@extend %boldWeight;
			}

	.order-summary__button-wrapper {
		margin-top: 10px;
		margin-bottom: 18px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.order-summary__whatsapp {
		@include font(16px, 21px);
		@extend %boldWeight;
		color: $c-gold-02;
		margin: 40px auto;
		text-align: center;

		@include breakpoint(tablet) {
			text-align: left;
		}
	}