.carbon-emissions {
	color: $c-grey-01;
	position: relative;

	// Wrap
	.wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
	}

	// Header
	&__header {
		background-color: $c-environmental-green;
		text-align: center;
		padding-top: 30px;
		padding-bottom: 20px;
		background-size: cover;
		background-position: top center;

		@include breakpoint(phablet) {
			padding-bottom: 35px;
		}

		@include breakpoint(notebook) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		&__thank-you {
			@extend %boldWeight;
			@include font(24px, 30px);
			margin-bottom: 0.4em;

			@include breakpoint(phablet) {
				@include font(26px, 32px);
			}

			@include breakpoint(tablet) {
				@include font(28px, 34px);
			}

			@include breakpoint(notebook) {
				@include font(30px, 36px);
			}

			@include breakpoint(taptop) {
				@include font(34px, 40px);
			}
		}

		&__info {
			@extend %boldWeight;
			@include font(16px, 24px);
			max-width: 80vw;

			@include breakpoint(tablet) {
				max-width: 500px;
				@include font(18px, 26px);
			}
		}

		&__inputs {
			display: grid;
			grid-template-columns: auto auto auto;
			justify-content: center;
			align-items: center;
			margin-bottom: 30px;
		}

		&__at {
			margin-left: 11px;
			margin-right: 11px;
		}

		&__mt-number {
			@extend %boldWeight;
			@include font(28px, 36px);
			color: $c-gold;

			@include breakpoint(phablet) {
				@include font(30px, 38px);
			}

			@include breakpoint(tablet) {
				@include font(32px, 40px);
			}

			@include breakpoint(notebook) {
				@include font(34px, 44px);
			}

			@include breakpoint(taptop) {
				@include font(36px, 46px);
			}
		}

		&__how-does-this-work {
			@extend %boldWeight;
			@include font(16px, 22px);
			color: $c-gold-02;
			border-bottom: 1px solid $c-gold-02;
			margin-top: 20px;

			@include breakpoint(notebook) {
				position: absolute;
				top: -12px;
				right: 30px;
				margin-top: 0;
			}
		}

		&__you-saved-a-total-of {
			@extend %boldWeight;
			@include font(18px, 28px);
			color: $c-grey-04;
			margin-top: 25px;
		}

		&__see-how-you-saved {
			max-width: 90vw;
			@include font(16px, 22px);
			margin-top: 35px;

			a {
				@extend %mediumWeight;
				border-bottom: 1px solid $c-grey-01;
			}
		}
	}

	// Planet hero
	&__planet-hero {
		background-color: $c-environmental-green;
		padding-top: 50px;
		padding-bottom: 70px;

		&__title {
			@extend %boldWeight;
			@include font(22px, 30px);
			text-transform: uppercase;
			color: $c-grey-05;
		}

		&__sub-title {
			margin-top: 10px;
		}

		&__button-wrapper {
			margin-top: 30px;
			width: 100%;
			max-width: 300px;
		}
	}

	// Project
	&__projects {
		background-color: $c-grey-01;

		@include breakpoint(notebook) {
			padding-bottom: 40px;
		}

		&__title {
			@extend %boldWeight;
			@include font(16px, 20px);
			color: $c-primary-blue;
			text-align: center;
			max-width: 80%;
			margin: 0 auto 30px;

			@include breakpoint(phablet) {
				@include font(18px, 22px);
			}

			@include breakpoint(notebook) {
				margin: 15px auto 30px;
				@include font(20px, 24px);
			}

			@include breakpoint(laptop) {
				text-transform: uppercase;
				@include font(22px, 26px);
			}
		}

		p {
			@include font(16px, 20px);
			max-width: 80%;
			color: $c-grey-02;
			text-align: center;
			margin: -15px auto 30px;
		}

		&__swiper-container {
			margin: auto;

			@include breakpoint(notebook) {
				max-width: calc(100vw - 40px);
				.swiper-pagination-bullet {
					display: none;
				}
			}

			@include breakpoint(laptop) {
				max-width: 1160px;
			}
		}

		&__projects-container {
			margin: 0 auto 30px;
			max-width: 550px;
			position: relative;

			@include breakpoint(phablet) {
				max-width: 500px;
			}

			@include breakpoint(notebook) {
				max-width: 1300px;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
			}

			@include breakpoint(laptop) {
				column-gap: 20px;
				width: calc(100vw - 50px);
				margin-bottom: 50px;
			}
		}
	}

	// Journeys
	&__journeys {
		margin: auto;
		background-color: $c-grey-01;
		max-width: 1200px;

		@include breakpoint(tablet) {
			margin: 30px auto 50px;
		}

		.journeys__table {
			column-gap: 8px;
			row-gap: 16px;
			color: $c-grey-dark;
			position: relative;

			@include breakpoint(tablet) {
				margin: 0 15px;
				padding-bottom: 1px;
			}

			&__backgrounds {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;

				@include breakpoint(tablet) {
					left: 0;
					right: 0;
					column-gap: 8px;
					row-gap: 16px;
					grid-template-columns: 1.5fr 1fr 1fr;
				}

				@include breakpoint(notebook) {
					grid-template-columns: 2fr 1fr 1fr;
				}

				&__blue {
					&:first-of-type {
						display: none;
						@include breakpoint(tablet) {
							display: block;
						}
					}

					background-color: rgba($c-environmental-blue, 0.3);
				}

				&__green {
					background-color: rgba($c-environmental-green-03, 0.3);
				}
			}

			&__header {
				display: none;
				position: relative;
				@extend %boldWeight;
				@include breakpoint(tablet) {
					width: 100%;
					display: grid;
					column-gap: 8px;
					grid-template-columns: 1.5fr 1fr 1fr;
				}

				@include breakpoint(notebook) {
					column-gap: 8px;
					grid-template-columns: 2fr 1fr 1fr;
				}

				&__title {
					@include font(18px);
					color: $c-black;
					background-color: $c-environmental-blue;
					padding: 20px;
					text-align: center;

					&--carbon-emissions {
						background-color: $c-environmental-green-02;
						color: $c-grey-01;
					}
				}
			}

			&__journey {
				position: relative;
				width: 100%;
				display: grid;
				column-gap: 8px;
				grid-template-columns: 1fr 1fr;
				border-bottom: 1px solid rgba($c-black, 0.1);

				@include breakpoint(tablet) {
					grid-template-columns: 1.5fr 1fr 1fr;
					border-bottom: none;
					margin-bottom: 16px;
					background-color: rgba($c-grey-01, 0.73);
				}

				@include breakpoint(notebook) {
					column-gap: 8px;
					grid-template-columns: 2fr 1fr 1fr;
				}
			}

			&__journey-leg {;
				grid-column: span 2;
				padding: 30px 15px 15px;
				background-color: $c-grey-01;
				width: 100%;

				@include breakpoint(tablet) {
					padding: 8px;
					grid-column: span 1;
				}

				@include breakpoint(tablet) {
					margin: 0;
					padding: 15px;
				}
			}

			&__aircraft-details {
				padding: 15px;

				@include breakpoint(notebook) {
					padding: 8px;
					display: grid;
					grid-template-columns: 1fr 3fr;
					padding-top: 30px;
					grid-column-gap: 8px;
					align-content: flex-start;
				}

				&__title {
					@extend %lightWeight;
					margin-bottom: 6px;

					@include breakpoint(notebook) {
						width: 100px;
					}

					&--carbon-emissions {
						@include breakpoint(tablet) {
							display: none;
						}
					}
				}

				&__info {
					@extend %boldWeight;
					margin-bottom: 10px;

					&--carbon-emissions {
						@include breakpoint(mobile) {
							text-align: center;
						}

						@include breakpoint(tablet) {
							font-weight: 400;
							text-align: center;
							grid-column: span 2;
							padding-top: 20px;
						}

						@include breakpoint(notebook) {
							padding-top: 0;
						}
					}

					&__carbon-number {
						@extend %mediumWeight;
						@include font(30px);
						margin-top: 12px;
						margin-bottom: 5px;

						@include breakpoint(tablet) {
							font-weight: 700;
							margin-top: 0;
							text-align: center;
							@include font(22px);
							justify-self: center;
						}

					}
				}
			}
		}
	}
}