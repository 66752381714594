.profile {
	position: relative;
}

	.profile__picture {
		height: 16vh;
		width: 16vh;
		max-height: 120px;
		max-width: 120px;
		min-height: 90px;
		min-width: 90px;
		border-radius: 100%;
		border: solid 1px $c-gold;
		background-color: rgba($c-gold, .1);
		position: relative;
		margin: 0 auto 20px;

		@include breakpoint(tablet) {
			margin: 0 auto 40px;
		}
	}

		.profile__picture__text {
			@include font(24px);
			color: $c-gold-02;
			text-transform: uppercase;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -48%);
		}

		.profile__picture__icon {
			position: absolute;
			bottom: 4%;
			right: 4%;
			height: 22px;
			width: 22px;

			img {
				margin: auto;
				height: 100%;
			}
		}

		.profile__submit-button-container {
			@include breakpoint(tablet) {
				grid-column: span 2;
				margin-top: 20px;

				button {
					max-width: 300px;
					margin: auto;
					width: 30vw;
				}
			}
		}