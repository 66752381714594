.modal {
	@include inset(0, 0, 0, 0);
	position: fixed;
	display: flex;
	flex-direction: column;
	z-index: 3;
}

.modal--transition-in {
	animation: slamIn forwards $modal-speed;
	.modal__backdrop{
		animation: fadeIn forwards $modal-speed;
	}
}

.modal--transition-out {
	animation: slamOut forwards $modal-speed;
	.modal__backdrop{
		animation: fadeOut forwards $modal-speed;
	}
}

.modal--welcome {
	z-index: 9998;
}

	.modal__scroll {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		@extend %scrollVertical;
	}

	.modal__backdrop {
		@include inset(0, 0, 0, 0);
		position: absolute;
		background-color: rgba($c-black, 0.8);

		.modal--restricted & {
			background: transparent;
		}
	}

	.modal__backdrop--semi-opaque {
		background-color: rgba($c-black, 0.4);
	}

	.modal__content-wrapper {
		position: relative;
		padding: 2rem 0 2rem 0;
		width: 100%;
		margin: auto;

		.modal--full & {
			margin: 0;
			padding: 0;
		}
	}

		.modal__content {
			pointer-events: auto;
			margin: auto;
			position: relative;
			width: calc(100vw - 50px);
			max-width: 900px;
			background-color: $c-grey-01;
			border-radius: 4px;
			overflow: hidden;

			.modal--full & {
				width: 100%;
				max-width: none;
				border-radius: 0;
			}
		}

		.modal__content--thin {
			max-width: 485px;
		}

		.modal__content--medium {
			max-width: 600px;
		}

		.modal__close-cross-wrapper {
			position: absolute;
			top: 4px;
			right: 7px;

			@include breakpoint(phablet) {
				top: 8px;
				right: 8px;
			}

			@include breakpoint(notebook) {
				top: 12px;
				right: 12px;
			}

			.modal--restricted & {
				opacity: 0;
				pointer-events: none;
			}
		}