.transactions {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	color: $c-grey-dark;

	.wrap {
		flex: 1 1 auto;
		margin: 0 auto;
		
		@include breakpoint(tablet) {
			display: flex;
		}
	}
}

	.transactions__list {
		padding-top: 25px;

		@include breakpoint(tablet) {
			width: 370px;
		}
	}

		.transactions__title {
			@include font(18px, 22px);

			@include breakpoint(tablet) {
				@include margin(bottom, 20px);

				padding: 0 10px;
			}
		}

	.transactions__content {
		flex: 1 1 auto;
		padding-top: 25px;

		@include breakpoint(tablet) {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 30px 0 30px 40px;
		}

		.transactions--viewing & {

			@include breakpoint(tablet) {
				justify-content: flex-start;
				align-items: flex-start;
			}
		}
	}

		.transactions__intro {
			@include font(22px, 26px);

			max-width: 320px;
			margin: 0 auto;
			letter-spacing: 0.02em;
			text-align: center;
		}

		.transactions__column {
			@include margin(bottom, 50px);

			@include breakpoint(tablet) {
				flex: 1 1 auto;
				margin-bottom: 0;
			}
		}

		.transactions__column--2 {

			@include breakpoint(tablet) {
				max-width: 230px + 20px + 1px;
				margin-left: 20px;
				padding-left: 20px;
				border-left: 1px solid $c-grey-04;
			}
		}

			.transactions__payment {
				@include margin(bottom, 30px);

				display: flex;
				justify-content: center;

				@include breakpoint(tablet) {
					@include margin(bottom, 66px);
				}

				.button {
					max-width: 288px;
				}
			}