.settings {
	max-width: 600px;
	margin: auto;
	position: relative;

	@include breakpoint(tablet) {
		max-width: 1000px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 40px;
	}

	@include breakpoint(notebook) {
		column-gap: 55px;
	}

	section {
		position: relative;
		@include breakpoint(tablet) {
			position: static;
		}
	}

	h1 {
		@include font(18px);
		color: $c-primary-blue;
		margin: 0;

		@include breakpoint(tablet) {
			@include font(20px);
		}

		@include breakpoint(notebook) {
			@include font(22px);
		}
	}

	&__border {
		border: solid 1px $c-blue-04;
		margin-top: 20px;
		margin-bottom: 40px;
		padding: 14px;
		border-radius: 4px;
	}

	&__manage-data {
		button:first-of-type {
			margin-bottom: 14px;
		}
	}

	b {
		@include font(16px, 18px);
		display: block;
		margin-bottom: 16px;
	}
}