.fieldset {
	width: 100%;
	position: relative;
}

.fieldset--margin-bottom {
	margin-bottom: 30px;
}

.fieldset--skeleton {

	&:after {
		@extend %skeleton;
		
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 4px;
	}
}