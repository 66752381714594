.quote-dashboard-article {
	@include margin(bottom, 40px);

	@include breakpoint(tablet) {
		@include margin(bottom, 48px);

		display: flex;
		align-items: flex-start;
	}

	&:not(:first-child) {

		.button,
		.quote-dashboard-article__note {
			display: none;
		}
	}
}

	.quote-dashboard-article__date {
		@include font(14px, 17px);
		@include margin(bottom, 8px);
		@extend %lightWeight;

		display: block;

		@include breakpoint(tablet) {
			@include font(16px, 22px);
			
			flex: 0 0 150px;
			margin-bottom: 0;
		}
	}

	.quote-dashboard-article__content {

		@include breakpoint(tablet) {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			max-width: 514px + 16px;
			padding-left: 16px;
		}

		.button {
			@include margin(bottom, 16px);

			@include breakpoint(tablet) {
				@include margin(bottom, 28px);
			}
		}
	}

		.quote-dashboard-article__header {
			@include margin(bottom, 8px);

			display: flex;
			align-items: center;

			@include breakpoint(tablet) {
				@include margin(bottom, 15px);
			}

			&:before {
				content: '';
				width: 2px;
				height: 18px;
				margin-right: 10px;
				background: $c-gold;

				@include breakpoint(tablet) {
					height: 24px;
					margin-right: 0;//14px;
					transform: translateX(-18px);
				}
			}

			img {
				width: 18px;

				@include breakpoint(tablet) {
					width: 24px;
				}

				&:not(:first-child) {
					margin-left: 10px;
				}

				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}

			img.quote-dashboard-article__icon--quote-submitted,
			img.quote-dashboard-article__icon--quote-selected {
				width: 12px;
				margin-left: 3px;

				@include breakpoint(tablet) {
					width: 16px;
					margin-left: 4px;
				}

				&:not(:last-child) {
					margin-right: 10px + 3px;

					@include breakpoint(tablet) {
						margin-right: 10px + 4px;
					}
				}
			}

			img.quote-dashboard-article__icon--quote-pending-contract {
				width: 9px;
				margin-left: 4px;

				@include breakpoint(tablet) {
					width: 12px;
					margin-left: 6px;
				}

				&:not(:last-child) {
					margin-right: 10px + 5px;

					@include breakpoint(tablet) {
						margin-right: 10px + 6px;
					}
				}
			}

			.quote-dashboard-article__title {
				@include font(16px, 19px);
				@extend %mediumWeight;

				@include breakpoint(tablet) {
					@include font(18px, 22px);
				}
			}

		.quote-dashboard-article__text {
			@include font(14px, 22px);
			@include margin(bottom, 12px);

			@include breakpoint(tablet) {
				@include font(16px, 25px);
				@include margin(bottom, 12px);
			}

			ul {
				margin-left: 0;
				padding-left: 20px;
				list-style: outside;
			}

			strong {
				@extend %boldWeight;
			}

			a {
				text-decoration: underline;
			}
		}

		.quote-dashboard-article__link {
			@include font(14px, 17px);

			text-decoration: underline;
		}

		.quote-dashboard-article__note {
			@include font(14px, 17px);

			color: $c-gold;
			letter-spacing: 0.04em;
			text-align: center;
		}