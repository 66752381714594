.checkbox {
	display: flex;
	flex-direction: row;
	margin-bottom: 40px;
	position: relative;
	margin-top: 20px;
	padding-bottom: 5px;
}

.checkbox--small-margin {
	margin-top: 0;
}

.checkbox--locked {
	opacity: 0.65;

	.checkbox__label {
		cursor: default;
	}
}

.checkbox--aircraft-compare {
	padding: 0;
	margin: 0;

	.checkbox__box {
		height: 24px;
		width: 24px;
		background-color: $c-white;
	}

	.checkbox__box--success {
		background-color: $c-white;
		border: solid $c-grey-03 1px;
	}
}

	.checkbox__box {
		flex-shrink: 0;
		height: 18px;
		width: 18px;
		border: solid $c-grey-03 1px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include breakpoint(tablet) {
			height: 25px;
			width: 25px;
		}

		img {
			width: 65%;
			height: 65%;

			@include breakpoint(tablet) {
				width: 60%;
				height: 60%;
			}
		}
	}

	.checkbox__box--error {
		border: solid $c-red 1px;
	}

	.checkbox__box--success {

		// theme
		background: var(--checkbox-success-bg);
		border: solid var(--checkbox-success-bg) 1px;
	}

	.checkbox__label {
		@extend %lightWeight;
		@include font(16px, 20px);
		width: auto;
		display: block;
		margin-left: 8px;
		margin-top: -1px;

		@include breakpoint(tablet) {
			margin-left: 12px;
			margin-top: 2px;
		}

		a {
			@extend %mediumWeight;
			text-decoration: none;
			border-bottom: 1px solid $c-grey-dark;
			display: inline;
			transform: translate(1px, 0.75px);
		}
	}