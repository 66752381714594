.quotes-and-bookings {

	&__title + &__title {
		margin-top: 20px;
	}

	&__title {
		text-align: center;
		color: $c-grey-02;

		@include breakpoint(phablet) {
			@include font(18px)
		}

		b {
			@extend %boldWeight;
			color: $c-grey-dark;
		}

		&__chevron {
			width: 20px;
			margin-top: 4px;
			transform-origin: center;
			transition: all $aircraft-filter-speed;

			@include breakpoint(phablet) {
				width: 22px;
			}

			&--rotated {
				transform: rotate(-180deg);
			}
		}

		button {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin: 0 auto;

			b {
				margin-left: 3px;
				margin-right: 8px;
			}
		}
	}

	&__none-found {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 20px;
		padding-bottom: 50px;

		@include breakpoint(phablet) {
			padding-bottom: 65px;
		}

		@include breakpoint(notebook) {
			padding-top: 40px;
			padding-bottom: 85px;
		}

		&__title {
			@extend %boldWeight;
			margin-bottom: 0.5em;

			@include breakpoint(phablet) {
				@include font(18px)
			}

			@include breakpoint(tablet) {
				@include font(22px)
			}

			@include breakpoint(notebook) {
				font-weight: 500;
				@include font(24px)
			}
		}

		&__sub-title {
			@extend %lightWeight;
			margin-bottom: 1em;
		}

		&__close-cross-wrapper {
			position: absolute;
			top: 0;
			right: 0;
		}

	}

	&__listing-wrapper {
		padding-bottom: 60px;
	}

}