.quote-filters {
	height: 0px;
	overflow: hidden;
	transition: height 400ms;
	pointer-events: none;

	@include breakpoint(phablet) {
		height: 40px;
		padding-top: 4px;
		padding-bottom: 4px;
	}
}

.quote-filters--transition-in,
.quote-filters--active {
	height: 48px;
	pointer-events: auto;
	@include breakpoint(phablet) {
		height: 40px;

		.quote-filters__row{
			opacity: 1;
			transition: opacity 200ms, visibility 0ms 0ms;
			visibility: visible;
		}
	}
}

	.quote-filters__row {
		height: 48px;
		background: $c-blue-04;
		width: 100%;
		display: flex;
		padding: 0 10px;
		justify-content: space-between;

		@include breakpoint(phablet) {
			visibility: hidden;
			border-radius: 4px;
			opacity: 0;
			height: 100%;
			transition: opacity 200ms, visibility 0ms 200ms;
		}

		@include breakpoint(tablet) {
			width: 550px;
			padding-left: 40px;
			padding-right: 40px;
		}

		@include breakpoint(notebook) {
			width: 700px;
			padding-left: 70px;
			padding-right: 70px;
		}
	}

	.quote-filters__filter{
		display: flex;
		flex-direction: row;
		align-items: center;
		color: $c-grey-dark;
	}

		.quote-filters__filter__check-box{
			height: 16px;
			width: 16px;
			border-radius: 2px;
			margin-right: 5px;
			border: solid 1px $c-grey-02;
			background-color: $c-grey-01;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}