.book {
	@include font(16px, 19px);

	.wrap {
		@include breakpoint(tablet) {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
		}
	}

	.order-summary {

		@include breakpoint(tablet) {
			flex: 1 1 auto;
			margin-left: 20px;
		}

		@include breakpoint(notebook) {
			margin-left: 55px;
		}
	}
}

	.book__form {

		@include breakpoint(tablet) {
			max-width: 450px;
		}

		@include breakpoint(notebook) {
			max-width: 500px;
		}
	}

		.book__aircraft {
			@include margin(bottom, 10px);

			margin-right: -$site-gutter;
			margin-left: -$site-gutter;

			@include breakpoint(tablet) {
				margin-right: 0;
				margin-left: 0;
			}

			.aircraft-card__content {
				padding-bottom: 0;
			}
		}

		.book__subtitle {
			@include margin(bottom, 10px);
			@extend %mediumWeight;
		}

		.book__text {
			@include margin(bottom, 24px);
		}

		.book__prices {
			@include margin(bottom, 14px);

			display: flex;
			flex-direction: column;
			border: 1px solid $c-gold;
		}

			.book__price {
				@include font(18px, 22px);

				position: relative;
				padding: 10px 10px 10px 30px;
				order: 1;
				letter-spacing: 0.02em;
				color: $c-grey-dark;
				cursor: pointer;
				overflow: hidden;

				p {
					@include font(14px, 17px);
					@extend %boldWeight;

					margin: -10px -10px 10px -30px;
					padding: 5px;
					background: $c-gold;
					text-align: center;
					text-transform: uppercase;
				}

				input {
					position: absolute;
					top: 0;
					left: -100%;

					&:checked {

						& + .book__price-checkbox {

							&:after {
								content: '';
								position: absolute;
								top: 50%;
								left: 50%;
								height: 55%;
								width: 55%;
								border-radius: 100%;
								background-color: $c-gold;
								transform: translate(-50%, -50%);
							}
						}
					}
				}

				sub {
					@include font(16px, 20px);
				}

				.book__small-print {
					@include font(14px, 20px);
					@extend %lightWeight;

					display: block;
				}
			}

			.book__price--primary {
				order: 0;
				border-bottom: 1px solid $c-gold;
			}

				.book__price-checkbox {
					position: absolute;
					top: 13px;
					left: 15px;
					width: 16px;
					height: 16px;
					border: 1px solid $c-gold;
					border-radius: 100%;
					transform: translateX(-50%);

					.book__price--primary & {
						top: 40px;
					}
				}