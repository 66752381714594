.quote-pdf {
	$this: &;
	color: $c-primary-blue;
	margin: 0;
	padding: 0;
	font-size: 1.4rem;

	&__page {
		background: #ffffff;
		padding: 1.4rem;
		display: flex;
		flex-direction: column;
		height: 100vh;
		overflow: hidden;
	}

	&__header {
		height: 7rem;
		flex-shrink: 0;
		background-color: $c-primary-blue;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		position: relative;

		// theme
		&--pbs {
			background-color: var(--header-bg);
		}

		&-recommends {
			color: $c-primary-blue;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
			display: none;
			text-transform: uppercase;
			margin-right: 2.1rem;
			@extend %boldWeight;

			#{$this}__header--pbs & {
				color: var(--header-color);
			}
		}

		&--recommended {
			background-color: $c-gold;

			#{$this}__header-recommends {
				display: block;
			}

			&#{$this}__header--pbs {
				background: var(--header-bg);
			}
		}
	}

	&__logo {
		height: 60%
	}

	// Header title
	&__header-table {
		width: 100%;
		border-bottom: 1px solid $c-grey-08;

		tr:first-of-type { // Add padding to the first row
			td {
				padding-top: 2rem;
			}
		}

		tr:last-of-type { // Add padding to the last row
			td {
				padding-bottom: 2rem;
			}
		}

		// Columns
		td {
			width: 50%;
			padding: .4em 2em;

			&:first-of-type {
				border-right: 1px solid $c-grey-08;;
			}

			// Left hand text
			span:first-of-type {
				@extend %boldWeight;
				text-transform: uppercase;
			}

			// Right hand text
			span:nth-of-type(2) {
				@extend %lightWeight;
				padding-left: .5rem;
			}

			span #{$this}__lowercase {
				text-transform: lowercase;
			}

			a {
				border-bottom: 1px solid $c-primary-blue;
			}
		}
	}

	// Aircraft images
	&__aircraft-images {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 1.2rem;
		margin: 1rem 0;

		img {
			object-fit: cover;
			object-position: center;
			display: block;
			width: 100%;
			background-color: black;
			aspect-ratio: 16/9;
		}
	}

	// Aircraft details
	&__aircraft-details {
		display: grid;
		flex: 1;
		grid-template-columns: 1fr 1fr;
		align-content: center;
		position: relative;

		h2 {
			@extend %boldWeight;
			margin: 1rem 0;
			text-transform: uppercase;
		}

		&-column {
			padding: 0 2rem;
		}

		&-dividing-line {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 1px;
			transform: translate(-50%, 0);
			background-color: $c-grey-08;
		}

		p {
			@extend %lightWeight;
			margin: .5rem 0;
			line-height: 1.4em;
		}
	}


	// Details table
	&__details-table {
		margin: .8rem 0 3rem;
		width: 100%;

		td {
			padding: .4rem 0;
		}

		img {
			object-fit: contain;
			object-position: center;
			height: 2rem;
			width: 2.8rem;
			margin-right: .5rem;
			transform: translate(0, 0.5rem);
		}

		span:first-of-type {
			@extend %regularWeight;
			letter-spacing: .02em;
		}

		span:nth-of-type(2) {
			margin-left: .3rem;
			@extend %lightWeight;
		}
	}

	// Aircraft dimensions
	&__dimensions {
		position: relative;
		margin-bottom: 1.4rem;

		&-layout-image {
			display: block;
			margin-left: 15%;
			width: 70%;
			max-width: 40rem;
		}

		&-length {
			margin: .5rem 0 2rem 36%;
			text-align: center;
			width: fit-content;
			position: relative;
			padding: 0 .7rem;

			&:before,
			&:after {
				content: '';
				display: block;
				height: 1rem;
				width: 2.2rem;
				position: absolute;
				left: 0;
				top: 50%;
				border-bottom: 1px solid $c-grey-07;
				border-left: 1px solid $c-grey-07;
				transform: translate(-100%, -90%);
			}

			&:after {
				left: auto;
				right: 0;
				border-left: none;
				border-right: 1px solid $c-grey-07;
				transform: translate(100%, -90%);
			}
		}

		&-width,
		&-height {
			position: relative;
			width: fit-content;
			margin: 1.2rem 0 0 36%;
			padding: 0 .7rem;

			&:before {
				content: "";
				position: absolute;
				top: 50%;
				height: 1px;
				width: 8rem;
				right: 100%;
				background-color: $c-grey-07;
			}
		}

		&-cross-section-image {
			position: absolute;
			object-fit: contain;
			object-position: center;
			bottom: 0;
			left: 0;
			width: 7rem;
			height: 7rem;
			background-color: white;
			border: solid 1px $c-grey-07;
		}

		span:first-of-type {
			@extend %regularWeight;
			letter-spacing: .02em;
		}

		span:nth-of-type(2) {
			margin-left: .3rem;
			@extend %lightWeight;
		}

		// Exception for when there is no cross section image
		&--no-cross-section {

			#{$this}__dimensions-length {
				margin: .5rem auto;
			}

			#{$this}__dimensions-width,
			#{$this}__dimensions-height {
				margin: .5rem auto;

				&:before {
					display: none;
				}
			}

		}

		small {
			font-size: 0.8em;
		}
	}

	&__footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-top: .4rem solid $c-primary-blue;
		border-bottom: .2rem solid $c-gold;

		// theme
		&--pbs {
			border-top: .4rem solid var(--footer-bg);
			border-bottom: .2rem solid var(--header-bg);
		}
	}

	&__footer-column {
		padding: 1.3rem 2.1rem;
		display: flex;
		flex-direction: column;
	}

	&__movida-info {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-end;
		margin-bottom: 1rem;

		&-title {
			text-transform: uppercase;
			margin: 0 .8rem .1rem 0;
			@extend %boldWeight;
		}

		&-points {
			font-size: 2.8rem;
			letter-spacing: .05em;
			@extend %boldWeight;
		}

		&-text {
			@extend %regularWeight;
			width: 100%;
			padding-top: .5rem;
		}
	}

	&__price {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		margin-bottom: .8rem;

		&-from {
			white-space: nowrap;
			margin: 0 .8rem .2rem 0;
		}

		&-amount {
			font-size: 2.8rem;
			letter-spacing: .05em;
			@extend %boldWeight;
		}
	}

	&__book-now-cta {
		height: 5rem;
		width: 25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: .4rem;
		text-transform: uppercase;
		font-size: 1.8rem;
		@extend %lightWeight;

		// theme
		color: var(--button-color);
		background-color: var(--button-bg);
		border: 1px solid var(--button-border);
	}

	&__legal-info {
		@extend %lightWeight;
		line-height: 1.3em;
		margin-top: auto;

		a {
			border-bottom: 1px solid $c-primary-blue;
		}
	}

}
