/// Specify margins to be applied to items - excluding the first/last child depending on direction
///
/// @param {String} $direction The direction to apply margin to
/// @param {String} $margin The margin value to be applied
/// @author Joe Adcock <joe.adcock@damdigital.com>

@mixin margin($direction, $margin) {
    margin-#{$direction}: $margin;

    @if $direction == 'top' or $direction == 'left' {
        &:first-child {
            margin-#{$direction}: 0;
        }
    }

    @if $direction == 'right' or $direction == 'bottom' {
        &:last-child {
            margin-#{$direction}: 0;
        }
    }
}