.aircraft-card-extra-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px 15px 10px;
    position: relative;
    color: $c-grey-dark;

    @include breakpoint(tablet) {
        padding: 20px 20px 10px;
    }
}

.aircraft-card-extra-content--enter{
    opacity: 0;
}

.aircraft-card-extra-content--exit{
    opacity: 1;
}
.aircraft-card-extra-content--enter-active{
    opacity: 1;
}
.aircraft-card-extra-content--exit-active{
    opacity: 0;
}

.aircraft-card-extra-content--enter-active,
.aircraft-card-extra-content--exit-active{
    transition: opacity 500ms;
}

    .aircraft-card-extra-content__column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 30px;
        align-self: flex-start;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include breakpoint(tablet) {
            width: calc(50% - (40px / 2));
        }

        @include breakpoint(notebook) {
            width: calc(33.333% - (40px / 3));
        }
    }

    .aircraft-card-extra-content__column--double-width {

        @include breakpoint(tablet) {
            flex-wrap: wrap;
            flex-direction: row;
            width: 100%;
        }

        @include breakpoint(notebook) {
            width: calc(66.6666% - (40px / 3));
        }

        .aircraft-card-extra-content__column__title {

            @include breakpoint(tablet) {
                width: 100%;
            }
        }

        .aircraft-card-extra-content__column-content {

            @include breakpoint(tablet) {
                width: 50%;
            }
        }
    }

    .aircraft-card-extra-content__column--full-width {
        @include breakpoint(tablet) {
            width: 100%;
        }

        @include breakpoint(notebook) {
            width: calc(33.333% - (40px / 3));
        }
    }

    .aircraft-card-extra-content__column--full-width-2 {
        @include breakpoint(tablet) {
            width: 100%;
        }
    }

        .aircraft-card-extra-content__column__title {
            @include font(22px, 28px);
            @extend %mediumWeight;
            margin-bottom: 22px;
        }

        .aircraft-card-extra-content__floor-plan {
            margin-bottom: 20px;

            p {
                @include font(14px, 14px);
                @include margin(bottom, 14px);
                @extend %lightWeight;
            }

            small {
                font-size: 0.8em;
            }
        }

        .aircraft-card-extra-content__floor-plan--mobile {

            @include breakpoint(notebook) {
                display: none;
            }
        }

        .aircraft-card-extra-content__floor-plan--desktop {
            display: none;

            @include breakpoint(notebook) {
                display: block;
            }
        }

            .aircraft-card-extra-content__floor-plan-images {
                display: flex;
                width: 100%;
                margin-bottom: 20px;
            }

                .aircraft-card-extra-content__floor-plan-image {
                    flex: 1 1 100%;

                    @include breakpoint(notebook) {
                        flex: 0 0 200px;
                    }

                    img {
                        width: 100%;
                        mix-blend-mode: multiply;
                    }
                }

                .aircraft-card-extra-content__aircraft{
                    margin-bottom: 30px;

                    .aircraft-card-extra-content__aircraft-inline-content {
                        margin-bottom: 10px;
                    }
                    
                    .aircraft-card-extra-content__aircraft-inline-content em,
                    .aircraft-card-extra-content__aircraft-inline-content p {
                        display: inline;
                        margin-right: 5px;
    
                        @include font(16px, 20px);
                        @extend %lightWeight;
                    }
                }
                
                

        .aircraft-card-extra-content__column__feature {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: 24px;
            margin-bottom: 14px;

            @include breakpoint(laptop) {
                padding-left: 5px;
            }
        }

            .aircraft-card-extra-content__feature__image {
                display: flex;
                justify-content: center;
                width: 24px;
            }

            .aircraft-card-extra-content__column__feature__name {
                @include font(16px, 20px);
                @extend %lightWeight;
                min-width: 45%;
                margin-left: 12px;
            }

            .aircraft-card-extra-content__column__feature__detail {
                @include font(16px, 20px);
                @extend %mediumWeight;
                margin-left: 12px;
            }

                .aircraft-card-extra-content__column__feature__detail__catering-info {
                    @extend %lightWeight;
                    @include font(14px);
                    margin-top: 3px;
                }

        .aircraft-card-extra-content__column__small-print {
            @include font(14px, 18px);
            @extend %lightWeight;
            
            margin-bottom: 20px;
        }

        .aircraft-card-extra-content__column__actions-block {
            padding: 15px;
            margin: 20px -15px -30px;

            // theme
            background: var(--aircraft-card-extra-actions-bg);

            @include breakpoint(tablet) {
                width: 100%;
                margin: 10px auto;
            }

            @include breakpoint(notebook) {
                margin-top: auto;
            }
        }

            .aircraft-card-extra-content__column__actions-block__price {
                @include font(22px, 26px);
                @extend %mediumWeight;
                margin: 15px 0
            }

                .aircraft-card-extra-content__column__actions-block__price__from {
                    @include font(16px);
                    @extend %lightWeight;
                    display: inline;
                    margin-right: 10px;
                }

                .aircraft-card-extra-content__column__actions-block__price__buttons {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 10px;

                    button {
                        width: calc(50% - 5px);

                        @include breakpoint(tablet) {
                            width: 100%;
                            margin-top: 10px;
                        }

                        @include breakpoint(laptop) {
                            width: calc(50% - 5px);
                            margin-top: 0;
                        }
                    }
                }

    .aircraft-card-extra-content__close {
        position: absolute;
        top: 0;
        right: 0;
    }
