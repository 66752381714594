.switch-item {
	display: grid;
	grid-template-columns: 10fr 1fr;
	column-gap: 20px;
	padding: 18px 0;
	border-bottom: 1px solid $c-blue-04;

	&:first-of-type {
		padding-top: 10px;
	}

	&:last-of-type {
		border-bottom: none;
		padding-bottom: 10px;
	}

	&__title {
		@extend %boldWeight;
		@include font(16px);
		margin-bottom: 10px;
	}

	&__detail {
		@extend %lightWeight;
		@include font(16px, 18px);
	}

	&__switch_container {
		margin-top: -5px;
	}
}