.blog-article-preview {
	background: $c-white;
	a {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
	}
}

.blog-article-preview--in-content-block {
	border-radius: 4px;
	overflow: hidden;
}

.blog-article-preview--skeleton {
	width: 100%;

	&:nth-child(2) {
		display: none;

		@include breakpoint(phablet) {
			display: block;
		}
	}

	&:nth-child(3) {
		display: none;

		@include breakpoint(tablet) {
			display: block;
		}
	}
}

	.blog-article-preview__image {
		@include aspect(344 / 400);

		position: relative;

		.blog-article-preview--skeleton & {
			@extend %skeleton;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.blog-article-preview__content {
		padding: 14px 10px;
		color: $c-grey-dark;
		display: flex;
		flex-direction: column;
		flex: 1;

		@include breakpoint(tablet) {
			padding: 16px;
		}

		.blog-article-preview--in-content-block & {
			padding: 16px;
		}
	}

		.blog-article-preview__date {
			@include font(16px, 19px);
			@include margin(bottom, 16px);
			@extend %italicStyle;

			.blog-article-preview--in-content-block & {
				@include font(14px, 17px);
				@include margin(bottom, 2px);
			}

			.blog-article-preview--skeleton & {
				@extend %skeleton;
			}
		}

		.blog-article-preview__title {
			@include font(20px, 24px);
			@include margin(bottom, 16px);

			text-transform: uppercase;
			letter-spacing: 0.02em;

			.blog-article-preview--in-content-block & {
				@include font(18px, 22px);
				@extend %boldWeight;

				text-transform: none;
			}

			.blog-article-preview--skeleton & {
				@extend %skeleton;
			}
		}

		.blog-article-preview__intro {
			@include font(16px, 22px);
			@include margin(bottom, 40px);
			@extend %lightWeight;
			flex: 1;

			.blog-article-preview--in-content-block & {
				@include font(14px, 20px);
				@include margin(bottom, 16px);
			}

			.blog-article-preview--skeleton & {
				@extend %skeleton;
			}
		}

		.blog-article-preview__cta {
			@include font(16px, 19px);

			display: flex;
			justify-content: flex-end;
			align-items: center;
			color: var(--blog-article-preview-cta-color);
			text-decoration: underline;
			text-underline-offset: 3px;

			.blog-article-preview--in-content-block & {
				@include font(14px, 17px);
			}

			.blog-article-preview--skeleton & {
				@extend %skeleton;
				
				width: 50%;
				margin-left: auto;
			}

			img {
				margin-left: 10px;
				
				.blog-article-preview--in-content-block & {
					width: 16px;
					margin-left: 14px;
				}

				.blog-article-preview--skeleton & {
					display: none;
				}
			}
		}