.modal-copy-quote-booking {}

	.modal-copy-quote-booking__header {
		padding: 25px 15px 30px 15px;
		border-radius: 4px;
		text-align: center;

		// theme
		color: var(--quote-query-personal-details-header-color);
		background: var(--quote-query-personal-details-header-bg);
	}

		.modal-copy-quote-booking__title {
			@include font(22px, 30px);
			@include margin(bottom, 16px);
		}

	.modal-copy-quote-booking__content {
		padding: 26px 26px 40px;

		.select-input--flight-enquiry,
		.date-picker-input,
		.text-area-input__text-area {
			background-color: $c-white;
			border: 1px solid $c-grey-02;
		}

		.text-area-input__text-area {
			border-radius: 4px;
		}

		.text-area-input__text-area--success,
		.text-area-input__text-area--success:focus {
			border-color: $c-success-green;
		}
	}