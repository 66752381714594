.add-passenger-steps {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 20px auto 30px;
	width: 55%;
	max-width: 250px;
	justify-content: space-between;

	@include breakpoint(tablet) {
		margin: 30px auto 70px;
		width: 70%;
		max-width: 550px;
	}

	@include breakpoint(notebook) {
		margin: 40px auto 80px;
	}
}

	.add-passenger-steps__step {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.add-passenger-steps__step--selected {
		.add-passenger-steps__step__number {
			color: $c-gold;
			border: solid 1px $c-gold;
		}

		.add-passenger-steps__step__title {
			color: $c-gold;
		}
	}

	.add-passenger-steps__step--complete {
		.add-passenger-steps__step__number {
			background-color: $c-gold;
			color: transparent;
			border: solid 1px transparent;
		}

		.add-passenger-steps__step__title {
			color: $c-gold;
		}
	}

		.add-passenger-steps__step__number {
			@include font(14px);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 24px;
			width: 24px;
			border: solid 1px $c-primary-blue;
			border-radius: 100%;

			img {
				width: 60%;
			}

			@include breakpoint(tablet) {
				@include font(16px);
				height: 32px;
				width: 32px;

				img {
					width: 50%;
				}
			}
		}

		.add-passenger-steps__step__title {
			display: none;
			@extend %boldWeight;

			@include breakpoint(tablet) {
				display: block;
				position: absolute;
				top: 100%;
				margin-top: 10px;
				white-space: nowrap;
			}
		}

	.add-passenger-steps__line {
		flex: 1;
		height: 1px;
		min-width: 40px;
		margin-left: 4px;
		margin-right: 4px;
		background-color: $c-primary-blue;

		@include breakpoint(tablet) {
			margin-left: 15px;
			margin-right: 15px;
		}
	}

	.add-passenger-steps__line--previous {
		background-color: $c-gold;
	}