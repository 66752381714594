.from-to-table {
	width: 100%;
	font-size: 1.4rem;

	tr {
		border-bottom: 1px solid $c-grey-08;
	}

	th {
		@extend %regularWeight;
		text-align: left;
		padding: .6em 1em .8em;
		vertical-align: bottom;

		span {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 1.8rem;
		}

		img {
			margin-right: .3rem;
			margin-left: -.2rem;
			height: 2.6rem;
			display: block;
		}
	}

	&__to-from-width {
		width: 30%;
	}

	&__time-width {
		width: 7%;
	}

	td {
		@extend %lightWeight;
		padding: 1em;
		position: relative;

		span {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	&__fuel-stop-icon {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(-40%, -50%);
	}

}